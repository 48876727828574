import React from "react";
import ReactApexChart from "react-apexcharts";
import { shortCurrency } from "utils/CurrencyUtil";
import { renderToString } from 'react-dom/server'
import { getDabbleColor } from 'utils/UIUtil';
import { serialAmount } from "utils/DataUtil";
import { dabbleDecimal } from "utils/NumericUtil";


function PerformanceChart(props) {
    const filter = props.filter
    const metric = filter?.metric
    const data = props.data
    const datas = data.datas

    function getSeries() {
        let series = []
        datas.forEach((data) => {
            const childrens = data.childrens
            childrens.forEach((children, index) => {
                const exsistingSerieIndex = series.findIndex((serie) => serie.name === children.name);
                if (exsistingSerieIndex !== -1) {
                    series[exsistingSerieIndex].data.push(children.amount)
                } else {
                    series.push({
                        name: children.name,
                        data: [children.amount],
                        colors: getDabbleColor(index, 0)
                    })
                }
            })
        })
        return series
    }

    const series = getSeries()
    const seriesLength = series.map((serie, index) => serie.data.length).reduce((total, number) => {
        return total + number;
    }, 0);
    const colors = series.map((serie, index) => serie.colors)
    const state = {
        series: series,
        options: {
            chart: {
                type: "line",
                height: 350,
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: `${seriesLength}%`,
                    endingShape: "rounded",
                    borderRadius: "3",
                },
            },
            dataLabels: {
                enabled: false,
            },
            legend: {
                show: true,
                position: 'top',
                showForSingleSeries: true,
                markers: {
                    radius: 20,
                    fillColors: colors,
                }
            },
            yaxis: {
                labels: {
                    formatter: function (val) {
                        return shortCurrency({ num: val })
                    }
                },
            },
            stroke: {
                show: true,
                curve: 'smooth',
                width: 2,
            },
            xaxis: {
                categories: (datas != null) ? datas.map((data, index) => data.date) : [],
            },
            fill: {
                colors: colors,
                opacity: 1,
            },
            tooltip: {
                shared: true,
                intersect: false,
                width: '400px',
                custom: renderToolTip
            },
            colors: colors
        },
    };

    function renderToolTip({ series, seriesIndex, dataPointIndex }) {
        const selectedSeries = datas[dataPointIndex].childrens;
        const title = datas[dataPointIndex]?.title ?? datas[dataPointIndex]?.date;
        return renderToString(
            <div className="card px-1 py-3 m-0">
                <div className="col">
                    <p className="pb-0 mb-1">
                        { title }
                    </p>
                </div>
                {
                    selectedSeries.map((selectedSerie, index) => {
                        const isPositive = selectedSerie.growth > 0
                        const color = isPositive ? "#21B830" : "#FF2626";
                        const arrow = isPositive ? "M0 11L11 -4.72849e-07L22 11" : "M0 -9.61651e-07L11 11L22 0";
                        const symbol = isPositive? '+':'';
                        const growth = `${symbol}${dabbleDecimal(selectedSerie?.growth)}`;
                        
                        return (
                            <div className="d-flex flex-row align-content-between text-body-xs align-items-center">
                                <div className="col col-auto">
                                    <div className="px-2 py-1 my-1 border rounded">
                                        <svg className="mr-1" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="12" height="12" rx="12.5" fill={colors[index]}></rect>
                                        </svg>
                                        {selectedSerie.name}
                                    </div>
                                </div>
                                <div className="col"></div>
                                <div className="col d-flex align-items-center">
                                    <svg className="p-0 m-0" width="24" height="12" viewBox="0 0 19 12">
                                        <path d={arrow} fill={color} />
                                    </svg>
                                    <div className="text-right pl-1 p-0 m-0">
                                        <span className="p-0 m-0" style={{ color: color }}> {growth} </span>
                                        <span className="p-0 m-0">({serialAmount(selectedSerie.amount, metric?.id)})</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        );
    }

    return (
        <div className="d-flex flex-column">
            <ReactApexChart
                options={state.options}
                series={state.series}
                type={state.options.chart.type}
                width={state.options.chart.width}
                height={state.options.chart.height}
                tooltip={state.options.tooltip}
            />
        </div>
    );
}
export default PerformanceChart;
