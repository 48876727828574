import axios from "axios";
import axiosInstance from "../core/AxiosInstance";
import swal from "sweetalert";
import { Buffer } from "buffer";
import { loginConfirmedAction, logoutAction } from "../store/actions/AuthActions";
import getCookie from "../utils/CookieUtil";

export function forgetPassword(email) {
	const postData = {
		email,
	};
	const url = process.env.REACT_APP_DABBLE_AUTH
	return axiosInstance.post(`${url}/forget-password`, postData)
}

export function signUp(email, password) {
	//axios call
	const postData = {
		email,
		password,
		returnSecureToken: true,
	};
	return axios.post(
		`https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
		postData
	);
}

export function login(email, password) {
  const payload = {
    username: email,
    password: password,
  };
  const encodedPayload = Buffer.from(
    `${process.env.REACT_APP_DABBLE_CLIENT_USERNAME}:${process.env.REACT_APP_DABBLE_CLIENT_PASSWORD}`
  ).toString("Base64");
  const url = process.env.REACT_APP_DABBLE_AUTH;
  const urlV2 = process.env.REACT_APP_DABBLE_API_HOST_V2;
  return axios.all([
    axios.post(`${url}/auth/login`, payload, {
      headers: {
        Authorization: `Basic ${encodedPayload}`,
      },
    }),
    axios.post(`${urlV2}/v2/security/auth/login`, payload, {
      headers: {
        Authorization: `Basic ${encodedPayload}`,
      },
    }),
  ]);
}

export function logout() {
	const url = process.env.REACT_APP_DABBLE_AUTH;
	return axiosInstance.post(`${url}/auth/logout`);
}

export function formatError(errorResponse) {
	switch (errorResponse.error.message) {
		case "invalid_grant":
			return "Username / Password salah";
		case "EMAIL_EXISTS":
			//return 'Email already exists';
			swal("Oops", "Email already exists", "error");
			break;
		case "EMAIL_NOT_FOUND":
			//return 'Email not found';
			swal("Oops", "Email not found", "error", { button: "Try Again!" });
			break;
		case "INVALID_PASSWORD":
			//return 'Invalid Password';
			swal("Oops", "Invalid Password", "error", { button: "Try Again!" });
			break;
		case "USER_DISABLED":
			return "User Disabled";
		default:
			return "";
	}
}

export function saveTokenInLocalStorage(tokenDetails) {
	const today = new Date();
	today.setDate(today.getDate() + 7);
	const expireDate = today.getTime();
	document.cookie = `dabble_token=${tokenDetails.accessToken}; expires=${expireDate}; path=/`;
	document.cookie = `dabble_refresh_token=${tokenDetails.refreshToken}; expires=${expireDate}; path=/`;
	localStorage.setItem('company_name', tokenDetails?.companyName)
	localStorage.setItem('company_logo', tokenDetails?.companyLogo)
}

export function saveTokenV2InLocalStorage(tokenDetails) {
  const today = new Date();
  today.setDate(today.getDate() + 7);
  const expireDate = today.getTime();
  document.cookie = `dabble_token_v2=${tokenDetails.access_token}; expires=${expireDate}; path=/`;
  document.cookie = `dabble_refresh_token_v2=${tokenDetails.refresh_token}; expires=${expireDate}; path=/`;
  localStorage.setItem("company_name", tokenDetails?.companyName);
  localStorage.setItem("company_logo", tokenDetails?.companyLogo);
}

export function runLogoutTimer(dispatch, timer, navigate) {
	setTimeout(() => {
		dispatch(logoutAction(navigate));
	}, timer);
}

export function checkAutoLogin(dispatch, navigate) {
	const accessToken = getCookie("dabble_token");
	if (accessToken == null) {
		dispatch(logoutAction(navigate));
		return;
	}

	dispatch(loginConfirmedAction(accessToken));
}
