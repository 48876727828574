export function isOdd(number) {
    return number % 2 === 1;
}

export function isEven(number) {
    return number % 2 === 0;
}

export function isLeft(index, length){
    return (index % length) === 0;
}

export function isRight(index, length){
    return ((index+1) % length) === 0;
}

export function isEdge(index, length){
    if(index===0){
        return true;
    }
    return index === (length - 1);
}

export function isFirst(index, length){
    return index === 0;
}

export function isLast(index, length){
    return index === (length - 1);
}

export function roundIfWhole(number) {
    if (number % 1 === 0) {
        return Math.round(number);
    } else {
        return number;
    }
}

export function compactDecimal(number, trailingLength = 1){
    if(number != null){
        const cleanedNumber = number?.toFixed(trailingLength)
        return cleanedNumber.replaceAll('.', ',')
    }
    return 0
}

export function displayPercentage(number){
    if(number != null){
        const fragments = number.toString().split('.')
        return compactDecimal(number, (fragments[0].length > 1) ? 0 : 1)
    }
    return 0
}

export function thousandFormat(number, options) {
    const { locale = "en" } = options || {};
    const intNumber = parseInt(number);
    return intNumber.toLocaleString(locale);
}

export function dabbleDecimal(decimal){
    if(decimal === null || decimal === 0.0 || decimal === 0.00 || decimal === undefined || decimal === ''){ return 0; }
    var decimalString = decimal.toString();
    let firstDigit = decimalString.split(',')[0];
    if (firstDigit < 1){
        return Number(decimal)?.toFixed(2) ?? 0;
    } else if (firstDigit.length > 2) {
        return (Number(decimal)?.toFixed(0) ?? 0);
    } else {
        return (Number(decimal)?.toFixed(1) ?? 0);
    }
}
