import {retrieveMetrics} from 'services/FilterService';

export const RETRIEVE_METRICS_CONFIRMED = '[retrieve format action] confirmed retrieve metric';
export const RETRIEVE_METRICS_FAILED = '[retrieve format action] confirmed retrieve metric';
export const UPDATE_SELECTED_METRIC = '[update selected metric]';

export function retrieveMetricsAction(defaultValue){
    return (dispatch) => {
        retrieveMetrics()
        .then((response) => { 
            dispatch(retrieveMetricsConfirmedAction(response.data));
            dispatch(updateSelectedMetric(defaultValue ?? response.data[0]))
        })
        .catch((error) => {
            dispatch(retrieveMetricsFailedAction(error));
        });
    };
}

export function retrieveMetricsConfirmedAction(data) {
    return {
        type: RETRIEVE_METRICS_CONFIRMED,
        result: data,
    };
}

export function retrieveMetricsFailedAction(data) {
    return {
        type: RETRIEVE_METRICS_FAILED,
        result: data,
    };
}

export function updateSelectedMetric(metric){
    return (dispatch) => {
        dispatch({
            type: UPDATE_SELECTED_METRIC,
            payload: metric,
        })
    }
}