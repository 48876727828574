function CardNumber(props){
    return (
        <div className="circle" style={{
            width: '30px',
            height: '30px',
            backgroundColor :'#f2f2f2',
            borderRadius:' 50%',
            display: 'flex',
            justifyContent:' center',
            alignItems:' center',
            border: '2px solid gray',
            position: 'absolute',
            zIndex: '1000',
            marginLeft: '-15px',
            marginTop: '-15px',
        }}>
            <h5 className="p-0 m-0">{props.number}</h5>
        </div>
    );
}

export default CardNumber; 