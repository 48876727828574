import React from "react";
/// React router dom
import { Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";
import 'chart.js/auto'

/// Layout
import Nav from "layouts/nav";
import Footer from "layouts/Footer";
/// Dashboard
import Marketshare from "pages/Dashboard/Marketshare";
import Performance from "pages/Dashboard/Performance";
import Growth from "pages/Dashboard/Growth";

/// Pages
import Registration from "pages/Registration";
import Login from "pages/Login";
import ActivateAccount from "pages/ActivateAccount";
import UpdatePasswordSuccess from "pages/UpdatePasswordSuccess";
import LockScreen from "pages/LockScreen";
import Error400 from "pages/Error400";
import Error403 from "pages/Error403";
import Error404 from "pages/Error404";
import Error500 from "pages/Error500";
import Error503 from "pages/Error503";

//Scroll To Top
import ScrollToTop from "layouts/ScrollToTop";
import getCookie from "utils/CookieUtil";
import Profile from "pages/Profile";
import Pricing from "pages/Dashboard/Pricing";
import Discount from "pages/Dashboard/Discount";
import ResetPassword from "pages/ResetPassword";
import { useDispatch } from "react-redux";
import { authMiddleware, guestMiddleware } from "middlewares/authentication";
import { useRoute, Navigate } from 'react-router-dom';
import AfinityPerformance from "pages/Dashboard/AfinityPerformance";

const Markup = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	let path = window.location.pathname
	path = path.split('/')
	path = path[path.length - 1]
	// let pagePath = path.split('-').includes('page')
	// const [activeEvent, setActiveEvent] = useState(!path)

	const allroutes = [
		{ url: "dashboard", component: <Marketshare />, useAuth: true, layout: <MainLayout /> },
		{ url: "marketshare", component: <Marketshare />, useAuth: true, layout: <MainLayout /> },
		{ url: "performance", component: <Performance />, useAuth: true, layout: <MainLayout /> },
		{ url: "discount", component: <Discount />, useAuth: true },
		{ url: "afinity-performance", component: <AfinityPerformance />, useAuth: true, layout: <MainLayout /> },
		{ url: "growth", component: <Growth />, useAuth: true, layout: <MainLayout /> },
		{ url: "pricing", component: <Pricing />, useAuth: true, layout: <MainLayout /> },
		{ url: "reset-password", component: <ResetPassword />, useAuth: false, layout: null },
		{ url: "reset-password/success", component: <UpdatePasswordSuccess />, useAuth: false },
		{ url: "register", component: <Registration />, useAuth: false, layout: null },
		{ url: "profile", component: <Profile />, useAuth: true, layout: null },
		{ url: "login", component: <Login />, useAuth: false, layout: null },
		{ url: "account/activate", component: <ActivateAccount />, useAuth: false },
		{ url: "account/activate/success", component: <UpdatePasswordSuccess />, useAuth: false },
		{ url: "page-lock-screen", component: <LockScreen />, useAuth: true, layout: null },
		{ url: "page-error-400", component: <Error400 />, useAuth: false, layout: null },
		{ url: "page-error-403", component: <Error403 />, useAuth: false, layout: null },
		{ url: "page-error-404", component: <Error404 />, useAuth: false, layout: null },
		{ url: "page-error-500", component: <Error500 />, useAuth: false, layout: null },
		{ url: "page-error-503", component: <Error503 />, useAuth: false, layout: null },
	];

	const isAuthenticated = getCookie("dabble_token") !== null;
	const userRoutes = allroutes.filter((data) => data.useAuth);
	const userRoutesWithMainLayout = userRoutes.filter((data) => data.layout !== null);
	const userRoutesWithClientLayout = userRoutes.filter((data) => data.layout === null);
	const guestRoutes = allroutes.filter((data) => data.useAuth === false);

	return (
		<>
			<Routes>
				<Route path="/" element={<Navigate to="/dashboard" replace />} />

				{/* Routes without layout (Whole page render) */}
				{guestRoutes.map((data, i) => (
					<Route
						key={i}
						exact
						path={`${data.url}`}
						element={authMiddleware(data.component, isAuthenticated, path)}
					/>
				))}

				{/* Dashboard layout */}
				<Route element={<MainLayout />}>
					{userRoutesWithMainLayout.map((data, i) => {
						return (
							<Route
								key={i}
								exact
								path={data.url}
								element={guestMiddleware(data.component, isAuthenticated, path)}
							/>
						)
					})}
				</Route>

				{/* Client management system layout */}
				<Route element={<MainLayout />}>
					{userRoutesWithClientLayout.map((data, i) => (
						<Route
							key={i}
							exact
							path={data.url}
							element={guestMiddleware(data.component, isAuthenticated, path)}
						/>
					))}
				</Route>
			</Routes>
			<ScrollToTop />
		</>
	);
};

function MainLayout() {
	return (
		<div
			id="main-wrapper"
			className={`show`}>
			<Nav />
			<div
				className="content-body"
				style={{ minHeight: window.screen.height - 45 }}>
				<div className="container-fluid">
					<Outlet />
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default Markup;
