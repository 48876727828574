import React, { useRef } from "react";
import Select from "react-select";
import { debounce } from "utils/InputUtil";

function Dicer(props) {
    const dicerRef = useRef(null);
    const dicers = props.dicers ?? [];
    const dicerSelections = props.dicerSelections ?? [];
    const selectedDicer = props.selectedDicer;
    const selectedDicerSelection = props.selectedDicerSelection;
    const onDicerSelected = props.onDicerSelected;
    const onDicerSelectionSelected = props.onDicerSelectionSelected;
    const onSearchDicerSelection = debounce(props.onSearchDicerSelection, 400);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderRadius: "1.25rem",
            backgroundColor: "filter-selector",
            border: `1px solid ${state.isFocused ? '#00F0FF' : '#eee'}`,
            ':focus': {
                border: `1px solid #00F0FF`,
            },
            ':hover': {
                border: `1px solid #00F0FF`,
            },
            boxShadow: 'none'
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                borderRadius: "1.25rem",
                ':hover': {
                    backgroundColor: `#00F0FF`,
                },
            };
        },
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                borderRadius: "1.25rem",
            };
        },
        singleValue: (styles, { data }) => {
            return {
                ...styles,
                borderRadius: "1.25rem",
            };
        },
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            borderRadius: "1.25rem",
        }),
    };

    return (
        <div className="col pb-3" id="DicerSection">
            <hr />
            <p className="text-body-s medium text-primary">Dicer</p>
            <div className="form-group">
                <Select
                    key={'dicers'}
                    ref={dicerRef}
                    className="text-body-base regular"
                    value={selectedDicer}
                    onChange={(value) => {
                        onDicerSelected(value)
                    }}
                    options={dicers}
                    styles={customStyles}
                    isClearable={true}
                />
            </div>
            <div className="form-group">
                <Select
                    key={'dicerSelections'}
                    className="text-body-base regular"
                    styles={customStyles}
                    closeMenuOnSelect={false}
                    value={selectedDicerSelection}
                    onChange={onDicerSelectionSelected}
                    isMulti
                    isClearable={false}
                    options={dicerSelections}
                    onInputChange={onSearchDicerSelection}
                />
            </div>
        </div>
    );
}

export default Dicer;