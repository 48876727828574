import React from "react";
import ReactApexChart from "react-apexcharts";
import { shortCurrency } from "utils/CurrencyUtil";
import { renderToString } from "react-dom/server";
import { getDabbleColor } from "utils/UIUtil";
import { serialAmount } from "utils/DataUtil";
import { dabbleDecimal } from "utils/NumericUtil";

function AfinityPerformanceChart(props) {
	const filter = props.filter;
	const metric = filter?.metric;
	const data = props?.data;

	function getSeries() {
		let series = [];
		data?.forEach((d, index) => {
			d.childrens.forEach((child, childIndex) => {
				child.dataSet.forEach((set, setIndex) => {
					const exsistingSerieIndex = series.findIndex((serie) => serie.name === set.title);
					if (exsistingSerieIndex !== -1) {
						series[exsistingSerieIndex].data.push(set.amount);
					} else {
						series.push({
							name: set.title,
							data: [set.amount],
							group: child.title,
							colors: getDabbleColor(5 + childIndex, setIndex),
						});
					}
				});
			});
		});
		return series;
	}

	const series = getSeries();
	const dataLength = 100 / (data?.length ?? 0);
	const colors = series.map((serie, index) => serie.colors);
	const state = {
		series: series,
		options: {
			chart: {
				height: 350,
				type: "bar",
				stacked: true,
				toolbar: {
					show: false,
				},
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: `${dataLength}%`,
					endingShape: "rounded",
				},
			},
			dataLabels: {
				enabled: false,
			},
			legend: {
				show: true,
				position: "top",
				showForSingleSeries: true,
				markers: {
					radius: 20,
					fillColors: colors,
				},
			},
			yaxis: {
				labels: {
					formatter: function (val) {
						return shortCurrency({ num: val });
					},
				},
			},
			xaxis: {
				categories: data != null ? data.map((data, index) => data.title) : [],
			},
			fill: {
				colors: colors,
				opacity: 1,
			},
			stroke: {
				show: false,
				width: 1,
				lineCap: "curve",
				colors: ["#000"],
			},
			tooltip: {
				shared: true,
				intersect: false,
				width: "400px",
				custom: renderToolTip,
			},
			colors: colors,
		},
	};

	function renderToolTip({ series, seriesIndex, dataPointIndex, w }) {
		const selectedSeries = data[dataPointIndex].childrens;
		const title = data[dataPointIndex]?.title;
		return renderToString(
			<div
				className="card px-1 py-3 m-0"
				style={{ gap: "10px" }}>
				{selectedSeries?.map((group, index) => {
					return (
						<div>
							<div className="col">
								<div className="px-2 py-1 my-1 border rounded d-flex align-items-center flex-shrink-1">
									<svg
										className="mr-1"
										width="12"
										height="12"
										viewBox="0 0 12 12"
										fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<rect
											width="12"
											height="12"
											rx="12.5"
											fill={getDabbleColor(index+5, 0)}></rect>
									</svg>
									<p className="pb-0 mb-0 flex-shrink-1">
										{group.title} in {title}
									</p>
								</div>
							</div>
                            <div className="col">
                                <p className="pb-0 mb-1">Top 5 Cat Lv 3 transacted w/ x { group.title }:</p>
                            </div>
							{group.dataSet.map((child, index) => {
								const isPositive = child.growth > 0;
								const color = isPositive ? "#21B830" : "#FF2626";
								const arrow = isPositive ? "M0 11L11 -4.72849e-07L22 11" : "M0 -9.61651e-07L11 11L22 0";
								const symbol = isPositive ? "+" : "";
								const growth = `${symbol}${dabbleDecimal(child?.growth)}`;

								return (
									<div className="d-flex flex-row align-content-between text-body-xs align-items-center">
										<div className="col col-auto">
											<p className="pb-0 mb-1">{child.title}</p>
										</div>
										<div className="col"></div>
										<div className="col d-flex align-items-center">
											<svg
												className="p-0 m-0"
												width="24"
												height="12"
												viewBox="0 0 19 12">
												<path
													d={arrow}
													fill={color}
												/>
											</svg>
											<div className="text-right pl-1 p-0 m-0">
												<span
													className="p-0 m-0"
													style={{ color: color }}>
													{" "}
													{growth}{" "}
												</span>
												<span className="p-0 m-0">({serialAmount(child.amount, metric?.id)})</span>
											</div>
										</div>
									</div>
								);
							})}
                            <div className="col">
                                <p className="pb-0 mb-0" >
                                These Top 5 related Cat Lv 3  generated a combined GTV of Rp. 874,000,000 (10%) 
                                which is 15% from a Total GTV of  Rp. 10,000,000,000 (7%) in that same period
                                </p>
                            </div>
						</div>
					);
				})}
			</div>
		);
	}

	return (
		<div className="d-flex flex-column">
			<ReactApexChart
				options={state.options}
				series={state.series}
				type={state.options.chart.type}
				width={state.options.chart.width}
				height={state.options.chart.height}
				tooltip={state.options.tooltip}
			/>
		</div>
	);
}
export default AfinityPerformanceChart;
