import React, { useEffect, useRef, useState } from "react";
import { isFirst, isLast } from "utils/NumericUtil";
import { getTimespanFilterByPeriod } from "utils/FilterUtil";
import CardNumber from "components/Composable/CardNumber";
import DabbleDropdown from "components/Forms/Dropdown/DabbleDropdown";
import EmptyCardPlaceholder from "components/Composable/EmptyCardPlaceholder";
import GroupStackedBar from "../chart/StackBarChart";

// Compile request
// Request chart by request
function DicerGraphicCard3(props) {
	const cardRef = useRef(null);
	const [activeMainFilter, setActiveFilter] = useState(null);
	const data = props?.data;
	const activeFilter = props?.activeFilter;
	const cardFilters = props.filters;
	const number = props.number;
	const onMainFilterChange = props.onMainFilterChange;
	const dicerLength = activeFilter?.dicerSelections?.length ?? 0;
	const slicerLength = activeFilter?.slicerSelections?.length ?? 0;

	function changeActiveMainFilter(filter, index) {
		setActiveFilter(filter);
		onMainFilterChange(filter)
	}

	function getDicers() {
		const selections = activeFilter?.dicerSelections ?? activeFilter?.slicerSelections;
		if (selections != null) {
			const selectionLabels = selections.map((selection) => selection?.label ?? selection?.name);
			return selectionLabels.join(", ").replace(/,(?!.*,)/gim, " and");
		}
		return "-";
	}

	const cardTitle = () => {
		return slicerLength > 0 ? (
			<div className="card-header pb-0 border-0 d-flex flex-column">
				<div className="row d-flex flex-row w-100 align-items-center justify-content-between">
					<div className="d-flex align-items-center">
						<div className="d-flex flex-column w-100">
							{ mainFilterDropdown }
							<p className="text-body-2lg p-0 m-0 mt-3">
							Discount between{" "}
								{activeFilter?.dicerSelections != null
									? `${dicerLength} ${activeFilter?.dicer?.label}`
									: `${slicerLength} ${activeFilter?.slicer?.label}`}
							</p>
							{activeFilter?.dicerSelections != null ? (
								<p className="text-body-2lg p-0 m-0">{`of ${getDicers()}`}</p>
							) : null}
							<p className="text-body-2lg p-0 m-0">{`in ${activeFilter?.period?.id}`}</p>
						</div>
					</div>
				</div>
			</div>
		) : (
			<div className="card-header pb-0 border-0">
				<p className="text-body-2lg p-0 m-0 text-muted">No slicer selection selected</p>
			</div>
		);
	}

	const mainFilterDropdown = (
		<DabbleDropdown
			items={cardFilters}
			onTap={(filter, index) => changeActiveMainFilter(filter, index)}
			selectedItemId={activeMainFilter?.value}
			toggleClassName="default-select style-3 btn btn-sm filter-option text-body-2xs regular"
			containerClassName="dropdown bootstrap-select form-control style-3 default-select d-inline-flex"
			key={Math.random()}
			buttonStyle="style1"
		/>
	);

	return (
		<div className="col-xl-12 col-md-12">
			<CardNumber number={number} />
			<div className="card">
				{cardTitle()}
				{data?.data != null ? (
					<div
						className="card-body"
						ref={cardRef}>
						<GroupStackedBar data={data}/>
					</div>
				) : (
					<EmptyCardPlaceholder />
				)}
			</div>
		</div>
	);
}

export default DicerGraphicCard3;
