import {RESET_FILTERS, SYNC_ACTIVE_FILTERS, UPDATE_ACTIVE_FILTERS, UPDATE_FILTERS,} from '../actions/FilterActions';
import {RETRIEVE_ANALYTICS_CONFIRMED, UPDATE_SELECTED_ANALYTIC,} from '../actions/filter/AnalyticActions'
import {RETRIEVE_METRICS_CONFIRMED, UPDATE_SELECTED_METRIC,} from '../actions/filter/MetricActions'
import {RETRIEVE_INTERVALS_CONFIRMED, UPDATE_SELECTED_INTERVAL,} from '../actions/filter/IntervalActions'
import {
    RETRIEVE_SLICER_SELECTIONS_SUCCESS,
    RETRIEVE_SLICERS_SUCCESS,
    UPDATE_SELECTED_SLICER,
    UPDATE_SELECTED_SLICER_SELECTION,
} from '../actions/filter/SlicerActions'
import {
    RETRIEVE_DICER_SELECTIONS_SUCCESS,
    RETRIEVE_DICERS_SUCCESS,
    UPDATE_SELECTED_DICER,
    UPDATE_SELECTED_DICER_SELECTION,
} from '../actions/filter/DicerActions'
import {RETRIEVE_PERIODS_CONFIRMED, UPDATE_SELECTED_PERIOD} from '../actions/filter/PeriodActions';
import {RETRIEVE_FORMATS_CONFIRMED, UPDATE_SELECTED_FORMAT} from '../actions/filter/FormatActions';


const currentDate = new Date();
const currentYear = currentDate.getFullYear();

export const initialState = {
    // Filter is an available filter menu which user can iteractive with
    filter : {
        analytic : {
            id: 1,
            title: 'Marketshare',
            route: '/marketshare'
        },
        metric : {
            id: 'transaction',
            title: 'Transaction'
        },
        format : {
            id: 'graphic',
            title: 'Graphic'
        },
        period : {
            id: currentYear,
            title: currentYear.toString()
        },
        interval : 3,
        slicer : null,
        slicerSelections : null,
        dicer : null,
        dicerSelections : null,
    },
    // Active filter is user selected menu that used to fetch comparisons and charts
    activeFilter : {
        analytic : null,
        metric : null,
        format : null,
        period : {
            id: currentYear,
            title: currentYear.toString()
        },
        interval : null,
        slicer : null,
        slicerSelections : null,
        dicer : null,
        dicerSelections : null,
    },
    slicers : null,
    slicerSelections : null,
    dicers : null,
    dicerSelections : null,
    analytics : null,
    metrics : null,
    intervals : null,
    periods: null,
};

export function MenuReducer(state = initialState, action){
    switch(action.type) {
        case SYNC_ACTIVE_FILTERS:
            state = {
                ...state,
                filter : action.result,
                activeFilter : action.result,
            }
            return state
        case UPDATE_FILTERS:
            state = {
                ...state,
                filter : action.payload
            }
            return state
        case UPDATE_ACTIVE_FILTERS:
            state = {
                ...state,
                activeFilter : action.payload
            }
            return state
        case RESET_FILTERS:
            state = initialState 
            return state
        case RETRIEVE_ANALYTICS_CONFIRMED:
            // Retrieve slicers
            // Set slicers & default selected slicer
            state = {
                ...state,
                analytics : action.result,
                filter : {
                    ...state.filter,
                }
            }
            return state
        case RETRIEVE_METRICS_CONFIRMED:
            // Retrieve metrics
            // Set metrics & default selected metrics
            state = {
                ...state,
                metrics : action.result,
                filter : {
                    ...state.filter,
                }
            }
            return state
        case RETRIEVE_FORMATS_CONFIRMED:
            // Retrieve metrics
            // Set metrics & default selected metrics
            state = {
                ...state,
                formats : action.result,
                filter : {
                    ...state.filter,
                }
            }
            return state
        case RETRIEVE_INTERVALS_CONFIRMED:
            // Retrieve metrics
            // Set metrics & default selected metrics
            state = {
                ...state,
                intervals : action.result,
                filter : {
                    ...state.filter,
                }
            }
            return state
        case RETRIEVE_PERIODS_CONFIRMED:
            // Retrieve metrics
            // Set metrics & default selected metrics
            state = {
                ...state,
                periods : action.result,
                filter : {
                    ...state.filter,
                }
            }
            return state
        case RETRIEVE_SLICERS_SUCCESS:
            // Retrieve slicers
            // Set slicers & default selected slicer
            state = {
                ...state,
                slicers : action.result,
                filter : {
                    ...state.filter,
                }
            }
            return state
        case RETRIEVE_SLICER_SELECTIONS_SUCCESS:
            // Retrieve & Set slicer selection
            state = {
                ...state,
                slicerSelections : action.result,
            }
            return state
        case RETRIEVE_DICERS_SUCCESS:
            // Retrieve dicers
            // Set dicers & default selected dicer
            console.log('set dicer '+action.result)
            state = {
                ...state,
                dicers : action.result.length > 0 ? action.result : null,
                filter : {
                    ...state.filter,
                }
            }
            return state
        case RETRIEVE_DICER_SELECTIONS_SUCCESS:
            // Set dicer selections
            state = {
                ...state,
                dicerSelections : action.result,
            }
            return state
        case UPDATE_SELECTED_ANALYTIC:
            // Set selected analytic
            state = {
                ...state,
                filter : {
                    ...state.filter,
                    analytic: action.payload
                }
            }
            return state
        case UPDATE_SELECTED_METRIC:
            // Set selected metric
            state = {
                ...state,
                filter : {
                    ...state.filter,
                    metric: action.payload
                }
            }
            return state
        case UPDATE_SELECTED_FORMAT:
            // Set selected metric
            state = {
                ...state,
                filter : {
                    ...state.filter,
                    format: action.payload
                }
            }
            return state
        case UPDATE_SELECTED_INTERVAL:
            // Set selected metric
            state = {
                ...state,
                filter : {
                    ...state.filter,
                    interval: action.payload
                }
            }
            return state
        case UPDATE_SELECTED_PERIOD:
            // Set selected metric
            state = {
                ...state,
                filter : {
                    ...state.filter,
                    period: action.payload
                }
            }
            return state
        case UPDATE_SELECTED_SLICER:
            // Set selected slicer
            state = {
                ...state,
                filter : {
                    ...state.filter,
                    slicer: action.payload
                }
            }
            return state
        case UPDATE_SELECTED_SLICER_SELECTION:
            // Set selected slicer selections
            state = {
                ...state,
                filter : {
                    ...state.filter,
                    slicerSelections: action.payload
                }
            }
            return state
        case UPDATE_SELECTED_DICER:
            // Set selected dicer
            state = {
                ...state,
                filter : {
                    ...state.filter,
                    dicer: action.payload
                }
            }
            return state
        case UPDATE_SELECTED_DICER_SELECTION:
            // Set selected dicer selections
            state = {
                ...state,
                filter : {
                    ...state.filter,
                    dicerSelections: action.payload
                }
            }
        return state
        default:
            return state
    }
};

export default MenuReducer;
