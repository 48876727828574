import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import DabbleDropdown from "components/Forms/Dropdown/DabbleDropdown";
import PricingPieChart from "../chart/PricingPieChart";
import EmptyCardPlaceholder from "components/Composable/EmptyCardPlaceholder";
import { useGetChartQuery } from "services/PricingServiceV2";

export default function PricingGraphicCard3({
	slicerFilter,
	setSlicerFilter = () => {},
}) {
	const menu = useSelector((state) => state.menu);
	const activeFilter = menu?.activeFilter;
	const slicerSelections = activeFilter?.slicerSelections

	// Meta seciton
	const selectedDicersLength = activeFilter?.dicerSelections?.length;

	const body = useMemo(() => {
		const filter = {
			year: activeFilter?.period?.id,
			interval: activeFilter?.interval?.id,
			metric: activeFilter?.metric?.id,
			slicerId: activeFilter?.slicer?.value,
			slicerSelections: activeFilter?.slicerSelections?.map((slicerSelection, _) => slicerSelection.value),
			intervalFilter: slicerFilter?.value,
		};
		if (activeFilter?.dicerSelections != null) {
			filter.dicerId = activeFilter?.dicer?.value;
			filter.dicerSelections = activeFilter?.dicerSelections?.map((dicerSelection, _) => dicerSelection.value);
		}
		return filter
	}, [activeFilter, slicerFilter])

	const { data: dataChart } = useGetChartQuery({ body, path: '/3'}, { skip: !body.slicerId })
	const data = dataChart?.data
	const meta = dataChart?.meta

	function cardTitle() {
		return (
			<>
				{selectedDicersLength > 0 ? (
					<>
						<DabbleDropdown
							items={slicerSelections}
							onTap={(filter) => setSlicerFilter(filter)}
							selectedItemId={slicerFilter?.value}
							toggleClassName="default-select style-3 btn btn-sm filter-option text-body-2xs regular"
							containerClassName="dropdown bootstrap-select form-control style-3 default-select d-inline-flex"
							key={Math.random()}
							buttonStyle="style1"
						/>
						<div className="d-flex justify-content-between w-100 mt-4">
							<div className="text-body-2lg">
								<div>Nett Price and GTV Performance</div>
								<div>
									in {selectedDicersLength} {activeFilter?.dicer?.label} of {slicerFilter?.label}
								</div>
								<div>in {meta?.year}</div>
							</div>
						</div>
					</>
					) : (
					<p className="text-body-2lg p-0 m-0 text-muted">No slicer selection selected</p>
					)
				}
			</>
		);
	}

	return (
		<div className="d-flex flex-column align-items-center">
			{cardTitle()}
			
			{data ? (
				<div className="d-flex flex-column align-items-center">
					<div className="flex-grow-1 row align-items-center pt-5">
						{data?.map((d, index) => (
							<PricingPieChart
								data={d}
								meta={meta}
								filter={activeFilter}
								key={Math.random()}
							/>
						))}
					</div>
				</div>
			) : (
				<EmptyCardPlaceholder />
			)}
					
			{data !== null ? <h5 className="bold mt-3 text-primary">{/* {data.name} */}</h5> : null}
		</div>
	);
}
