const serializeParams = (params) => {
  const queryParams = [];

  Object.keys(params).forEach((key) => {
    if (Array.isArray(params[key])) {
      queryParams.push(
        `${encodeURIComponent(key)}=${params[key]
          .map(encodeURIComponent)
          .join(",")}`
      );
    } else {
      queryParams.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      );
    }
  });

  return queryParams.join("&");
};

export default serializeParams;
