import React, { useEffect, useState } from "react";
import CardNumber from "components/Composable/CardNumber";
import ComparisonTooltipTile from "components/Composable/ComparisonTooltipTile";
import ComparisonProgressBar from "components/Composable/ComparisonProgressBar";
import EmptyCardPlaceholder from "components/Composable/EmptyCardPlaceholder";
import CardTitle from "components/Composable/CardTitle";
import { useDispatch } from "react-redux";
import { fetchSlicerComparison } from "store/actions/PerformanceAction";
import { getDabbleColor } from "utils/UIUtil";
import { debounce } from "utils/InputUtil";
import { dabbleDecimal, displayPercentage } from "utils/NumericUtil";
import { serialAmount } from "utils/DataUtil";

function SlicerComparisonCard(props) {
	const activeFilter = props?.activeFilter;
	const slicersLength = activeFilter?.slicerSelections?.length ?? 0;
	const slicerLabel = activeFilter?.slicer?.label;
	const periodId = activeFilter?.period?.id;
	const dispatch = useDispatch();
	const [data, setData] = useState(null);

	useEffect(() => {
		if(activeFilter?.slicerSelections != null){
			function fetchData() {
				const filter = {
					year: activeFilter?.period?.id,
					metric: activeFilter?.metric?.id,
					slicer_id: activeFilter?.slicer?.value,
					slicers: activeFilter?.slicerSelections?.map((slicerSelection, _) => slicerSelection.value),
					interval: activeFilter?.interval?.id,
				};
				if (activeFilter?.dicerSelections != null) {
					filter.dicer_id = activeFilter?.dicer?.value;
					filter.dicers = activeFilter?.dicerSelections?.map((dicerSelection, _) => dicerSelection.value);
				}
				dispatch(fetchSlicerComparison(filter, "slicer", setData));
			}
			const debouncedFetchData = debounce(fetchData, 250);
			debouncedFetchData();
		}
	}, [activeFilter, dispatch]);

	function getDicerLabels() {
		const selections = activeFilter?.dicerSelections ?? activeFilter?.slicerSelections;
		return selections?.map((selection) => selection?.label ?? selection?.name);
	}

	function progressBarCaption() {
		return (
			<div className="row">
				{data.datas.map((slicer, index) => {
					const color = getDabbleColor(index, 0);
					return (
						<div
							className="col"
							key={index}>
							<p
								className="m-0"
								style={{ color }}>
								{slicer?.title} :{displayPercentage(slicer?.percentage)}%
							</p>
							<p
								className="mb-2"
								style={{ color }}>
								of {data.year}
							</p>
						</div>
					);
				})}
			</div>
		);
	}

	return (
		<div className="col-xl-12 col-md-12">
			<div className="card">
				<CardNumber number="4" />
				<CardTitle
					slicerTitle={slicerLabel}
					slicerLength={slicersLength}
					dicerLabels={getDicerLabels()}
					period={periodId}
				/>
				{data && activeFilter?.slicer != null && activeFilter?.slicerSelections != null ? (
					<div className="card-body">
						{progressBarCaption()}
						<div className="dabble-tooltip-container w-100">
							<div
								className="dabble-tooltip rounded-sm shadow mt-5 py-2 px-4 text-body regular"
								style={{ zIndex: "1080" }}>
								<p className="p-0 pb-0 mb-1">
									{`Combined ${slicersLength} ${activeFilter?.slicer?.label} : ${serialAmount(
										data.totalAmount,
										activeFilter?.metric?.id
									)} in ${activeFilter?.period?.id}`}
								</p>
								{data.datas?.map((slicer, slicerIndex) => {
									return (
										<ComparisonTooltipTile
											title={slicer?.title}
											value={serialAmount(slicer?.amount, activeFilter?.metric?.id)}
											percentage={slicer?.percentage}
											growth={dabbleDecimal(slicer?.growth)}
											color={getDabbleColor(slicerIndex, 0)}
											key={Math.random()}
										/>
									);
								})}
							</div>
							<ComparisonProgressBar data={data} />
						</div>
					</div>
				) : (
					<EmptyCardPlaceholder />
				)}
			</div>
		</div>
	);
}

export default SlicerComparisonCard;
