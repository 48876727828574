import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchChartByInterval } from "store/actions/PerformanceAction";
import { getTimespanFilterByPeriod } from "utils/FilterUtil";
import { debounce } from "utils/InputUtil";
import { isFirst, isLast } from "utils/NumericUtil";
import CardNumber from "components/Composable/CardNumber";
import DabbleDropdown from "components/Forms/Dropdown/DabbleDropdown";
import PerformanceChart from "../chart/PerformanceChart";
import EmptyCardPlaceholder from "components/Composable/EmptyCardPlaceholder";

// Compile request
// Request chart by request
function DicerGraphicCard(props) {
	const dispatch = useDispatch();
	const cardRef = useRef(null);
	const [data, setData] = useState(null);
	const [timespanFilters, setTimespanFilters] = useState(null);
	const [activeTimespanFilter, setActiveTimespanFilter] = useState(null);
	const [activeMainFilter, setActiveFilter] = useState(null);
	const groupBy = props.groupBy;
	const activeFilter = props?.activeFilter;
	const cardFilters = props.filters;
	const number = props.number;
	const dicerLength = activeFilter?.dicerSelections?.length ?? 0;
	const slicerLength = activeFilter?.slicerSelections?.length ?? 0;

	useEffect(() => {
		const defaultCardFilterSelected = cardFilters != null ? cardFilters[0]?.value : null;
		if((activeMainFilter != null || defaultCardFilterSelected != null) || activeTimespanFilter?.value != null){
			function fetchData() {
				const request = {
					year: activeFilter?.period?.id,
					interval: activeFilter?.interval?.id,
					metric: activeFilter?.metric?.id,
					slicerId: activeFilter?.slicer?.value,
					slicerSelections: activeFilter?.slicerSelections?.map(
						(slicerSelection, _) => slicerSelection.value
					),
					dicerId: activeFilter?.dicer?.value,
					dicerSelections: activeFilter?.dicerSelections?.map(
						(dicerSelection, _) => dicerSelection.value
					),
					timespan: activeTimespanFilter?.value ?? 1,
					intervalFilter: activeMainFilter?.value ?? defaultCardFilterSelected,
					slicerFilter: activeMainFilter?.value ?? defaultCardFilterSelected,
					dicerFilter: activeMainFilter?.value ?? defaultCardFilterSelected,
				};
				dispatch(fetchChartByInterval(request, groupBy, setData));
			}
			const debouncedFetchData = debounce(fetchData, 250);
			debouncedFetchData();
		}
	}, [activeMainFilter, activeTimespanFilter, activeFilter, cardFilters, groupBy, dispatch]);

	useEffect(() => {
		initializeFilter(activeFilter);
	}, [activeFilter]);

	function initializeFilter(filter) {
		const timespanPeriodFilters = getTimespanFilterByPeriod(filter?.interval?.id);
		if (timespanPeriodFilters != null) {
			setTimespanFilters(timespanPeriodFilters);
			const defaultIndex = timespanPeriodFilters?.length - 1;
			setActiveTimespanFilter(timespanPeriodFilters[defaultIndex]);
		}
	}

	function changeActiveMainFilter(filter, index) {
		setActiveFilter(filter);
	}

	function changeActiveTimespanFilter(timespanFilter, index) {
		setActiveTimespanFilter(timespanFilter);
	}

	function getDicers() {
		const selections = activeFilter?.dicerSelections ?? activeFilter?.slicerSelections;
		if (selections != null) {
			const selectionLabels = selections.map((selection) => selection?.label ?? selection?.name);
			return selectionLabels.join(", ").replace(/,(?!.*,)/gim, " and");
		}
		return "-";
	}

	const mainFilterDropdown = (
		<DabbleDropdown
			items={cardFilters}
			onTap={(filter, index) => changeActiveMainFilter(filter, index)}
			selectedItemId={activeMainFilter?.value}
			toggleClassName="default-select style-3 btn btn-sm filter-option text-body-2xs regular"
			containerClassName="dropdown bootstrap-select form-control style-3 default-select d-inline-flex"
			key={Math.random()}
			buttonStyle="style1"
		/>
	);

	const timespanFilterChips = (
		<div className="card-action">
			{timespanFilters?.map((filter, index) => {
				const filterLength = filter.length;
				const marginLeft = !isFirst(index, filterLength) ? " ml-1" : "";
				const marginRight = !isLast(index, filterLength) ? " mr-1" : "";
				const isSelected = filter?.value === activeTimespanFilter?.value;
				return (
					<div
						className={`btn ${
							isSelected ? "btn-primary" : "btn-outline-primary"
						} btn-xxs text-black ${marginLeft}${marginRight}`}
						key={`dgfb${Math.random()}`}
						onClick={() => changeActiveTimespanFilter(filter, index)}>
						{filter?.label}
					</div>
				);
			})}
		</div>
	);

	function cardTitle() {
		return slicerLength > 0 ? (
			<div className="card-header pb-0 border-0 d-flex flex-column">
				<div className="row d-flex flex-row w-100 align-items-center justify-content-between">
					<div className="d-flex align-items-center">
						<div className="d-flex flex-column w-100">
							{groupBy !== "dicer-slicer" ? mainFilterDropdown : null}
							<p className="text-body-2lg p-0 m-0 mt-3">
								Performance between{" "}
								{activeFilter?.dicerSelections != null
									? `${dicerLength} ${activeFilter?.dicer?.label}`
									: `${slicerLength} ${activeFilter?.slicer?.label}`}
							</p>
							{activeFilter?.dicerSelections != null ? (
								<p className="text-body-2lg p-0 m-0">{`of ${getDicers()}`}</p>
							) : null}
							<p className="text-body-2lg p-0 m-0">{`in ${activeFilter?.period?.id}`}</p>
						</div>
					</div>
					{timespanFilters != null ? timespanFilterChips : null}
				</div>
			</div>
		) : (
			<div className="card-header pb-0 border-0">
				<p className="text-body-2lg p-0 m-0 text-muted">No slicer selection selected</p>
			</div>
		);
	}

	return (
		<div className="col-xl-12 col-md-12">
			<div className="card">
				<CardNumber number={number} />
				{cardTitle()}
				{data != null ? (
					<div
						className="card-body"
						ref={cardRef}>
						<div id="barChart">
							<PerformanceChart
								data={data}
								filter={activeFilter}
							/>
						</div>
					</div>
				) : (
					<EmptyCardPlaceholder />
				)}
			</div>
		</div>
	);
}

export default DicerGraphicCard;
