export const Comparison1 = {
  code: 200,
  message: "Successfully retrieve comparison 1!",
  data: [
    {
      amount: 327763,
      percentage: 2.41, /// Percentage di sini persentasi chunk data amount + discounted amount dari keseluruhan returned data (ex : Unilever & Wings Surya)
      growth: 0.11,
      title: "UNILEVER",
    },
    {
      amount: 13266148,
      percentage: 97.59, /// Percentage di sini persentasi chunk data amount + discounted amount dari keseluruhan returned data (ex : Unilever & Wings Surya)
      growth: 0.11,
      title: "WINGS SURYA",
    },
  ],
  meta: {
    year: 2023,
    interval: "MONTHLY",
    metric: "quantity",
    slicerTitle: "Manufacturer",
    dicerTitle: "Category Level 1",
    slicerSelections: [
      {
        id: 2,
        name: "UNILEVER",
      },
      {
        id: 17,
        name: "WINGS SURYA",
      },
      {
        id: 579,
        name: "A PLUS SUPPLY CO.LTD",
      },
    ],
    dicerSelections: [
      {
        id: 11,
        name: "SHAMPOO",
      },
      {
        id: 33,
        name: "LIQUID",
      },
      {
        id: 35,
        name: "POWDER",
      },
    ],
  },
  paging: null,
};

export const Chart1 = {
  code: 200,
  message: "Successfully retrieve performance 1!",
  data: [
    {
      title: "October 2022",
      childrens: [
        {
          title: "SKU 1",
          metric: 6100,
          mode: 3750,
          max: 4000,
          mean: 3500,
          median: 3200,
          min: 3000,
          average: 3200,
        },
        {
          title: "SKU 2",
          metric: 4400,
          mode: 3750,
          max: 4000,
          mean: 3500,
          median: 3200,
          min: 3000,
          average: 2400,
        },
      ],
    },
    {
      title: "November 2022",
      childrens: [
        {
          title: "SKU 1",
          metric: 5500,
          mode: 3750,
          max: 4000,
          mean: 3500,
          median: 3200,
          min: 3000,
          average: 3200,
        },
        {
          title: "SKU 2",
          metric: 9000,
          mode: 3750,
          max: 4000,
          mean: 3500,
          median: 3200,
          min: 3000,
          average: 3400,
        },
      ],
    },
    {
      title: "December 2022",
      childrens: [
        {
          title: "SKU 1",
          metric: 7200,
          mode: 3750,
          max: 4000,
          mean: 3500,
          median: 3200,
          min: 3000,
          average: 3600,
        },
        {
          title: "SKU 2",
          metric: 6500,
          mode: 3750,
          max: 4000,
          mean: 3500,
          median: 3200,
          min: 3000,
          average: 2900,
        },
      ],
    },
    {
      title: "January 2023",
      childrens: [
        {
          title: "SKU 1",
          metric: 5400,
          mode: 3750,
          max: 4000,
          mean: 3500,
          median: 3200,
          min: 3000,
          average: 2700,
        },
        {
          title: "SKU 2",
          metric: 6000,
          mode: 3750,
          max: 4000,
          mean: 3500,
          median: 3200,
          min: 3000,
          average: 3300,
        },
      ],
    },
    {
      title: "February 2023",
      childrens: [
        {
          title: "SKU 1",
          metric: 4400,
          mode: 3750,
          max: 4000,
          mean: 3500,
          median: 3200,
          min: 3000,
          average: 2800,
        },
        {
          title: "SKU 2",
          metric: 7200,
          mode: 3750,
          max: 4000,
          mean: 3500,
          median: 3200,
          min: 3000,
          average: 3600,
        },
      ],
    },
    {
      title: "March 2023",
      childrens: [
        {
          title: "SKU 1",
          metric: 8000,
          mode: 3750,
          max: 4000,
          mean: 3500,
          median: 3200,
          min: 3000,
          average: 2900,
        },
        {
          title: "SKU 2",
          metric: 5600,
          mode: 3750,
          max: 4000,
          mean: 3500,
          median: 3200,
          min: 3000,
          average: 3100,
        },
      ],
    },
  ],
  meta: {
    year: 2023,
    interval: "MONTHLY",
    metric: "quantity",
    slicerTitle: "SKU",
    dicerTitle: "Province",
    slicerSelections: [
      {
        id: 1,
        name: "SKU 1",
      },
      {
        id: 2,
        name: "SKU 2",
      },
    ],
    dicerSelections: [
      {
        id: 11,
        name: "DKI Jakarta",
      },
    ],
    timespan: 6,
    filter: {
      id: 1, /// id dari filter province = 1 sku 1
      name: "SKU", /// filter master data type name
      value: "SKU 1", /// filter value label
    },
  },
  paging: null,
};

export const Chart2 = {
  code: 200,
  message: "Successfully retrieve performance 2!",
  data: [
    {
      title: "November 2022",
      childrens: [
        {
          title: "SKU 1",
          metric: 22134,
          mode: 3750,
          max: 4000,
          mean: 3500,
          median: 3200,
          min: 3000,
          average: 5442,
        },
        {
          title: "SKU 2",
          metric: 11421,
          mode: 3750,
          max: 4000,
          mean: 3500,
          median: 3200,
          min: 3000,
          average: 2241,
        },
      ],
    },
    {
      title: "December 2022",
      childrens: [
        {
          title: "SKU 1",
          metric: 34123,
          mode: 3750,
          max: 4000,
          mean: 3500,
          median: 3200,
          min: 3000,
          average: 4452,
        },
        {
          title: "SKU 2",
          metric: 15521,
          mode: 3750,
          max: 4000,
          mean: 3500,
          median: 3200,
          min: 3000,
          average: 3344,
        },
      ],
    },
  ],
  meta: {
    year: 2023,
    interval: "MONTHLY",
    slicerTitle: "SKU",
    dicerTitle: "Province",
    slicerSelections: [
      {
        id: 1,
        name: "SKU 1",
      },
      {
        id: 2,
        name: "SKU 2",
      },
    ],
    dicerSelections: [
      {
        id: 33,
        name: "DKI Jakarta",
      },
      {
        id: 35,
        name: "Jawa Barat",
      },
    ],
    timespan: 6,
    filter: {
      id: 1, /// id dari filter province = 1 west java
      name: "Province", /// filter master data type name
      value: "West java", /// filter value label
    },
  },
  paging: null,
};

export const Chart3 = {
  code: 200,
  message: "Successfully retrieve performance 3!",
  data: [
    {
      title: "SKU 1",
      childrens: [
        {
          name: "DKI Jakarta",
          amount: 5276,
          metricAmount: 22000000,
          growth: 1.3,
        },
        {
          name: "Jawa Barat",
          amount: 37,
          metricAmount: 95.0,
          growth: 0,
        },
      ],
    },
    {
      title: "SKU 2",
      childrens: [
        {
          name: "DKI Jakarta",
          amount: 135,
          metricAmount: 95.0,
          growth: 0.09,
        },
        {
          name: "Jawa Barat",
          amount: 39,
          metricAmount: 95.0,
          growth: 0.05,
        },
      ],
    },
  ],
  meta: {
    year: 2023,
    interval: "MONTHLY",
    metric: "gtv",
    slicerTitle: "SKU",
    dicerTitle: "Province",
    slicerSelections: [
      {
        id: 2,
        name: "SKU 1",
      },
      {
        id: 17,
        name: "SKU 2",
      },
    ],
    dicerSelections: [
      {
        id: 33,
        name: "DKI Jakarta",
      },
      {
        id: 35,
        name: "Jawa Barat",
      },
    ],
    filter: {
      id: 2, /// id dari filter interval (1 minggu, 2 bulan, 3 quarter, 4 semester, 5 tahun)
      name: "Interval", /// nama filter sesuai id
      value: 1, /// value dari filter interval, 1 = (tanggal, minggu, bulan)
    },
  },
  paging: null,
};

export const TableData1 = {
  code: 200,
  message: "Successfully retrieve tabel 1!",
  data: {
    summary: {
      transaction: 1364798,
      transactionGrowth: 11.2,
      grossTransactionValue: 1364798,
      grossTransactionValueGrowth: 12.2,
      quantity: 1364798,
      quantityGrowth: 12.2,
    },
    ranks: [
      {
        title: "Rank #1",
        price: 3200,
        priceHistories: [0, 2000, 2500, 4000, 6000, 3500],
        metric: 1000967345,
        percentage: 25,
        meta: {
          island: "Java",
          province: "West Java",
          city: "Bandung",
          outlet: "Indomaret tasik 2",
        },
      },
      {
        title: "Rank #2",
        price: 3200,
        priceHistories: [0, 1000, 2200, 3000, 4200, 3500],
        metric: 1000967345,
        percentage: 25,
        meta: {
          island: "Java",
          province: "West Java",
          city: "Bandung",
          outlet: "Indomaret tasik 2",
        },
      },
      {
        title: "Rank #3",
        price: 3200,
        priceHistories: [0, 1400, 2700, 2200, 6000, 3500],
        metric: 1000967345,
        percentage: 25,
        meta: {
          island: "Java",
          province: "West Java",
          city: "Bandung",
          outlet: "Indomaret tasik 2",
        },
      },
    ],
  },
  meta: {
    year: 2023,
    interval: "MONTHLY",
    metric: "gtv",
    slicerTitle: "SKU",
    dicerTitle: "Province",
    dicerSelections: [
      {
        id: 11,
        name: "DKI Jakarta",
      },
    ],
    timespan: 6,
    filter: {
      id: 1, /// id dari filter province = 1 sku 1
      name: "SKU", /// filter master data type name
      value: "SKU 1", /// filter value label
    },
  },
  paging: null,
};

export const TableData2 = {
  code: 200,
  message: "Successfully retrieve tabel 1!",
  data: {
    summary: {
      transaction: 1364798,
      transactionGrowth: 11.2,
      grossTransactionValue: 1364798,
      grossTransactionValueGrowth: 12.2,
      quantity: 1364798,
      quantityGrowth: 12.2,
    },
    ranks: [
      {
        title: "Rank #1",
        childrens: [
          {
            title: "SKU 1",
            price: 3200,
            priceHistories: [0, 2000, 2500, 4000, 6000, 3500],
            metric: 1000967345,
            percentage: 25,
            meta: {
              island: "Java",
              province: "West Java",
              city: "Bandung",
              outlet: "Indomaret tasik 2",
            },
          },
          {
            title: "SKU 2",
            price: 3200,
            priceHistories: [0, 2000, 2500, 4000, 6000, 4500],
            metric: 1000967345,
            percentage: 25,
            meta: {
              island: "Java",
              province: "West Java",
              city: "Tasik Malaya",
              outlet: "Indomaret tasik 1",
            },
          },
        ],
      },
      {
        title: "Rank #4",
        childrens: [
          {
            title: "SKU 1",
            price: 3200,
            priceHistories: [0, 2000, 2500, 4000, 6000, 3500],
            metric: 1000967345,
            percentage: 25,
            meta: {
              island: "Java",
              province: "West Java",
              city: "Bandung",
              outlet: "Indomaret tasik 2",
            },
          },
          {
            title: "SKU 2",
            price: 3200,
            priceHistories: [0, 2000, 2500, 4000, 6000, 4500],
            metric: 1000967345,
            percentage: 25,
            meta: {
              island: "Java",
              province: "West Java",
              city: "Tasik Malaya",
              outlet: "Indomaret tasik 1",
            },
          },
        ],
      },
      {
        title: "Rank #3",
        childrens: [
          {
            title: "SKU 1",
            price: 3200,
            priceHistories: [0, 2000, 2500, 4000, 6000, 3500],
            metric: 1000967345,
            percentage: 25,
            meta: {
              island: "Java",
              province: "West Java",
              city: "Bandung",
              outlet: "Indomaret tasik 2",
            },
          },
          {
            title: "SKU 2",
            price: 3200,
            priceHistories: [0, 2000, 2500, 4000, 6000, 4500],
            metric: 1000967345,
            percentage: 25,
            meta: {
              island: "Java",
              province: "West Java",
              city: "Tasik Malaya",
              outlet: "Indomaret tasik 1",
            },
          },
        ],
      },
    ],
  },
  meta: {
    year: 2023,
    interval: "MONTHLY",
    metric: "quantity",
    slicerTitle: "SKU",
    dicerTitle: "Province",
    slicerSelections: [
      {
        id: 1,
        name: "SKU 1",
      },
      {
        id: 2,
        name: "SKU 2",
      },
    ],
    dicerSelections: [
      {
        id: 11,
        name: "DKI Jakarta",
      },
    ],
    timespan: 6,
    filter: {
      id: 1, /// id dari filter province = 1 west java
      name: "Province", /// filter master data type name
      value: "West java", /// filter value label
    },
  },
  paging: null,
};

export const TableData3 = {
  code: 200,
  message: "Successfully retrieve performance 3!",
  data: {
    summary: {
      transaction: 1364798,
      transactionGrowth: 11.2,
      grossTransactionValue: 1364798,
      grossTransactionValueGrowth: 12.2,
      quantity: 1364798,
      quantityGrowth: 12.2,
    },
    ranks: [
      {
        title: "Rank #1",
        childrens: [
          {
            title: "SKU 1",
            price: 3200,
            priceHistories: [0, 2000, 2500, 4000, 6000, 3500],
            metric: 1000967345,
            percentage: 25,
            meta: {
              island: "Java",
              province: "West Java",
              city: "Bandung",
              outlet: "Indomaret tasik 2",
            },
          },
          {
            title: "SKU 2",
            price: 3200,
            priceHistories: [0, 2000, 2500, 4000, 6000, 4500],
            metric: 1000967345,
            percentage: 25,
            meta: {
              island: "Java",
              province: "West Java",
              city: "Tasik Malaya",
              outlet: "Indomaret tasik 1",
            },
          },
        ],
      },
      {
        title: "Rank #4",
        childrens: [
          {
            title: "SKU 1",
            price: 3200,
            priceHistories: [0, 2000, 2500, 4000, 6000, 3500],
            metric: 1000967345,
            percentage: 25,
            meta: {
              island: "Java",
              province: "West Java",
              city: "Bandung",
              outlet: "Indomaret tasik 2",
            },
          },
          {
            title: "SKU 2",
            price: 3200,
            priceHistories: [0, 2000, 2500, 4000, 6000, 4500],
            metric: 1000967345,
            percentage: 25,
            meta: {
              island: "Java",
              province: "West Java",
              city: "Tasik Malaya",
              outlet: "Indomaret tasik 1",
            },
          },
        ],
      },
      {
        title: "Rank #3",
        childrens: [
          {
            title: "SKU 1",
            price: 3200,
            priceHistories: [0, 2000, 2500, 4000, 6000, 3500],
            metric: 1000967345,
            percentage: 25,
            meta: {
              island: "Java",
              province: "West Java",
              city: "Bandung",
              outlet: "Indomaret tasik 2",
            },
          },
          {
            title: "SKU 2",
            price: 3200,
            priceHistories: [0, 2000, 2500, 4000, 6000, 4500],
            metric: 1000967345,
            percentage: 25,
            meta: {
              island: "Java",
              province: "West Java",
              city: "Tasik Malaya",
              outlet: "Indomaret tasik 1",
            },
          },
        ],
      },
    ],
  },
  meta: {
    year: 2023,
    interval: "MONTHLY",
    metric: "quantity",
    slicerTitle: "SKU",
    dicerTitle: "Province",
    slicerSelections: [
      {
        id: 1,
        name: "SKU 1",
      },
      {
        id: 2,
        name: "SKU 2",
      },
    ],
    dicerSelections: [
      {
        id: 11,
        name: "DKI Jakarta",
      },
    ],
    filter: {
      id: 2, /// id dari filter interval (1 minggu, 2 bulan, 3 quarter, 4 semester, 5 tahun)
      name: "Interval", /// nama filter sesuai id
      value: 1, /// value dari filter interval, 1 = (tanggal, minggu, bulan)
    },
  },
  paging: null,
};
