function CardTitle(props) {
	const slicerTitle = props?.slicerTitle;
	const slicerLength = props?.slicerLength;
	const dicerLabels = props?.dicerLabels;
	const period = props?.period;
	const periodRange = props?.periodRange;
	const dicerTitle = dicerLabels?.join(", ").replace(/,(?!.*,)/gim, " and");

	return (
		<div className="card-header border-0 pb-0">
			{slicerLength > 0 ? (
				<div className="d-flex flex-column">
					<p className="text-body-2lg p-0 m-0">{`Comparison between ${slicerLength} ${slicerTitle}`}</p>
					<p className="text-body-2lg p-0 m-0">{`of ${dicerTitle}`}</p>
					{periodRange != null && <p className="text-body-2lg p-0 m-0">{`from ${periodRange}`}</p>}
					<p className="text-body-2lg p-0 m-0">{`in ${period}`}</p>
				</div>
			) : (
				<p className="text-body-2lg p-0 m-0 text-muted">No slicer selection selected</p>
			)}
		</div>
	);
}

export default CardTitle;
