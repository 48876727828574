import React from "react";
import CardNumber from "components/Composable/CardNumber";
import ComparisonTooltipTile from "components/Composable/ComparisonTooltipTile";
import EmptyCardPlaceholder from "components/Composable/EmptyCardPlaceholder";
import ComparisonProgressBarV2 from "components/Composable/ComparisonProgressBarV2";
import CardTitle from "components/Composable/CardTitle";
import { getDabbleColor } from "utils/UIUtil";
import { dabbleDecimal } from "utils/NumericUtil";
import { thousandFormat } from "utils/NumericUtil";
import { useGetComparisonQuery } from "services/PricingServiceV2";
import { useMemo } from "react";
import { serialAmount } from "utils/DataUtil";

function SlicerComparisonCard(props) {
	const activeFilter = props?.activeFilter;
	const slicersLength = activeFilter?.slicerSelections?.length ?? 0;
	const slicerLabel = activeFilter?.slicer?.label;
	const periodId = activeFilter?.period?.id;
	const body = useMemo(() => {
		const filter = {
			year: activeFilter?.period?.id,
			metric: activeFilter?.metric?.id,
			slicerId: activeFilter?.slicer?.value,
			slicers: activeFilter?.slicerSelections?.map((slicerSelection, _) => slicerSelection.value),
			interval: activeFilter?.interval?.id,
		};
		if (activeFilter?.dicerSelections != null) {
			filter.dicerId = activeFilter?.dicer?.value;
			filter.dicers = activeFilter?.dicerSelections?.map((dicerSelection, _) => dicerSelection.value);
		}
		return filter
	}, [activeFilter?.dicer?.value, activeFilter?.dicerSelections, activeFilter?.interval?.id, activeFilter?.metric?.id, activeFilter?.period?.id, activeFilter?.slicer?.value, activeFilter?.slicerSelections])
	
	const { data } = useGetComparisonQuery({ body, path: '/1'}, { skip: !body.slicerId || !activeFilter?.interval?.id })
	const meta = data?.meta;
	const totalAmount = data?.data?.reduce((a, b) => a?.amount + b?.amount, 0);

	function getDicerLabels() {
		const selections = activeFilter?.dicerSelections ?? activeFilter?.slicerSelections;
		return selections?.map((selection) => selection?.label ?? selection?.name);
	}

	function progressBarCaption() {
		return (
			<div className='row'>
				{data?.data?.map((slicer, index) => {
					const color = getDabbleColor(index, 0);
					return (
						<div className='col' key={index}>
							<p className='m-0' style={{ color }}>
								{slicer.title} : Rp. {thousandFormat(slicer?.amount)}
							</p>
							<p className='mb-2' style={{ color }}>
								or {slicer?.percentage}% of {meta.year}
							</p>
						</div>
					);
				})}
			</div>
		);
	}

	return (
		<div className='col-xl-12 col-md-12'>
			<div className='card'>
				<CardNumber number='4' />
				<CardTitle
					slicerTitle={slicerLabel}
					slicerLength={slicersLength}
					dicerLabels={getDicerLabels()}
					period={periodId}
				/>
				{data?.data?.length > 0 ? (
					<div className='card-body'>
						{progressBarCaption()}
						<div className='dabble-tooltip-container w-100'>
							<div
								className='dabble-tooltip rounded-sm shadow mt-5 py-2 px-4 text-body regular'
								style={{ zIndex: "1080" }}>
								<p className="p-0 pb-0 mb-1">
									{`Combined ${slicersLength} ${activeFilter?.slicer?.label} : ${serialAmount(
										totalAmount,
										activeFilter?.metric?.id
									)} in ${activeFilter?.period?.id}`}
								</p>
								{data?.data?.map((slicer, slicerIndex) => {
									return (
										<ComparisonTooltipTile
											title={slicer?.title}
											value={serialAmount(
												slicer?.amount,
												activeFilter?.metric?.id
											)}
											percentage={slicer?.percentage}
											growth={dabbleDecimal(slicer?.growth)}
											color={getDabbleColor(slicerIndex, 0)}
											key={Math.random()}
										/>
									);
								})}
							</div>
							<ComparisonProgressBarV2 data={data} />
						</div>
					</div>
				) : (
					<EmptyCardPlaceholder />
				)}
			</div>
		</div>
	);
}

export default SlicerComparisonCard;
