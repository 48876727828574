export default function getCookie(name) {
	const cookies = document.cookie.split("; "); // Split cookies into an array
	for (let i = 0; i < cookies.length; i++) {
		const cookiePair = cookies[i].split("="); // Split cookie into key-value pair
		const cookieName = decodeURIComponent(cookiePair[0]); // Decode cookie name
		if (cookieName === name) {
			const cookieValue = decodeURIComponent(cookiePair[1]); // Decode cookie value
			return cookieValue;
		}
	}
	return null; // Return null if cookie not found
}

export function deleteCookie(name) {
	document.cookie = `${encodeURIComponent(name)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
}