export function debounce(func, delay) {
	let timeoutId;
	return function executedFunction(...args) {
		const later = () => {
			clearTimeout(timeoutId);
			func(...args);
		};
		clearTimeout(timeoutId);
		timeoutId = setTimeout(later, delay);
	};
}
