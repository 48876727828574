import { shortCurrency } from "utils/CurrencyUtil";
import { displayPercentage } from "utils/NumericUtil";
import { getDabbleColor } from "utils/UIUtil";

function ComparisonProgressBarV2(props) {
	const data = props.data;
	const parentIndex = props?.parentIndex;
	const parentData = data?.data?.[parentIndex];
	const parentDataChildrensLength = (parentData?.childrens?.length ?? 0) + 2;
	const isMultiLevel = props?.isMultiLevel ?? false;
	function multilevelProgressBar() {
		return (
			<div className="progress">
				{parentData?.childrens.map((slicer, index) => (
					<div
						className="progress-bar"
						role="progressbar"
						style={{
							width: `${slicer?.percentage}%`,
							backgroundColor: getDabbleColor(parentIndex ?? 0, index + 1, parentDataChildrensLength),
						}}
						aria-valuenow={slicer?.percentage}
						aria-valuemin="0"
						aria-valuemax="100"
						key={Math.random()}></div>
				))}
			</div>
		);
	}

	function singleLevelProgressBar() {
		return (
			<div className="progress">
				{data?.data?.map((slicer, index) => (
					<div
						className="progress-bar"
						role="progressbar"
						style={{
							width: `${slicer?.percentage}%`,
							backgroundColor: getDabbleColor(index, 0),
						}}
						aria-valuenow={slicer?.percentage}
						aria-valuemin="0"
						aria-valuemax="100"
						key={Math.random()}></div>
				))}
			</div>
		);
	}

	return (
		<div>
			{isMultiLevel ? multilevelProgressBar() : singleLevelProgressBar()}
			<div className="progress mt-2">
				<div
					className={{
						"progress-bar": true,
						"bg-grey": parentIndex == null,
					}}
					role="progressbar"
					aria-valuenow="100"
					aria-valuemin="0"
					aria-valuemax="100"
					style={{
						backgroundColor:
							parentIndex != null ? getDabbleColor(parentIndex, 0) : null,
						width: "100%",
					}}></div>
			</div>
		</div>
	);
}

export default ComparisonProgressBarV2;
