import {compactDecimal, roundIfWhole} from "./NumericUtil";

export function shortCurrency({prefix = "", num = 0, trillionLabel = null, billionLabel = null, millionLabel = null, thousandLabel = null}) {
    if (num >= 1e12) {
        return prefix + compactDecimal(roundIfWhole((num / 1e12)), 0) + (trillionLabel ?? 't');
    }
    if (num >= 1e9) {
        return prefix + compactDecimal(roundIfWhole((num / 1e9)), 0) + (billionLabel ?? 'mil');
    }
    if (num >= 1e6) {
        return prefix + compactDecimal(roundIfWhole((num / 1e6)), 0) + (millionLabel ?? 'jt');
    }
    if (num >= 1e3) {
        return prefix + compactDecimal(roundIfWhole((num / 1e3)), 0) + (thousandLabel ?? 'rb');
    }
    return num.toFixed(0).replaceAll('.', ',');
}

export function formatCurrency(amount, currency = "IDR") {
    // convert amount to a number and round to 2 decimal places
    const number = Number.parseFloat(amount).toFixed(2);
    
    // format the number as currency
    const formatter = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency,
        currencyDisplay: "symbol",
        maximumFractionDigits: 0,
        useGrouping: true,
    });

// return the formatted value
return formatter.format(number).replaceAll('.', ',');
}