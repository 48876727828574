import axiosInstance from '../core/AxiosInstance';

export async function retrieveSlicerComparison(payload, groupBy){
    try {
        let url = process.env.REACT_APP_DABBLE_ANALYTIC;
        let baseUrl = "/performance";
        switch (groupBy) {
            case 'slicer':
                url += baseUrl + '/comparison/one';
                break;
            case 'dicer':
                url += baseUrl + '/comparison/second';
                break;
            default:
                url += baseUrl + '/comparison/third';
                break;
        }
        return await axiosInstance.post(url, payload)
    } catch (error) {
        console.error(error);
        throw(error)
    }
}

export async function retrieveChartByInterval(payload, groupBy){
    try {
        let url = process.env.REACT_APP_DABBLE_ANALYTIC;
        let baseUrl = "/performance";
        switch (groupBy) {
            case 'dicer-period':
                url += baseUrl + '/one';
                break;
            case 'slicer-period':
                url += baseUrl + '/second';
                break;
            default:
                url += baseUrl + '/third';
                break;
        }
        return await axiosInstance.post(url, payload)
    } catch (error) {
        console.error(error);
        throw(error)
    }
}
