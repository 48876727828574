import { Navigate } from "react-router-dom";

export const guestMiddleware = (element, isAuthenticated, path) => {
    if (!isAuthenticated && path !== 'login') {
        return <Navigate to={"/login"} replace />;
    }
    return element;
}

export const authMiddleware = (element, isAuthenticated, path) => {
    if (isAuthenticated) {
        return <Navigate to={"/dashboard"} replace />;
    }
    return element;
}