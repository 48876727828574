import React from "react";
import { isFirst, isLast } from "utils/NumericUtil";

const TimespanChips = ({
  activeTimespanFilter,
  timespanPeriods,
  setActiveTimespanFilter = () => {},
}) => {
  return (
    <div className="card-action">
      {timespanPeriods?.map((filter, index) => {
        const filterLength = filter.length;
        const marginLeft = !isFirst(index, filterLength) ? " ml-1" : "";
        const marginRight = !isLast(index, filterLength) ? " mr-1" : "";
        const isSelected = filter?.value === activeTimespanFilter?.value;
        return (
          <div
            className={`btn ${
              isSelected ? "btn-primary" : "btn-outline-primary"
            } btn-xxs text-black ${marginLeft}${marginRight}`}
            key={`dgfb${Math.random()}`}
            onClick={() => setActiveTimespanFilter(filter)}
          >
            {filter?.label}
          </div>
        );
      })}
    </div>
  );
};

export default TimespanChips;
