import CardNumber from "components/Composable/CardNumber";
import PricingBarLineChart from "../chart/PricingBarLineChart";
import PricingTable1 from "../table/PricingTable1";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getTimespanFilterByPeriod } from "utils/FilterUtil";

export default function PricingGraphicTableCard(){
    const { activeFilter } = useSelector((state) => state.menu);
    const timespanPeriods = getTimespanFilterByPeriod(activeFilter?.interval?.id || "WEEKLY")
    const defaultActiveTimespan = timespanPeriods[timespanPeriods?.length - 1]

    const [slicerFilter, setSlicerFilter] = useState(activeFilter?.slicerSelections?.[0]);
	const [activeTimespanFilter, setActiveTimespanFilter] = useState(defaultActiveTimespan);

    return (
        <div className="col-xl-12 col-md-12">
            <div className="card">
                <CardNumber number="5" />
                <div className="card-body w-100">
                    <PricingBarLineChart
                        slicerFilter={slicerFilter}
                        setSlicerFilter={setSlicerFilter}
                        activeTimespanFilter={activeTimespanFilter}
                        setActiveTimespanFilter={setActiveTimespanFilter}
                        timespanPeriods={timespanPeriods}
                    />
                    <PricingTable1
                        slicerFilter={slicerFilter}
                        activeTimespanFilter={activeTimespanFilter}
                    />
                </div>
            </div>
        </div>
    );
}