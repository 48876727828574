import {retrieveActiveFilters } from "services/FilterService";
import {baseResponse, GENERAL_ERROR} from 'utils/DataUtil';
import {retrieveAnalyticsAction} from "./filter/AnalyticActions";
import {retrieveMetricsAction} from "./filter/MetricActions";
import {retrieveFormatsAction} from "./filter/FormatActions";
import {retrievePeriodsAction} from "./filter/PeriodActions";
import {retrieveIntervalsAction} from "./filter/IntervalActions";
import {retrieveSlicersAction} from "./filter/SlicerActions";
import rewriteQueryParams from "utils/UrlUtil";

export const UPDATE_FILTERS = '[update active filter]';
export const UPDATE_ACTIVE_FILTERS = '[update filter]';
export const SYNC_ACTIVE_FILTERS = '[synchronize active filter]';
export const RESET_FILTERS = '[reset filter]';

export function updateFilterAction(filter){
    return (dispatch) => {
        dispatch({
            type: UPDATE_FILTERS,
            payload: filter,
        })
    }
}

export function updateActiveFiltersAction(filter, navigate, location){
    return (dispatch) => {
        rewriteQueryParams(filter, location, navigate)
        dispatch({
            type: UPDATE_ACTIVE_FILTERS,
            payload: filter,
        })
    }
}

export function syncActiveFiltersAction(location){
    return (dispatch) => {
        retrieveActiveFilters(location)
        .then((response) => {
            dispatch(retrieveAnalyticsAction(response?.analytic, location))
            dispatch(retrieveMetricsAction(response?.metric))
            dispatch(retrieveFormatsAction(response?.format))
            dispatch(retrievePeriodsAction(response?.period))
            dispatch(retrieveIntervalsAction(response?.interval))
            dispatch(retrieveSlicersAction({ defaultValue : response?.slicer, defaultDicers: response?.dicer }))
            dispatch({
                type: SYNC_ACTIVE_FILTERS,
                payload: null,
                result: response,
            })
        })
        .catch((error) => 
            dispatch(baseResponse(500, false, GENERAL_ERROR))
        );
    };
}
