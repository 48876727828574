import {retrieveDicers, retrieveDicerSelection,} from 'services/FilterService';
import {baseResponse, GENERAL_ERROR} from 'utils/DataUtil';
export const RETRIEVE_DICERS_SUCCESS = '[Retrieve dicer success]';
export const RETRIEVE_DICERS_FAILED = '[Retrieve dicer failed]';
export const RETRIEVE_DICER_SELECTIONS_SUCCESS = '[retrieve dicer selection success]';
export const RETRIEVE_DICER_SELECTIONS_FAILED = '[retrieve dicer selection failed]';
export const UPDATE_SELECTED_DICER = '[update selected dicer]';
export const UPDATE_SELECTED_DICER_SELECTION = '[update selected dicer selection]';

export function retrieveDicersAction(slicerId, defaultValue){
    return (dispatch) => {
        retrieveDicers(slicerId)
        .then((response) => { 
            const datas = response.data.data
            const mappedDatas = datas.map((data, _) => {
                return {
                    label: data.name,
                    value: data.id
                }
            });
            const defaultSelected = defaultValue ?? mappedDatas[0] ?? null;
            dispatch(updateSelectedDicer(defaultSelected))
            if(defaultSelected!=null){
                dispatch(retrieveDicerSelectionAction(defaultSelected.value))
            }
            dispatch({
                type: RETRIEVE_DICERS_SUCCESS,
                payload: null,
                result: mappedDatas,
            })
        })
        .catch((error) => 
        dispatch({
            type: RETRIEVE_DICERS_FAILED,
            payload: null,
            error: error.toString(),
        })
        );
    };
}

export function retrieveDicerSelectionAction(dicerId, payload){
    return (dispatch) => {
        retrieveDicerSelection(dicerId, payload)
        .then((response) => { 
            const datas = response.data.data
            const mappedDatas = datas.map((data, _) => {
                return {
                    label: data.name,
                    value: data.id
                }
            });
            dispatch({
                type: RETRIEVE_DICER_SELECTIONS_SUCCESS,
                payload: payload,
                result: mappedDatas,
            })
        })
        .catch((error) => 
            dispatch({
                type: RETRIEVE_DICER_SELECTIONS_FAILED,
                payload: null,
                error: error.toString(),
            })
        );
    };
}

export function updateSelectedDicer(dicer){
    return (dispatch) => {
        dispatch({
            type: UPDATE_SELECTED_DICER,
            payload: dicer,
        })
    }
}

export const updateSelectedDicerSelections = (dicerSelections) => ({
    type: UPDATE_SELECTED_DICER_SELECTION,
    payload: dicerSelections,
})