import React, { useEffect, useCallback } from 'react'
import { useSearchParams } from "react-router-dom";
import {
  useCheckActivationLinkMutation,
  useActivateAccountMutation,
  useSetExpiredActivationLinkMutation,
} from 'services/ActivateAccountService';
import { useNavigate } from "react-router-dom";
import SetupPasswordForm from 'components/Forms/SetupPassword';
import { useState } from 'react';
import { login } from 'services/AuthService';

const defaultPassword = 'P@ssw0rd123#'

const ActivateAccount = () => {
  const [searchParams] = useSearchParams();
  const [linkExpired, setLinkExpired] = useState(false)
  const [activateAccount, { isLoading, error }] = useActivateAccountMutation()
  const [checkActivationLink, { isLoading: isLoadingCheckActivationLink }] = useCheckActivationLinkMutation()
  const [setExpiredActivationLink] = useSetExpiredActivationLinkMutation()
  const [errorMessage, setErrorMessage] = useState(error?.data?.message)
  const navigate = useNavigate();
  

  const username = searchParams.get('username')
  const id = searchParams.get('id')

  const onFormSubmitted = useCallback(
    async ({ password }) => {
      setErrorMessage('')
      const { data } = await checkActivationLink(id)
      if (data?.data?.isExpired === false) {
        const resp = await activateAccount({ username, id, password })
        if (resp?.data?.success) {
          await setExpiredActivationLink({ id, channel: 'EMAIL' })
          navigate('/reset-password/success')
        }
        if (resp?.error?.data) {
          setErrorMessage(
            resp?.error?.data?.message || 'Failed to update your password'
          )
        }
      } else {
        setErrorMessage(data?.message || 'Failed to update your password')
      }
    },
    [activateAccount, checkActivationLink, id, navigate, setExpiredActivationLink, username],
  )

  const startup = useCallback(
    async () => {
      const { data } = await checkActivationLink(id)
      if (data?.data?.isExpired === false) {
        login(username, defaultPassword)
      } else {
        setLinkExpired(true)
      }
    },
    [checkActivationLink, id, username],
  )
  
  useEffect(() => {
    startup()
  }, [startup])

  useEffect(() => {
    if (!id || !username) {
      navigate('/')
    }
    
  }, [id, navigate, username])
  
  return (
    <div className="min-vh-100 d-flex justify-content-center align-items-center">
      <div className="authentication w-100">
        <div className="container w-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-6">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  {linkExpired ? (
                    <div className="card p-5 border">
                      <p className="text-body-lg bold text-center mb-4">The link has been expired</p>
                      <div className="text-body-sm text-muted text-center">Please contact customer support</div>
                    </div>
                  ): (
                    <div className="card p-4 border">
                      <p className="text-body-lg bold text-center mb-2">Set new password</p>
                      {errorMessage && <div className="text-danger text-center fs-12 mb-2">{errorMessage}</div>}
                      <p className="text-body-sm regular mt-2">Username: {username}</p>
                      <SetupPasswordForm onSubmit={onFormSubmitted} isLoading={isLoading || isLoadingCheckActivationLink} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActivateAccount