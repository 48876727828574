import React, { useState } from 'react'
import { Link } from 'react-router-dom';

let errorsObj = { password: '', confirmPassword: '' };

const SetupPasswordForm = ({ onSubmit, isLoading }) => {

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordVisible, setShowPassword] = useState(false);
  const [isConfirmPasswordVisible, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState(errorsObj);

  const validateForm = () => {

    let error = false;
    const newErrors = { ...errorsObj }; // create a new object with the current errors

    // Validate password
    if (password === '') {
      newErrors.password = 'Password cannot be empty';
      error = true;
    } else if (password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters';
      error = true;
    }

    // Validate confirm password
    if (password !== confirmPassword) {
      newErrors.confirmPassword = 'Password don’t match';
      error = true;
    }

    // validate format if not empty
    setErrors(newErrors);

    if (error) {
      return false;
    } else {
      return true;
    }
  }

  const onSubmitForm = e => {
    e.preventDefault();
    
    if (validateForm()) {
      onSubmit({ password });
    }
  }
  
  return (
    <form onSubmit={onSubmitForm}>
      <div className="form-group">
        <label className="mb-2 text-body-sm regular">
          New Password
        </label>
        <div className={`input-group d-xl-inline-flex d-none ${errors.password ? 'is-invalid' : ''}`}>
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fa fa-lock color-muted"></i>
            </span>
          </div>
          <input
            type={isPasswordVisible ? "text" : "password"}
            className="form-control"
            placeholder="Enter your new password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="input-group-append">
            <span className="input-group-text">
              <span onClick={() => setShowPassword(!isPasswordVisible)}>
                <i className={`fa ${isPasswordVisible ? "fa-eye-slash" : "fa-eye"} color-muted`}></i>
              </span>
            </span>
          </div>
        </div>
        {!errors.password && <div className="text-body-xs text-muted mt-1">Your password must be at least 6 characters</div>}
        {errors.password && <div className="text-danger fs-12 mt-1">{errors.password}</div>}
      </div>
      <div className="form-group mt-3">
        <label className="mb-2 text-body-sm regular">
          Re-enter Password
        </label>
        <div className={`input-group d-xl-inline-flex d-none ${errors.confirmPassword ? 'is-invalid' : ''}`}>
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fa fa-lock color-muted"></i>
            </span>
          </div>
          <input
            type={isConfirmPasswordVisible ? "text" : "password"}
            className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
            placeholder="Re-enter your new password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <div className="input-group-append">
            <span className="input-group-text">
              <span onClick={() => setShowConfirmPassword(!isConfirmPasswordVisible)}>
                <i className={`fa ${isConfirmPasswordVisible ? "fa-eye-slash" : "fa-eye"} color-muted`}></i>
              </span>
            </span>
          </div>
        </div>
        {errors.confirmPassword && <div className="text-danger fs-12 mt-1">{errors.confirmPassword}</div>}
      </div>
      <button type="submit" disabled={isLoading} className={`btn btn-block mt-4 text-black btn-rounded ${isLoading ? 'btn-secondary' : 'btn-primary'}`}>
        Set Password
      </button>
    </form>
  )
}

export default SetupPasswordForm