import CardNumber from "components/Composable/CardNumber";
import React, { useCallback, useMemo } from "react";
import Chart from "react-apexcharts";
import { renderToString } from "react-dom/server";
import { useSelector } from "react-redux";
import { useGetChartQuery } from "services/DiscountService";
import { serialAmount } from "utils/DataUtil";
import { dabbleDecimal } from "utils/NumericUtil";

const gtvPrefix = " GTV";
const discountPrefix = " Discount";

const colors = [
  "#008FFB",
  "#80c7fd",
  "#CCC87D",
  "#FFFBB0",
  "#4CAF50",
  "#A5D6A7",
  "#f95045",
  "#FED3D0",
  "#FF9900",
  "#ffd6b9",
];

const GroupStackedBar = (props) => {
  const data = props.data;
  const meta = props.data.meta;
  const menu = useSelector((state) => state.menu);
  const categories = data?.data?.map((i) => i.title);
  const activeDicer = menu.activeFilter?.dicer?.label;
  const selectedDicersLength = menu.activeFilter?.dicerSelections?.length;
  const selectedDicers = menu.activeFilter?.dicerSelections?.map(
    (i) => i.label
  );
  const seperatedSelectedDicers = selectedDicers
    ?.join(", ")
    .replace(/,(?!.*,)/gim, " and");

  const dataChildrensRestructured = useMemo(() => {
    const result = [];
    data?.data?.forEach((element) => {
      element?.childrens?.forEach((data) => {
        const dataGTV = {
          name: (data?.title ?? data?.name) + gtvPrefix,
          amount: data.amount + data.discountedAmount,
          growth: data.growth,
          group: (data?.title ?? data?.name),
        };
        const dataDiscount = {
          name: (data?.title ?? data?.name) + discountPrefix,
          amount: data.discountedAmount,
          growth: data.discountedGrowth,
          group: (data?.title ?? data?.name),
        };
        result.push(dataDiscount);
        result.push(dataGTV);
      });
    });

    return result;
  }, [data]);

  const series = useMemo(() => {
    const series = [];
    dataChildrensRestructured.forEach((element) => {
      const dataIsAlreadyAvailable = series.filter((i) => i.name === element.name).length > 0;
      if (!dataIsAlreadyAvailable) {
        series.push({
          data: dataChildrensRestructured
            .filter((i) => i.name === element.name)
            .map((i) => i.amount),
          ...element,
        });
      } else {
        const index = series.findIndex((i) => i.name === element.name);
        const newDataNumber = dataChildrensRestructured?.[element]?.data;
        if (newDataNumber) {
          series[index].data.push(newDataNumber);
        }
      }
    });
    return series;
  }, [dataChildrensRestructured]);

  const renderToolTip = useCallback(
    ({ dataPointIndex }) => {
      return renderToString(
        <div className="rounded px-4 py-2">
          {series?.map((data, index) => {
            const isPositive = data.growth > 0;
            const color = isPositive ? "#21B830" : "#FF2626";
            const arrow = isPositive
              ? "M0 11L11 -4.72849e-07L22 11"
              : "M0 -9.61651e-07L11 11L22 0";
            const symbol = isPositive ? "+" : "";
            const growth = `${symbol}${dabbleDecimal(data?.growth)}`;

            return (
              <div
                className={`d-flex flex-row align-content-between text-body-xs align-items-center ${
                  (index + 1) % 2 === 0 &&
                  index + 1 !== series.length &&
                  "border-bottom"
                }`}
                key={Math.random()}
              >
                <div className="col col-auto">
                  <div className="px-2 py-1 my-1 border rounded">
                    <svg
                      className="mr-1"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="12"
                        height="12"
                        rx="12.5"
                        fill={colors[index]}
                      ></rect>
                    </svg>
                    {data?.name}
                  </div>
                </div>
                <div className="col"></div>
                <div className="col text-right">
                  <span className="p-0 m-0 mr-2">
                    Rp. {data.data[dataPointIndex]}
                  </span>
                </div>
                <div className="col d-flex align-items-center">
                  (
                  <svg
                    className="p-0 m-0"
                    width="12"
                    height="12"
                    viewBox="0 0 24 12"
                  >
                    <path d={arrow} fill={color} />
                  </svg>
                  <div className="text-right p-0 m-0">
                    <span className="p-0 m-0" style={{ color: color }}>
                      {" "}
                      {growth}{" "}
                    </span>
                  </div>
                  )
                </div>
              </div>
            );
          })}
        </div>
      );
    },
    [series]
  );

  const options = useMemo(() => {

    const getCustomLegend = (series, colors) => {
      return series.map((data, index) => ({
        name: data.name,
        marker: {
          fillColors: [colors[index]],
        },
      }));
    };

    return {
      series: series,
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: 5,
        colors: ["fff"],
      },
      dataLabels: {
        formatter: (val) => {
          return val / 1000 + "K";
        },
        enabled: false,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "50px",
          borderRadius: 4,
          borderRadiusApplication: "around",
          borderRadiusWhenStacked: "all",
        },
      },

      grid: {
        padding: {
          bottom: 20, // Add space at the bottom for better visualization
        },
      },
      xaxis: {
        categories,
        label: {
          formatter: (val) => {
            const text = val?.split(" ");
            const modifiedText = [text?.[0].slice(0, 3), text?.[1]];
            return modifiedText;
          },
        },
      },
      fill: {
        opacity: 1,
      },
      colors,
      yaxis: {
        labels: {
          formatter: (val) => {
            return val / 1000 + "K";
          },
        },
      },
      legend: {
        show: true,
        horizontalAlign: "center",
        position: 'top',
        showForSingleSeries: true,
        markers: {
            radius: 20,
            fillColors: colors,
        },
        customItem: getCustomLegend(series, colors),
      
      },
      tooltip: {
        enabled: true,
        shared: true,
        intersect: false,
        custom: renderToolTip,
      },
    };
  }, [categories, renderToolTip, series]);

  return (
    <Chart
      options={options}
      series={options.series}
      type="bar"
      width="100%"
      height={400}
    />
  );
};

export default GroupStackedBar;
