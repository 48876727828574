import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchSlicerComparison } from "store/actions/MarketshareActions";
import { dabbleDecimal, displayPercentage } from "utils/NumericUtil";
import { intervalLabel, serialAmount } from "utils/DataUtil";
import { getDabbleColor } from "utils/UIUtil";
import { debounce } from "utils/InputUtil";
import CardNumber from "components/Composable/CardNumber";
import ComparisonTooltipTile from "components/Composable/ComparisonTooltipTile";
import EmptyCardPlaceholder from "components/Composable/EmptyCardPlaceholder";
import CardTitle from "components/Composable/CardTitle";
import ComparisonProgressBarMulti from "components/Composable/ComparisonProgressBarMulti";

function SlicerComparisonCard3(props) {
	const cardScrollRef = useRef(null);
	const innerCardRef = useRef(null);
	const activeFilter = props?.activeFilter;
	const slicersLength = activeFilter?.slicerSelections?.length ?? 0;
	const slicerLabel = activeFilter?.slicer?.label;
	const periodId = activeFilter?.period?.id;
	const dispatch = useDispatch();
	const [isExpanded, setZoom] = useState(false);
	const [data, setData] = useState(null);
	const dataGroupLength = data?.datas?.length ?? 0;
	const threshold = Math.ceil(dataGroupLength / 6);

	useEffect(() => {
		if(activeFilter?.slicerSelections != null){
			function fetchData() {
				const filter = {
					year: activeFilter?.period?.id,
					metric: activeFilter?.metric?.id,
					slicer_id: activeFilter?.slicer?.value,
					slicers: activeFilter?.slicerSelections?.map((slicerSelection, _) => slicerSelection.value),
					dicers: activeFilter?.dicerSelections?.map((dicerSelection, _) => dicerSelection.value),
					interval: activeFilter?.interval?.id,
				};
				if (activeFilter?.dicerSelections != null) {
					filter.dicer_id = activeFilter?.dicer?.value;
				}
				dispatch(fetchSlicerComparison(filter, "period", setData));
			}
			const debouncedFetchData = debounce(fetchData, 250);
			debouncedFetchData();
		}
	}, [activeFilter, dispatch]);

	function getDicerLabels() {
		const dataSelections = data?.dicerSelections ?? activeFilter?.slicerSelections;
		return dataSelections?.map((dicerSelection) => dicerSelection?.name ?? dicerSelection?.label);
	}

	function getyearRange() {
		if (data != null) {
			const months = data.datas.map((data, index) => data.interval);
			const lastMonths = months[months.length - 1];
			return `${months[0]} to ${lastMonths}`;
		}
		return null;
	}

	function progressBarBottomCaption(parentData, parentIndex) {
		const colorStyle = {
			color: getDabbleColor(parentIndex, 0),
		};

		return (
			<p
				className="mt-1 mb-0 text-body-s text-center"
				style={colorStyle}>
				{parentIndex + 1}
			</p>
		);
	}

	return (
		<div className="col-xl-12 col-md-12">
			<div className="card">
				<CardNumber number="8" />
				<CardTitle
					slicerTitle={slicerLabel}
					slicerLength={slicersLength}
					dicerLabels={getDicerLabels()}
					period={periodId}
					periodRange={getyearRange()}
					key={Math.random()}
				/>
				{data != null ? (
					<div
						className="card-body text-body-s regular"
						ref={cardScrollRef}>
						<div
									className="d-flex mb-3"
									style={{ gap: "10px" }}
									ref={innerCardRef}
									key={Math.random()}>
									{data.datas.map((parentData, parentIndex) => (
										<div
											className="dabble-tooltip-container"
											style={{width:"100%"}}
											key={Math.random()}>
											{/* Tooltip */}
											<div
												className="dabble-tooltip rounded-sm shadow mt-5 py-2 px-4 text-body regular"
												style={{ zIndex: "1080" }}>
												<p className="p-0 pb-0 mb-1">
													{intervalLabel(activeFilter?.interval?.id)} {parentIndex + 1} ({parentData.interval}): {serialAmount(parentData?.totalAmount, activeFilter?.metric?.id)} or {displayPercentage(parentData.percentage)}% of {activeFilter?.period?.id}
												</p>
												{parentData.childrens?.map((slicer, slicerIndex) => {
													return (
														<ComparisonTooltipTile
															title={slicer.name}
															value={serialAmount(slicer.amount, activeFilter?.metric?.id)}
															percentage={slicer.percentage}
															growth={dabbleDecimal(slicer.growth)}
															color={getDabbleColor(slicerIndex, parentIndex, 10)}
															key={Math.random()}
														/>
													);
												})}
											</div>
											<ComparisonProgressBarMulti
												data={data}
												parentIndex={parentIndex}
												parentData={parentData}
												isExpanded={isExpanded}
												key={Math.random()}
											/>
											{progressBarBottomCaption(parentData, parentIndex)}
										</div>
									))}
								</div>
					</div>
				) : (
					<EmptyCardPlaceholder key={Math.random()}/>
				)}
			</div>
		</div>
	);
}

export default SlicerComparisonCard3;
