import React from 'react'
import { Link } from "react-router-dom";

const UpdatePasswordSuccess = () => {
  return (
    <div className="min-vh-100 d-flex justify-content-center align-items-center">
      <div className="container w-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="row no-gutters">
              <div className="col-xl-12">
                <div className="card p-4 border">
                  <p className="text-body-lg bold text-center mb-4">Your password has been updated</p>
                  <div className="text-body-sm text-muted text-center mt-1">Sign In with your new password.</div>
                  <Link to="/login">
                    <button className={`btn btn-block mt-4 text-black btn-rounded btn-primary`}>
                      Go to Sign In
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdatePasswordSuccess