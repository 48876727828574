import {retrieveAnalytics} from 'services/FilterService';

export const RETRIEVE_ANALYTICS_CONFIRMED = '[retrieve format action] confirmed retrieve analytic';
export const RETRIEVE_ANALYTICS_FAILED = '[retrieve format action] confirmed retrieve analytic';
export const UPDATE_SELECTED_ANALYTIC = '[update selected analytic]';

export function retrieveAnalyticsAction(defaultValue, location){
    return (dispatch) => {
        retrieveAnalytics()
        .then((response) => { 
            const activeAnalytic = (defaultValue?.route === location.pathname) ? defaultValue : response?.data?.find(data => data.route === location.pathname)
            dispatch(retrieveAnalyticsConfirmedAction(response.data))
            dispatch(updateSelectedAnalytic(activeAnalytic ?? response.data[0]))
        })
        .catch((error) => {
            dispatch(retrieveAnalyticsFailedAction(error));
        });
    };
}

export function retrieveAnalyticsConfirmedAction(data) {
    return {
        type: RETRIEVE_ANALYTICS_CONFIRMED,
        result: data,
    };
}

export function retrieveAnalyticsFailedAction(data) {
    return {
        type: RETRIEVE_ANALYTICS_FAILED,
        result: data,
    };
}

export function updateSelectedAnalytic(analytic){
    return (dispatch) => {
        dispatch({
            type: UPDATE_SELECTED_ANALYTIC,
            payload: analytic,
        })
    }
}