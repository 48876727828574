import {retrieveSlicers, retrieveSlicerSelection,} from 'services/FilterService';
import {retrieveDicersAction,} from './DicerActions';

export const RETRIEVE_SLICERS_SUCCESS = '[Retrieve slicer success]';
export const RETRIEVE_SLICERS_FAILED = '[Retrieve slicers failed]';
export const RETRIEVE_SLICER_SELECTIONS_SUCCESS = '[retrieve slicer selection success]';
export const RETRIEVE_SLICER_SELECTIONS_FAILED = '[retrieve slicer selection failed]';
export const UPDATE_SELECTED_SLICER = '[update selected slicer]';
export const UPDATE_SELECTED_SLICER_SELECTION = '[update selected slicer selection]';

export function retrieveSlicersAction({defaultValue, defaultDicers}){
    return (dispatch) => {
        retrieveSlicers()
        .then((response) => { 
            const datas = response.data.data
            const mappedDatas = datas.map((data, _) => {
                return {
                    label: data.name,
                    value: data.id
                }
            });
            const defaultSelected = defaultValue ?? mappedDatas[0];
            dispatch(updateSelectedSlicer(defaultSelected))
            dispatch(retrieveSlicerSelectionAction(defaultSelected.value))
            dispatch(retrieveDicersAction(defaultSelected.value, defaultDicers))
            dispatch({
                type: RETRIEVE_SLICERS_SUCCESS,
                payload: null,
                result: mappedDatas,
            })
        })
        .catch((error) => 
            dispatch({
                type: RETRIEVE_SLICERS_FAILED,
                payload: null,
                error: error.toString(),
            })
        );
    };
}

export function retrieveSlicerSelectionAction(slicerId, payload){
    return (dispatch) => {
        retrieveSlicerSelection(slicerId, payload)
        .then((response) => { 
            const datas = response.data.data
            const mappedDatas = datas.map((data, _) => {
                return {
                    label: data.name,
                    value: data.id
                }
            });
            dispatch({
                type: RETRIEVE_SLICER_SELECTIONS_SUCCESS,
                payload: payload,
                result: mappedDatas,
            })
        })
        .catch((error) => 
            dispatch({
                type: RETRIEVE_SLICER_SELECTIONS_FAILED,
                payload: null,
                error: error.toString(),
            })
        );
    };
}

export function updateSelectedSlicer(slicer){
    return (dispatch) => {
        dispatch({
            type: UPDATE_SELECTED_SLICER,
            payload: slicer,
        })
    }
}

export const updateSelectedSlicerSelections = (slicerSelections) => ({
    type: UPDATE_SELECTED_SLICER_SELECTION,
    payload: slicerSelections,
})