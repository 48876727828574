import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { debounce } from "utils/InputUtil";
import { getPeriodicFilter } from "utils/FilterUtil";
import YtdTransactionCard from "components/Dashboard/card/YtdTransactionCard";
import YtdGrossTransactionCard from "components/Dashboard/card/YtdGrossTransactionCard";
import YtdQuantityCard from "components/Dashboard/card/YtdQuantityCard";
import SlicerComparisonCard from "components/Dashboard/marketshare/card/SlicerComparisonCard";
import SlicerComparisonCard2 from "components/Dashboard/marketshare/card/SlicerComparisonCard2";
import SlicerComparisonCard3 from "components/Dashboard/marketshare/card/SlicerComparisonCard3";
import DicerGraphicCard from "components/Dashboard/marketshare/card/DicerGraphicCard";

function Marketshare(props) {
	const [_props, saveProps] = useState(null);
	const _menu = _props?.menu;

	useEffect(() => {
		const onSaveProps = debounce(saveProps, 250);
		onSaveProps(props);
	}, [props]);

	return _menu != null ? (
		<div>
			<div className="col">
				<div className="row">
					<h4 className="bold m-0">Marketshare Analytic</h4>
				</div>
				<div className="row">
					<p className="text-body regular">{`in the period of ${_menu?.activeFilter?.period?.title ?? _menu?.filter?.period?.title}`}</p>
				</div>
			</div>
			<div className="row">
				{/* Summary card sections */}
				<YtdTransactionCard activeFilter={_menu?.activeFilter} />
				<YtdGrossTransactionCard activeFilter={_menu?.activeFilter} />
				<YtdQuantityCard activeFilter={_menu?.activeFilter} />

				<SlicerComparisonCard activeFilter={_menu?.activeFilter} />
				{/* Marketshare Pie Chart (Dicer)*/}
				{_menu?.activeFilter?.dicerSelections != null ? <DicerGraphicCard
					activeFilter={_menu?.activeFilter}
					groupBy="dicer-period"
					filters={_menu?.activeFilter?.slicerSelections}
					number="5"
				/>: null}
				

				{_menu?.activeFilter?.dicerSelections != null ? <SlicerComparisonCard2 activeFilter={_menu?.activeFilter} /> : null}
				{/* Marketshare Pie Chart (Dicer)*/}
				<DicerGraphicCard
					activeFilter={_menu?.activeFilter}
					groupBy="slicer-period"
					filters={_menu?.activeFilter?.dicerSelections}
					number="7"
				/>

				{/* Marketshare Pie Chart (Slicer)*/}
				<SlicerComparisonCard3 activeFilter={_menu?.activeFilter} />
				{/* Marketshare Pie Chart (Dicer 2) */}
				{_menu?.activeFilter?.dicerSelections != null ? <DicerGraphicCard
					activeFilter={_menu?.activeFilter}
					groupBy="dicer-slicer"
					filters={getPeriodicFilter(
						_menu?.activeFilter?.interval?.id,
						_menu?.activeFilter?.period?.id
					)}
					number="9"
				/> : null}
			</div>
		</div>
	) : null;
}

const mapStateToProps = (state) => {
	return {
		menu: state.menu,
	};
};

export default connect(mapStateToProps, null)(Marketshare);
