import {retrieveChartByInterval, retrieveSlicerComparison,} from 'services/PerformanceService';

export const RETRIEVE_PERFORMANCE_SLICER_COMPARISON_SUCCESS = '[Retrieve performance slicer comparison success]';
export const RETRIEVE_PERFORMANCE_SLICER_COMPARISON_FAILED = '[Retrieve performance slicer comparison failed]';
export const RETRIEVE_PERFORMANCE_CHART_BY_INTERVAL_SUCCESS = '[Retrieve performance chart by interval success]';
export const RETRIEVE_PERFORMANCE_CHART_BY_INTERVAL_FAILED = '[Retrieve performance chart by interval failed]';

export function fetchSlicerComparison(payload, groupBy, callback){
    return (dispatch) => {
        retrieveSlicerComparison(payload, groupBy)
        .then((response) => { 
            const responseData = response.data.data;
            responseData.datas = (responseData.datas != null)? responseData.datas.slice(0, responseData.datas.length >= 12 ? 12 : responseData.datas.length) : null;
            const amounts = responseData.datas.map((data, index) => data.amount ?? data.totalAmount);
            const totalAmounts = amounts.reduce((a, b)=> a + b);
            responseData.totalAmount = totalAmounts;
            /// TODO : update data when slicer
            if(groupBy !== 'slicer'){
                responseData.datas.map((data, index) => {
                    const newPercentage = (data?.amount ?? data?.totalAmount) / totalAmounts * 100;
                    data.percentage = newPercentage;
                    const dicerAmounts = data.childrens.map((children, index) => children.amount);
                    const dicerTotalAmounts = dicerAmounts.reduce((a, b)=> a + b);
                    data.childrens.map((dicer, index) => {
                        const newPercentage =  dicer.amount / dicerTotalAmounts * 100 ;
                        dicer.percentage = newPercentage;
                        /// Check for slicer children then repeat
                        if(dicer.childrens!=null){
                            const slicerAmounts = dicer.childrens.map((children, index) => children.amount);
                            const slicerTotalAMounts = slicerAmounts.reduce((a, b)=> a + b);
                            dicer.childrens.map((slicer, index) => {
                                const slicerPercentage =  slicer.amount / slicerTotalAMounts * 100 ;
                                slicer.percentage = slicerPercentage;
                                return slicer
                            })
                        }
                        return dicer
                    })
                    return data;
                })
            }
            dispatch({
                type: RETRIEVE_PERFORMANCE_SLICER_COMPARISON_SUCCESS,
                payload: responseData,
            })
            callback(responseData)
        })
        .catch((error) => { 
            dispatch({
                type: RETRIEVE_PERFORMANCE_SLICER_COMPARISON_FAILED,
                payload: null,
                error: error.toString(),
            }) 
            callback(null)
        });
    };
}

export function fetchChartByInterval(filter, groupBy, callback){
    return (dispatch) => {
        retrieveChartByInterval(filter, groupBy)
        .then((response) => { 
            const responseData = response.data.data;
            const amounts = responseData.datas.map((data, index) => data.amount);
            const totalAmounts = amounts.reduce((a, b)=> a + b);
            responseData.totalAmount = totalAmounts;
            /// TODO : update data when slicer
            responseData.datas.map((data, index) => {
                const childrenAmounts = data.childrens.map((children, index) => children.amount);
                const childrenTotalAmounts = childrenAmounts.reduce((a, b)=> a + b);
                data.childrens.map((children, index) => {
                    const newPercentage =  children.amount / childrenTotalAmounts * 100 ;
                    children.percentage = newPercentage;
                    return children
                })
                return data;
            })
            dispatch({
                type: RETRIEVE_PERFORMANCE_CHART_BY_INTERVAL_SUCCESS,
                payload: responseData,
            })
            callback(responseData)
        })
        .catch((error) => { 
            dispatch({
                type: RETRIEVE_PERFORMANCE_CHART_BY_INTERVAL_FAILED,
                payload: null,
                error:  error.toString(),
            }) 
            callback(null)
         });
    }
}