import {retrievePeriods} from 'services/FilterService';

export const RETRIEVE_PERIODS_CONFIRMED = '[retrieve format action] confirmed retrieve period';
export const RETRIEVE_PERIODS_FAILED = '[retrieve format action] confirmed retrieve period';
export const UPDATE_SELECTED_PERIOD = '[update selected period]';

export function retrievePeriodsAction(defaultValue){
    return (dispatch) => {
        retrievePeriods()
        .then((response) => { 
            dispatch(retrievePeriodsConfirmedAction(response.data));
            const currentYear = new Date().getFullYear();
            const filteredList = response.data.find((period) =>  period?.id?.toString() === currentYear.toString());
            dispatch(updateSelectedPeriod(defaultValue ?? filteredList))
        })
        .catch((error) => {
            dispatch(retrievePeriodsFailedAction(error));
        });
    };
}

export function retrievePeriodsConfirmedAction(data) {
    return {
        type: RETRIEVE_PERIODS_CONFIRMED,
        result: data,
    };
}

export function retrievePeriodsFailedAction(data) {
    return {
        type: RETRIEVE_PERIODS_FAILED,
        result: data,
    };
}

export function updateSelectedPeriod(period){
    return (dispatch) => {
        dispatch({
            type: UPDATE_SELECTED_PERIOD,
            payload: period,
        })
    }
}