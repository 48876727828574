import axiosInstance from '../core/AxiosInstance';
import { activeFiltersKey } from '../core/StorageKey';

export function retrieveAnalytics(){
    return new Promise((resolve, reject) => {
        resolve({
            data: [{
                id: 1,
                title: 'Marketshare',
                route: '/marketshare',
            },{
                id: 2,
                title: 'Performance',
                route: '/performance',
            },{
                id: 3,
                title: 'Growth',
                route: '/growth',
            },
            // {
            //     title: 'Affinity',
            //     route: '/affinity',
            // },
            {
                id: 4,
                title: 'Discount',
                route: '/discount',
            },
            {
                id: 5,
                title: 'Pricing',
                route: '/pricing',
            }
        ]
        });
    });
}

export function retrieveMetrics() {
	return new Promise((resolve, reject) => {
		resolve({
			data: [{
				id: 'transaction',
				title: 'Transaction',
			}, {
				id: 'gtv',
				title: 'GTV',
			}, {
				id: 'quantity',
				title: 'Quantity',
			}]
		});
	});
}

export function retrieveFormats() {
	return new Promise((resolve, reject) => {
		resolve({
			data: [{
				id: 'graphic',
				title: 'Graphic'
			}, {
				id: 'table',
				title: 'Table'
			}]
		});
	});
}

export function retrievePeriods(){
    return new Promise((resolve, reject) => {
        resolve({
            data: [{
                id: '2017',
                title: '2017'
            },{
                id: '2018',
                title: '2018'
            },{
                id: '2019',
                title: '2019'
            },{
                id: '2020',
                title: '2020'
            },{
                id: '2021',
                title: '2021'
            },{
                id: '2022',
                title: '2022'
            },{
                id: '2023',
                title: '2023'
            },{
                id: '2024',
                title: '2024'
            }]
        });
    });
}

export function retrieveIntervals(){
    return new Promise((resolve, reject) => {
        resolve({
            data: [{
                id: 'WEEKLY',
                title: 'Week'
            },{
                id: 'MONTHLY',
                title: 'Month'
            },{
                id: 'QUARTERLY',
                title: 'Quarter'
            },{
                id: 'SEMESTERLY',
                title: 'Semester'
            },{
                id: 'ANNUAL',
                title: 'Annual'
            }]
        });
    });
}

export async function retrieveSlicers(period){
    const url = `${process.env.REACT_APP_DABBLE_MASTER_DATA_PATH}/api/slicer/list`;
    return await axiosInstance.get(url);
}

export async function retrieveSlicerSelection(slicerId, payload = defaultSelectionsPayload){
    const requestBody = {
        ...defaultSelectionsPayload,
        ...payload,
    }
    const url = `${process.env.REACT_APP_DABBLE_MASTER_DATA_PATH}/api/slicer/${slicerId}/slicer-selection`;
    return await axiosInstance.post(url, requestBody);
}

export async function retrieveDicers(slicerId){
    const url = `${process.env.REACT_APP_DABBLE_MASTER_DATA_PATH}/api/dicers/dicerList/${slicerId}`;
    return await axiosInstance.get(url);
}

export async function retrieveDicerSelection(dicerId, payload = defaultSelectionsPayload){
    const requestBody = {
        ...defaultSelectionsPayload,
        ...payload,
    }
    const url = `${process.env.REACT_APP_DABBLE_MASTER_DATA_PATH}/api/dicers/${dicerId}/dicer-selection`;
    return await axiosInstance.post(url, requestBody);
}

export async function saveActiveFilters(activeFilters) {
	localStorage.setItem(activeFiltersKey, JSON.stringify(activeFilters));
}

export async function retrieveActiveFilters(location){
    if(location?.search !== null && location?.search !== ''){
        const queryParams = new URLSearchParams(location?.search);
        const filters = queryParams.get('filters');
        return new Promise((resolve, reject) => {
            resolve(JSON.parse(filters))
        });
    }
    return null;
}

const defaultSelectionsPayload = {
	keywordName: null,
	excludeSelectionId: [],
	pageable: {
		pageNumber: 0,
		pageSize: 100,
		sortDirection: "asc",
		sortProperty: "name"
	}
}