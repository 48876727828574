import DabbleDropdown from "components/Forms/Dropdown/DabbleDropdown";
import React, { useCallback, useMemo } from "react";
import Chart from "react-apexcharts";
import { renderToString } from "react-dom/server";
import { useSelector } from "react-redux";
import { getDabbleColor } from "utils/UIUtil";
import { useGetChartQuery } from "services/PricingServiceV2";
import EmptyCardPlaceholder from "components/Composable/EmptyCardPlaceholder";
import TimespanChips from "components/Composable/TimespanChips";

const PricingBarLineChart = ({
	slicerFilter,
	setSlicerFilter = () => {},
	activeTimespanFilter,
	setActiveTimespanFilter = () => {},
	timespanPeriods
}) => {
	const menu = useSelector((state) => state.menu);
	const activeFilter = menu?.activeFilter;
	const slicerSelections = activeFilter?.slicerSelections

	const selectedDicersLength = activeFilter?.dicerSelections?.length;
	const colors = activeFilter?.slicerSelections?.map((dicer, index) => getDabbleColor(index, 0));
	const slicerLength = activeFilter?.slicerSelections?.length ?? 0;

	const body = useMemo(() => {
		const filter = {
			year: activeFilter?.period?.id,
			interval: activeFilter?.interval?.id,
			timespan: activeTimespanFilter?.value ?? 1,
			metric: activeFilter?.metric?.id,
			slicerId: activeFilter?.slicer?.value,
			slicerFilter: slicerFilter?.value,
		};
		if (activeFilter?.dicerSelections != null) {
			filter.dicerId = activeFilter?.dicer?.value;
			filter.dicerSelections = activeFilter?.dicerSelections?.map((dicerSelection, _) => dicerSelection.value);
		}
		return filter
	}, [activeFilter, slicerFilter, activeTimespanFilter])

	const { data: dataChart } = useGetChartQuery({ body, path: '/1'}, { skip: !body.slicerId || !activeTimespanFilter })
	
	const data = dataChart?.data
	const meta = dataChart?.meta
	const categories = data?.map((i) => i.title);

	const dataRestructured = useMemo(() => {
		const result = [];
		data?.forEach((element) => {
			element?.childrens?.forEach((data) => {
				result.push({
					name: data.title,
					amount: data.average,
				});
			});
		});

		return result;
	}, [data]);

	const dataSeries = useMemo(() => {
		const series = [];
		dataRestructured?.forEach((element) => {
			const dataIsAlreadyAvailable = series.filter((i) => i.name === element.name).length > 0;
			if (!dataIsAlreadyAvailable) {
				series.push({
					data: dataRestructured?.filter((i) => i.name === element.name).map((i) => i.amount),
					...element,
				});
			} else {
				const index = series.findIndex((i) => i.name === element.name);
				const newDataNumber = dataRestructured?.[element]?.data;
				if (newDataNumber) {
					series[index].data.push(newDataNumber);
				}
			}
		});
		if (series.length > 0) {
			series.forEach((_, index) => {
				if (index % 2 === 0) {
					series[index].type = 'column'
				} else {
					series[index].type = 'line'
				}
			})
		}
		return series;
	}, [dataRestructured]);

	function cardTitle() {
		return (
			<div>
				{slicerLength > 0 ? (
					<>
						<DabbleDropdown
							items={slicerSelections}
							onTap={(filter) => setSlicerFilter(filter)}
							selectedItemId={slicerFilter?.value}
							toggleClassName="default-select style-3 btn btn-sm filter-option text-body-2xs regular"
							containerClassName="dropdown bootstrap-select form-control style-3 default-select d-inline-flex"
							key={Math.random()}
							buttonStyle="style1"
						/>
						<div className="d-flex justify-content-between w-100 mt-4">
							<div className="text-body-2lg">
								<div>Nett Price and GTV Performance</div>
								<div>
									in {selectedDicersLength} {activeFilter?.dicer?.label} of {slicerFilter?.label}
								</div>
								<div>in {meta?.year}</div>
							</div>
							{timespanPeriods && (
								<TimespanChips
									setActiveTimespanFilter={setActiveTimespanFilter}
									activeTimespanFilter={activeTimespanFilter}
									timespanPeriods={timespanPeriods}
								/>
							)}
						</div>
					</>
				) : (
					<p className="text-body-2lg p-0 m-0 text-muted">No slicer selection selected</p>
				)}
			</div>
		);
	}

	const renderToolTip = useCallback(
		({ series, seriesIndex, dataPointIndex }) => {
			const dataSeries = data?.[dataPointIndex]?.childrens?.[seriesIndex];
			const tooltipColors = ["#FB7C73", "#00F0FF", "#F82416", "#4CAF50", "#0A58CA", "#320704"];
			const dataObj = {
				["Avg Price / Unit"]: dataSeries?.average,
				Max: dataSeries?.max,
				Mean: dataSeries?.mean,
				Median: dataSeries?.median,
				Min: dataSeries?.min,
				Mode: dataSeries?.mode,
			};

			return renderToString(
				<div
					className="rounded px-4 py-2"
					style={{ zIndex: "9999 !important" }}>
					<div style={{ color: colors[seriesIndex], fontWeight: 700, zIndex: 9999 }}>
						{dataSeries?.title}
					</div>
					{Object.keys(dataObj)?.map((key, index) => (
						<div
							key={index}
							className="my-1 d-flex justify-content-between align-items-center">
							<div className="border rounded border-light-text-primary py-1 px-2 d-flex align-items-center">
								<div
									style={{ width: "14px", height: "14px", background: tooltipColors[index] }}
									className="rounded-circle mr-2"
								/>
								<div>{key}</div>
								<div
									style={{
										width: "14px",
										height: "14px",
										fontSize: "9px",
										fontWeight: "600",
										border: "2px solid #4B5565",
									}}
									className="rounded-circle ml-2 d-flex align-items-center justify-content-center">
									i
								</div>
							</div>
							<div className="ml-4">Rp. {dataObj[key]}</div>
						</div>
					))}
				</div>
			);
		},
		[data, colors]
	);

	const maxYAxisValue = useMemo(() => {
		const data = dataSeries.map((i) => i.data);
		const combinedArray = data.reduce((acc, arr) => acc.concat(arr), []);
		const maxValue = Math.max(...combinedArray);

		return maxValue;
	}, [dataSeries]);

	const options = useMemo(() => {
		if (!data) return
		return {
			series: dataSeries,
			chart: {
				height: 350,
				type: "line",
				stacked: false,
				toolbar: {
					show: false,
				},
				zoom: {
					enabled: false,
				},
			},
			stroke: {
				width: [0, 3],
				curve: "smooth",
			},
			dataLabels: {
				formatter: (val) => {
					return val / 1000 + "K";
				},
				enabled: false,
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: "50px",
					borderRadius: 4,
					borderRadiusApplication: "around",
					borderRadiusWhenStacked: "all",
				},
			},
			xaxis: {
				categories,
				tooltip: {
					enabled: false,
				},
				labels: {
					formatter: function (val) {
						if (val) {
							const text = val.split(" ");
							const modifiedText = [text?.[0].slice(0, 3), text?.[1]];
							return modifiedText;
						}
					},
				},
			},
			fill: {
				opacity: 1,
			},
			colors,
			yaxis: [
				{
					max: maxYAxisValue,
					title: {
						text: "Price",
						style: {
							color: colors[0],
						},
					},
					labels: {
						show: true,
						formatter: (val) => {
							if (val) {
								return `Rp. \n${val.toFixed()}`;
							}
						},
						style: {
							colors: colors[0],
						},
					},
				},
				{
					max: maxYAxisValue,
					opposite: true,
					title: {
						rotate: 90,
						text: "GTV",
						style: {
							color: colors[1],
						},
					},
					labels: {
						show: true,
						formatter: (val) => {
							if (val) {
								return `Rp. \n${val.toFixed()}`;
							}
						},
						style: {
							colors: colors[1],
						},
					},
				},
			],
			legend: {
				position: "top",
				horizontalAlign: "left",
			},
			tooltip: {
				enabled: true,
				shared: true,
				intersect: false,
				custom: renderToolTip,
			},
		};
	}, [categories, dataSeries, maxYAxisValue, renderToolTip, colors, data]);

	return (
		<div
			id="group-stacked-bar-chart"
			className="col-xl-12 col-md-12">
			{cardTitle()}
			{data?.length > 0 ? (
				<Chart
					options={options}
					series={options.series}
					width="100%"
					height={400}
				/>
			) : (
				<EmptyCardPlaceholder />
			)}
		</div>
	);
};

export default PricingBarLineChart;

