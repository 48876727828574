import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getCookie from "../utils/CookieUtil";
import serializeParams from "utils/SerializeParams";

export const afinityPerformanceApi = createApi({
	reducerPath: "afinityPerformanceApi",
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_DABBLE_API_HOST_V2,
		prepareHeaders: (headers) => {
      const token = getCookie("dabble_token_v2");

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

			return headers;
		},
	}),
	endpoints: (builder) => ({
		getYtdTransaction: builder.query({
			query: ({ body }) => ({
				url: `/v2/analytic/affinity/performance/retrieve/transaction`,
				method: "post",
				body,
			}),
		}),

		getYtdGTV: builder.query({
			query: ({ body }) => ({
				url: `/v2/analytic/affinity/performance/retrieve/gtv`,
				method: "post",
				body,
			}),
		}),
		getYtdQuantity: builder.query({
			query: ({ body }) => ({
				url: `/v2/analytic/affinity/performance/retrieve/quantity`,
				method: "post",
				body,
			}),
		}),
		getChart: builder.query({
			query: ({ body, path }) => 
				`/v2/analytic/affinity/performance/chart${path}?${serializeParams(body)}`,
		}),
		getComparison: builder.query({
			query: ({ body, path }) =>
			  `/v2/analytic/affinity/performance/comparison${path}?${serializeParams(body)}`,
		}),
	}),
});

export const {
	useGetYtdTransactionQuery,
	useGetYtdGTVQuery,
	useGetYtdQuantityQuery,
	useGetChartQuery,
	useGetComparisonQuery,
} = afinityPerformanceApi;
