import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { formatCurrency } from "utils/CurrencyUtil";
import { getDabbleColor } from "utils/UIUtil";
import { dabbleDecimal } from "utils/NumericUtil";
import { useGetComparisonQuery } from "services/AfinityPerformanceService";
import { useMemo } from "react";
import { serialAmount } from "utils/DataUtil";
import EmptyCardPlaceholder from "components/Composable/EmptyCardPlaceholder";
import ComparisonTooltipTile from "components/Composable/ComparisonTooltipTile";
import CardTitle from "components/Composable/CardTitle";
import CardNumber from "components/Composable/CardNumber";
import ComparisonProgressBar from "components/Dashboard/afinity_performance/composable/ComparisonProgressBar";

function SlicerComparisonCard() {
	const { activeFilter } = useSelector((state) => state.menu);
	const slicersLength = activeFilter?.slicerSelections?.length ?? 0;
	const slicerLabel = activeFilter?.slicer?.label;
	const periodId = activeFilter?.period?.id;
	const body = {
		year: activeFilter?.period?.id,
		metric: activeFilter?.metric?.id,
		slicer_id: activeFilter?.slicer?.value,
		slicers: activeFilter?.slicerSelections?.map((slicerSelection) => slicerSelection.value),
		interval: activeFilter?.interval?.id,
	};
	if (activeFilter?.dicerSelections != null) {
		body.dicer_id = activeFilter?.dicer?.value;
		body.dicers = activeFilter?.dicerSelections?.map((dicerSelection) => dicerSelection.value);
	}

	// Uncomment when datasource ready
	const { data } = useGetComparisonQuery(
		{
			body,
			path: "/1",
		},
		{ skip: !body.slicer_id }
	);

	const combinedGTV = useMemo(
		() => data?.data?.reduce((accumulator, data) => accumulator + data?.amount, 0),
		[data?.data]
	);

	function cardCaption() {
		const slicerTitles = data?.data?.map((slicer) => slicer.title).join(" and ");
		const formattedTotalAmount = formatCurrency(combinedGTV);
		return (
			<p className="mt-3 mb-0 text-center">
				{`Combined ${slicerTitles}: ${formattedTotalAmount} in ${data.meta.year}`}
			</p>
		);
	}

	function getDicerLabels() {
		const selections = activeFilter?.dicerSelections ?? activeFilter?.slicerSelections;
		return selections?.map((selection) => selection?.label ?? selection?.name);
	}

	function progressBarCaption() {
		const combinedGTV = data?.data?.reduce(
			(accumulator, data) => accumulator + data?.amount + data?.discountedAmount,
			0
		);
		return (
			<div className="row">
				{data.data.map((slicer, index) => {
					const color = getDabbleColor(index, 0);
					return (
						<div
							className="col"
							key={index}>
							<p
								className="m-0"
								style={{ color }}>
								{slicer.title} : {dabbleDecimal(slicer.percentage)}%
							</p>
							<p
								className="mb-2"
								style={{ color }}>
								of {data.meta.year}
							</p>
						</div>
					);
				})}
			</div>
		);
	}

	return (
		<div className="col-xl-12 col-md-12">
			<div className="card">
				<CardNumber number="4" />
				<CardTitle
					slicerTitle={slicerLabel}
					slicerLength={slicersLength}
					dicerLabels={getDicerLabels()}
					period={periodId}
				/>
				{data ? (
					<div className="card-body">
						{progressBarCaption()}
						<div className="dabble-tooltip-container w-100">
							<div
								className="dabble-tooltip rounded-sm shadow mt-5 py-2 px-4 text-body regular"
								style={{ zIndex: "1080" }}>
								<p className="p-0 pb-0 mb-1">
									{`Combined ${slicersLength} ${activeFilter?.slicer?.label} : ${serialAmount(
										combinedGTV ?? 0,
										activeFilter?.metric?.id
									)} in ${activeFilter?.period?.id}`}
								</p>
								{data.data?.map((slicer, slicerIndex) => {
									return (
										<ComparisonTooltipTile
											title={slicer?.title}
											value={serialAmount(slicer.amount, activeFilter?.metric?.id)}
											percentage={slicer?.percentage}
											growth={dabbleDecimal(slicer?.growth)}
											color={getDabbleColor(slicerIndex, 0)}
											key={Math.random()}
										/>
									);
								})}
							</div>
							<ComparisonProgressBar data={data} />
						</div>
						{cardCaption()}
					</div>
				) : (
					<EmptyCardPlaceholder key={Math.random()} />
				)}
			</div>
		</div>
	);
}

export default SlicerComparisonCard;
