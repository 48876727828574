import React, { useMemo } from "react";
import { Table } from "react-bootstrap";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { thousandFormat } from "utils/NumericUtil";
import ReactApexChart from "react-apexcharts";
import { getMetricLabel, serialAmount } from "utils/DataUtil";
import { useGetTableQuery } from "services/PricingService";
import { useSelector } from "react-redux";
import { shortCurrency } from "utils/CurrencyUtil";

const PricingTable2 = () => {
  const menu = useSelector((state) => state.menu);
  const activeFilter = menu?.activeFilter;

  const body = useMemo(() => {
    const filter = {
      year: activeFilter?.period?.id,
      metric: activeFilter?.metric?.id,
      slicer_id: activeFilter?.slicer?.value,
      slicers: activeFilter?.slicerSelections?.map(
        (slicerSelection, _) => slicerSelection.value
      ),
      interval: activeFilter?.interval?.id,
    };
    if (activeFilter?.dicerSelections != null) {
      filter.dicer_id = activeFilter?.dicer?.value;
      filter.dicers = activeFilter?.dicerSelections?.map(
        (dicerSelection, _) => dicerSelection.value
      );
    }
    return filter;
  }, [activeFilter]);

  const { data: dataTable } = useGetTableQuery(
    { path: "/second", body },
    { skip: !body.slicer_id }
  );

  const data = dataTable?.data;
  const meta = dataTable?.meta;

  const transactionGrowthColor =
    data?.summary?.transactionGrowth > 0 ? "#21B830" : "#FF2626";
  const transactionArrowPoint =
    data?.summary?.transactionGrowth > 0 ? "4,0 0,8 8,8" : "4,8 0,0 8,0";

  const gtvGrowthColor =
    data?.summary?.grossTransactionValueGrowth > 0 ? "#21B830" : "#FF2626";
  const gtvArrowPoint =
    data?.summary?.grossTransactionValueGrowth > 0
      ? "4,0 0,8 8,8"
      : "4,8 0,0 8,0";

  const quantityGrowthColor =
    data?.summary?.quantityGrowth > 0 ? "#21B830" : "#FF2626";
  const quantityArrowPoint =
    data?.summary?.quantityGrowth > 0 ? "4,0 0,8 8,8" : "4,8 0,0 8,0";

  const chartOptions = {
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
      opacity: 1,
    },
    grid: {
      show: false,
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      show: false,
    },
  };

  const columns = [
    createColumnHelper().accessor("title", {
      header: () => <div className="text-center">Ranking</div>,
      cell: (info) => (
        <div className="text-right" style={{ color: "#4E5969" }}>
          {info.getValue().replace("Rank ", "")}
        </div>
      ),
      size: 50,
    }),
    createColumnHelper().accessor("price", {
      header: () => <div className="text-center">Price</div>,
      cell: (info) => {
        const colors = ["#FF4560", "#3D8BFD"];
        return (
          <>
            {info.row.original.childrens?.map((data, index) => (
              <div
                key={index}
                style={{ fontWeight: 500, whiteSpace: "nowrap" }}
              >
                <div style={{ color: colors[index] }}>
                  <span style={{ fontSize: "12px" }}>{data.title}</span>&nbsp;
                  <span style={{ fontSize: "10px" }}>Rp.</span>&nbsp;
                  <span style={{ fontSize: "14px" }}>
                    {thousandFormat(data.price)}
                  </span>
                </div>
              </div>
            ))}
          </>
        );
      },
      size: 100,
    }),
    createColumnHelper().accessor("priceHistory", {
      header: () => <div className="ml-4">Price History</div>,
      cell: (info) => {
        const data = info.row.original.childrens.map(
          (data) => data.priceHistories
        );
        const lastIndex1 = data?.[0].length - 1;
        const lastYCoordinate1 = data?.[0][lastIndex1];
        const lastIndex2 = data?.[1].length - 1;
        const lastYCoordinate2 = data?.[1][lastIndex2];
        const newAnnotations = [
          {
            x: 117,
            y: lastYCoordinate1,
            marker: {
              size: 5,
              fillColor: "#FF4560",
              strokeColor: "#FFF",
            },
          },
          {
            x: 117,
            y: lastYCoordinate2,
            marker: {
              size: 5,
              fillColor: "#3D8BFD",
              strokeColor: "#FFF",
            },
          },
        ];

        const series = data?.map((i) => {
          return {
            data: i,
            type: "line",
          };
        });

        const modifiedChartOptions = {
          series,
          options: {
            ...chartOptions,
            annotations: {
              points: newAnnotations,
            },
            colors: ["#F82416", "#3D8BFD"],
          },
        };

        return (
          <div id="chart" style={{ marginBottom: "-15px" }}>
            <ReactApexChart
              height={100}
              width={150}
              options={modifiedChartOptions.options}
              series={modifiedChartOptions.series}
              type="area"
            />
          </div>
        );
      },
    }),
    createColumnHelper().accessor("island", {
      header: () => <div className="text-center">Island</div>,
      cell: (info) => (
        <div className="d-flex flex-column flex-grow-1">
          {info.row.original.childrens.map((data, index) => (
            <div
              key={index}
              style={{
                width: "100%",
                minWidth: "max-content",
                fontSize: "10px",
                padding: "4px 36px",
                backgroundColor: "#ECEEEB",
                borderRadius: "50px",
                textAlign: "center",
                marginBottom: "4px",
              }}
            >
              {data.meta.island}
            </div>
          ))}
        </div>
      ),
      size: 100,
    }),
    createColumnHelper().accessor("province", {
      header: () => <div className="text-center">Province</div>,
      cell: (info) => (
        <>
          {info.row.original.childrens.map((data, index) => (
            <div
              key={index}
              style={{
                width: "100%",
                minWidth: "max-content",
                fontSize: "10px",
                padding: "4px 36px",
                backgroundColor: "#ECEEEB",
                borderRadius: "50px",
                textAlign: "center",
                marginBottom: "4px",
              }}
            >
              {data.meta.province}
            </div>
          ))}
        </>
      ),
      size: 100,
    }),
    createColumnHelper().accessor("city", {
      header: () => <div className="text-center">City / Regency</div>,
      cell: (info) => (
        <div className="d-flex flex-column">
          {info.row.original.childrens.map((data, index) => (
            <div
              key={index}
              style={{
                width: "100%",
                minWidth: "max-content",
                fontSize: "10px",
                padding: "4px 36px",
                backgroundColor: "#ECEEEB",
                borderRadius: "50px",
                textAlign: "center",
                marginBottom: "4px",
              }}
            >
              {data.meta.city}
            </div>
          ))}
        </div>
      ),
      size: 170,
    }),
    createColumnHelper().accessor("outlet", {
      header: () => <div className="text-center">Outlet Name</div>,
      cell: (info) => (
        <>
          {info.row.original.childrens.map((data, index) => (
            <div
              key={index}
              style={{
                width: "100%",
                minWidth: "max-content",
                fontSize: "10px",
                padding: "4px 36px",
                backgroundColor: "#ECEEEB",
                borderRadius: "50px",
                textAlign: "center",
                marginBottom: "4px",
              }}
            >
              {data.meta.outlet}
            </div>
          ))}
        </>
      ),
      size: 170,
    }),
    createColumnHelper().accessor("metric", {
      header: () => (
        <div className="text-center">
          Cumulative Metric: {getMetricLabel(meta.metric)}
        </div>
      ),
      cell: (info) => (
        <>
          {info.row.original.childrens.map((element, index) => (
            <div
              key={index}
              style={{
                fontWeight: 500,
                width: "100%",
                minWidth: "max-content",
              }}
            >
              {serialAmount(element?.metric, meta.metric)}
            </div>
          ))}
        </>
      ),
      size: 125,
    }),
    createColumnHelper().accessor("marketshare", {
      header: () => <div className="text-center">Market Share vs Total</div>,
      cell: (info) => (
        <>
          {info.row.original.childrens.map((data, index) => (
            <div key={index} className="text-center">
              {data.percentage} %
            </div>
          ))}
        </>
      ),
      size: 150,
    }),
  ];

  const table = useReactTable({
    data: data?.ranks,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 100,
    },
  });

  return (
    <div id="pricing-table-line-chart2" className="col-xl-12 col-md-12">
      {data ? (
        <>
          <div className="container">
            <div className="row">
              <div className="col-sm">
                <div className="card p-3 shadow-sm">
                  <div style={{ color: "#4E5969" }}>Transaction</div>
                  <div className="h2">
                    {thousandFormat(data?.summary?.transaction)}
                  </div>
                  <div className="d-flex align-items-center">
                    <svg width="8" height="8" className="p-0 m-0 mr-1">
                      <polygon
                        points={transactionArrowPoint}
                        fill={transactionGrowthColor}
                      />
                    </svg>
                    <span style={{ color: transactionGrowthColor }}>
                      {data?.summary?.transactionGrowth}%&nbsp;
                    </span>
                    <span style={{ color: "#86909C" }}>vs 12 months ago</span>
                  </div>
                </div>
              </div>
              <div className="col-sm">
                <div className="card p-3 shadow-sm">
                  <div style={{ color: "#4E5969" }}>GTV</div>
                  <div className="h2">
                    <span className="h6">Rp.&nbsp;</span>
                    {shortCurrency({
                      num: data?.summary?.grossTransactionValue,
                    })}
                  </div>
                  <div className="d-flex align-items-center">
                    <svg width="8" height="8" className="p-0 m-0 mr-1">
                      <polygon points={gtvArrowPoint} fill={gtvGrowthColor} />
                    </svg>
                    <span style={{ color: gtvGrowthColor }}>
                      {data?.summary?.grossTransactionValueGrowth}%&nbsp;
                    </span>
                    <span style={{ color: "#86909C" }}>vs 12 months ago</span>
                  </div>
                </div>
              </div>
              <div className="col-sm">
                <div className="card p-3 shadow-sm">
                  <div style={{ color: "#4E5969" }}>Quantity</div>
                  <div className="h2">
                    {thousandFormat(data?.summary?.quantity)}
                  </div>
                  <div className="d-flex align-items-center">
                    <svg width="8" height="8" className="p-0 m-0 mr-1">
                      <polygon
                        points={quantityArrowPoint}
                        fill={quantityGrowthColor}
                      />
                    </svg>
                    <span style={{ color: quantityGrowthColor }}>
                      {data?.summary?.quantityGrowth}%&nbsp;
                    </span>
                    <span style={{ color: "#86909C" }}>vs 12 months ago</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-50 my-2" style={{ fontSize: "20px" }}>
            <div>Top performing Nett Price of SKU 1</div>
            <div>based on GTV grouped by Pareto Stores in {meta?.year}</div>
          </div>
          <Table hover responsive size="sm">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      {...{
                        key: header.id,
                        colSpan: header.colSpan,
                      }}
                      style={{
                        minWidth: `${header.getSize()}px`,
                        whiteSpace: "normal",
                        padding: "12px",
                        color: "#00C0CC",
                        fontWeight: 400,
                      }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id} style={{ padding: "12px" }}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      ) : null}
    </div>
  );
};

export default PricingTable2;
