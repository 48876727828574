import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getCookie from "../utils/CookieUtil";
import serializeParams from "utils/SerializeParams";

export const pricingApiV2 = createApi({
  reducerPath: "pricingApiV2",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_DABBLE_API_HOST_V2,
    prepareHeaders: (headers) => {
      const token = getCookie("dabble_token_v2");

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getChart: builder.query({
      query: ({ body, path }) =>
        `/v2/analytic/pricing/chart${path}?${serializeParams(body)}`,
    }),

    getComparison: builder.query({
      query: ({ body, path }) =>
        `/v2/analytic/pricing/comparison${path}?${serializeParams(body)}`,
    }),
  }),
});

export const { useGetChartQuery, useGetComparisonQuery } = pricingApiV2;
