export function getTimespanFilterByPeriod(interval) {
	switch (interval) {
		case "WEEKLY":
			return getWeekTimespanFilter();
		case "MONTHLY":
			return getMonthTimespanFilter();
		case "QUARTERLY":
			return getQuarterTimespanFilter();
		case "SEMESTERLY":
			return getSemesterTimespanFilter();
		case "YEARLY":
			return getYearlyTimespanFilter();
		default:
			break;
	}
}

export const getYearlyTimespanFilter = () => {
	return [
		{
			value: 1,
			label: "Last 1",
		},
		{
			value: 2,
			label: "Last 2",
		},
		{
			value: 3,
			label: "Last 3",
		},
		{
			value: 4,
			label: "Last 4",
		},
	];
};

export const getQuarterTimespanFilter = () => {
	return [
		{
			value: 1,
			label: "Last 1",
		},
		{
			value: 2,
			label: "Last 2",
		},
		{
			value: 3,
			label: "Last 3",
		},
		{
			value: 4,
			label: "Last 4",
		},
	];
};

export const getSemesterTimespanFilter = () => {
	return [
		{
			value: 1,
			label: "Last 1",
		},
		{
			value: 2,
			label: "Last 2",
		},
	];
};

export const getMonthTimespanFilter = () => {
	return [
		{
			value: 1,
			label: "Last 1",
		},
		{
			value: 3,
			label: "Last 3",
		},
		{
			value: 6,
			label: "Last 6",
		},
		{
			value: 9,
			label: "Last 9",
		},
		{
			value: 12,
			label: "Last 12",
		},
	];
};

export const getWeekTimespanFilter = () => {
	return [
		{
			value: 1,
			label: "Last 1",
		},
		{
			value: 6,
			label: "Last 6",
		},
		{
			value: 12,
			label: "Last 12",
		},
		{
			value: 18,
			label: "Last 18",
		},
		{
			value: 24,
			label: "Last 24",
		},
	];
};

export function getPeriodicFilter(interval, year) {
	switch (interval) {
		case "WEEKLY":
			return getWeeklyFilter(year);
		case 'MONTHLY':
		    return getMonthlyFilter(year)
		case 'QUARTERLY':
		    return getQuarterlyFilter(year)
		case 'SEMESTERLY':
		    return getSemesterlyFilter(year)
		case 'YEARLY':
		    return getYearlyFilter(year)
		default:
			break;
	}
}

export function getWeeklyFilter(year) {
	// get first day of the $year, month index [0] = january, date = 1;
	// equivalent to 1 January $year
	const firstDayOfYear = new Date(year, 0, 1);
	// get first day of the $year, month index [11] = December, date = 31;
	// equivalent to 31 December $year
	const lastDayOfYear = new Date(year, 11, 31);

	// Calculate the number of days between the first day and last day of the year.
	// The result from lastDayOfYear - firstDayOfYear is in millisecond unit, so it's need to be converted to day which
	// equivalent to 1000 millisecond * 60 second * 60 minues * 24 hours = 86400000 millisecond (1 day)
	const daysInYear = Math.round(
		(lastDayOfYear - firstDayOfYear) / (1000 * 60 * 60 * 24)
	);

	// Calculate the number of weeks and add 1 if there is a partial week at the end of the year
	// Get number of weeks by calculating dayInYear / 7,
	const numberOfWeeks =
		Math.floor(daysInYear / 7) +
		(firstDayOfYear.getDay() === 4 || lastDayOfYear.getDay() === 4 ? 1 : 0);
	const weeks = [];
	for (let i = 1; i <= numberOfWeeks; i++) {
		const week = {
			value: i,
			label: `Week ${i} ${year}`,
		};
		weeks.push(week);
	}
	return weeks;
}

export function getMonthlyFilter(year) {
	const months = [];
	for (let month = 0; month < 12; month++) {
		const label = new Date(year, month).toLocaleString("en-US", {
			month: "long",
		});
		const monthObject = {
			value: month + 1,
			label: `${label} ${year}`,
		};
		months.push(monthObject);
	}
	return months;
}

export function getQuarterlyFilter(year) {
	const quarters = [];
	for (let quarter = 1; quarter <= 4; quarter++) {
		const quarterObject = {
			value: quarter,
			label: `Q${quarter} ${year}`,
		};
		quarters.push(quarterObject);
	}
	return quarters;
}

export function getSemesterlyFilter(year) {
	const semesters = [];
	for (let semester = 1; semester <= 2; semester++) {
		const semesterObject = {
			value: semester,
			label: `Semester ${semester} ${year}`,
		};
		semesters.push(semesterObject);
	}
	return semesters;
}

export function getYearlyFilter() {
	const currentYear = new Date().getFullYear();
	const years = [];
	for (let i = 0; i < 5; i++) {
		const year = currentYear - i;
		const yearObject = {
			value: year,
			label: `${year}`,
		};
		years.push(yearObject);
	}
	return years;
}
