import React, { useEffect, useState } from "react";

import YtdGrossTransactionCard from "components/Dashboard/card/YtdGrossTransactionCard";
import YtdQuantityCard from "components/Dashboard/card/YtdQuantityCard";
import YtdTransactionCard from "components/Dashboard/card/YtdTransactionCard";
import SlicerComparisonCard1 from "components/Dashboard/afinity_performance/card/SlicerComparisonCard";
import SlicerComparisonCard2 from "components/Dashboard/afinity_performance/card/SlicerComparisonCard2";
import SlicerComparisonCard3 from "components/Dashboard/afinity_performance/card/SlicerComparisonCard3";
import DicerGraphicCard from "components/Dashboard/afinity_performance/card/DicerGraphicCard";
import DicerGraphicCard2 from "components/Dashboard/afinity_performance/card/DicerGraphicCard2";
import DicerGraphicCard3 from "components/Dashboard/afinity_performance/card/DicerGraphicCard3";
import { useGetChartQuery } from "services/AfinityPerformanceService";
import { useSelector } from "react-redux";
import { getPeriodicFilter, getTimespanFilterByPeriod } from "utils/FilterUtil";

function AfinityPerformance(props) {
	// Global usage variable
	const menu = useSelector((state) => state.menu);
	const timespans = getTimespanFilterByPeriod(menu?.activeFilter?.interval?.id);
	const defaultIndex = timespans?.length - 1;
	const defaultTimespan = timespans != null ? timespans[defaultIndex] : null;
	const firstSlicer =
		menu?.activeFilter?.slicerSelections != null
			? menu?.activeFilter?.slicerSelections[0].value
			: null;
	const firstDicer =
		menu?.activeFilter?.dicerSelections != null ? menu?.activeFilter?.dicerSelections[0].value : null;
	const intervalFilters = getPeriodicFilter(
		menu?.activeFilter?.interval?.id,
		menu?.activeFilter?.period?.id
	);
	const firstIntervalFilter = intervalFilters!=null ? intervalFilters[0]?.value : null;

	// Chart 1 request
	const [chartRequest1, setChartRequest1] = useState(null);
	const [chartRequest2, setChartRequest2] = useState(null);
	const [chartRequest3, setChartRequest3] = useState(null);

	useEffect(() => {
		if (firstSlicer != null) {
			setChartRequest1({
				year: menu?.activeFilter?.period?.id,
				interval: menu?.activeFilter?.interval?.id,
				timespan: defaultTimespan?.value,
				metric: menu?.activeFilter?.metric?.id,
				slicerId: menu?.activeFilter?.slicer?.value,
				slicerFilter: firstSlicer ?? null,
				dicerId: menu?.activeFilter?.dicer?.value,
				dicerSelections: menu?.activeFilter?.dicerSelections?.map(
					(dicerSelection) => dicerSelection.value
				),
			});
		}
		if (firstDicer != null) {
			setChartRequest2({
				year: menu?.activeFilter?.period?.id,
				interval: menu?.activeFilter?.interval?.id,
				timespan: defaultTimespan?.value,
				metric: menu?.activeFilter?.metric?.id,
				slicerId: menu?.activeFilter?.slicer?.value,
				slicerSelections: menu?.activeFilter?.slicerSelections?.map(
					(slicerSelections) => slicerSelections.value
				),
				dicerId: menu?.activeFilter?.dicer?.value,
				dicerFilter: firstDicer ?? null,
			});
		}
		if (firstIntervalFilter != null) {
			setChartRequest3({
				year: menu?.activeFilter?.period?.id,
				interval: menu?.activeFilter?.interval?.id,
				timespan: defaultTimespan?.value,
				metric: menu?.activeFilter?.metric?.id,
				slicerId: menu?.activeFilter?.slicer?.value,
				slicerSelections: menu?.activeFilter?.slicerSelections?.map(
					(slicerSelections) => slicerSelections.value
				),
				dicerId: menu?.activeFilter?.dicer?.value,
				dicerSelections: menu?.activeFilter?.dicerSelections?.map(
					(dicerSelection) => dicerSelection.value
				),
				intervalFilter: firstIntervalFilter,
			});
		}
	}, [menu?.activeFilter]);

	let chart1 = useGetChartQuery(
		{
			body: chartRequest1,
			path: "/1",
		},
		{
			skip: !chartRequest1 || !firstSlicer,
		}
	);

	let chart2 = useGetChartQuery(
		{
			body: chartRequest2,
			path: "/2",
		},
		{
			skip: !chartRequest2 || !firstDicer,
		}
	);

	let chart3 = useGetChartQuery(
		{
			body: chartRequest3,
			path: "/3",
		},
		{
			skip: !chartRequest3 || !firstIntervalFilter,
		}
	);

	return (
		<div>
			<div className="col">
				<div className="row">
					<h4 className="bold m-0">Discount Analytic</h4>
				</div>
				<div className="row">
					<p className="text-body regular">{`in the period of ${menu?.filter?.period.title}`}</p>
				</div>
			</div>
			<div className="row">
				<YtdTransactionCard activeFilter={menu?.activeFilter} />
				<YtdGrossTransactionCard activeFilter={menu?.activeFilter} />
				<YtdQuantityCard activeFilter={menu?.activeFilter} />
			</div>
			<div className="row">
				<SlicerComparisonCard1 activeFilter={menu?.activeFilter} />
				{/*  */}

				{menu?.activeFilter?.dicerSelections != null ? (
					<DicerGraphicCard
						data={chart1.data}
						onMainFilterChange={(value) => {
							console.log(value);
							setChartRequest1({
								...chartRequest1,
								slicerFilter: value.value,
							});
							chart1.refetch();
						}}
						onSecondaryFilterChange={(value) => {
							console.log(value);
							setChartRequest1({
								...chartRequest1,
								timespan: value.value,
							});
							chart2.refetch();
						}}
						activeFilter={menu?.activeFilter}
						filters={menu?.activeFilter?.slicerSelections}
						number="5"
					/>
				) : null}
				{/*  */}
				<SlicerComparisonCard2 activeFilter={menu?.activeFilter} />
				{/*  */}
				<DicerGraphicCard2
					data={chart2.data}
					onMainFilterChange={(value) => {
						setChartRequest2({
							...chartRequest2,
							dicerFilter: value.value,
						});
						chart2.refetch();
					}}
					onSecondaryFilterChange={(value) => {
						console.log(value);
						setChartRequest2({
							...chartRequest2,
							timespan: value.value,
						});
						chart2.refetch();
					}}
					activeFilter={menu?.activeFilter}
					filters={menu?.activeFilter?.dicerSelections}
					number="7"
				/>
				{/*  */}
				<SlicerComparisonCard3 activeFilter={menu?.activeFilter} />
				{/*  */}
				<DicerGraphicCard3
					data={chart3.data}
					onMainFilterChange={(value) => {
						setChartRequest3({
							...chartRequest3,
							intervalFilter: value.value,
						});
						chart3.refetch();
					}}
					activeFilter={menu?.activeFilter}
					filters={getPeriodicFilter(menu?.activeFilter?.interval?.id, menu?.activeFilter?.period?.id)}
					number="9"
				/>
			</div>
		</div>
	);
}

export default AfinityPerformance;
