import axios from "axios";
import getCookie from "../utils/CookieUtil";

const headers = {
	"Content-Type": "application/json",
	Accept: "application/json",
};
const axiosInstance = axios.create({
	headers: headers,
});

axiosInstance.interceptors.request.use((config) => {
	const token = getCookie("dabble_token");
	if (token != null) {
		config.headers["Authorization"] = `Bearer ${token}`;
	}
	return config;
});

export default axiosInstance;
