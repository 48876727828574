import { Dropdown } from "react-bootstrap";
import React from "react";

function DabbleDropdown(props) {
	const items = props?.items;
	const containerClassName = props.containerClassName;
	const toggleClassName = props.toggleClassName;
	const selectedItemId = props.selectedItemId;
	const style = props.buttonStyle;
	const activeItem =
		items != null ? items?.find((item) => item.value === selectedItemId) ?? items[0] : null;

	return items != null ? (
		<div
			className={
				containerClassName != null
					? containerClassName
					: "dropdown bootstrap-select form-control style-3 default-select d-inline-flex"
			}>
			{items != null ? (
				<Dropdown>
					<Dropdown.Toggle
						variant=""
						id="dropdown-basic"
						className={
							toggleClassName != null
								? toggleClassName
								: `default-select style-3 btn btn-xs filter-option py-1 text-body-2xs regular`
						}
						style={{
							fontSize: "12px",
							fontWeight: "400",
							borderColor: style === "style1" ? "#00F0FF" : "#697586",
							backgroundColor: "#E6FEFF",
							color: style === "style1" ? "#0A58CA" : null,
						}}>
						{activeItem?.label}
					</Dropdown.Toggle>

					<Dropdown.Menu
						className="dropdown-menu inner show shadow-sm rounded-lg py-2"
						style={{
							"overflowY": "auto",
							"maxHeight": "250px",
						}}>
						{items.map((item, index) => (
							<Dropdown.Item
								className="dropdown-item text-body-2xs regular py-2"
								href="#"
								onClick={() => props.onTap(item, index)}
								key={Math.random()}>
								{item.label}
							</Dropdown.Item>
						))}
					</Dropdown.Menu>
				</Dropdown>
			) : null}
		</div>
	) : null;
}

export default DabbleDropdown;
