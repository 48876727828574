import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { renderToString } from "react-dom/server";
import { compactDecimal } from "utils/NumericUtil";
import { serialAmount } from "utils/DataUtil";
import { getDabbleColor } from "utils/UIUtil";

function PricingPieChart(props) {
	const data = props.data;
	const meta = props.meta;
	const filter = props.filter;
	const metric = filter?.metric;
	// Data section
	const childrens = data?.childrens;
	const childrenAmounts =
		childrens != null ? childrens?.map((children, index) => children.amount) : null;
	const totalAmounts = childrens != null ? childrenAmounts.reduce((a, b) => a + b) : null;
	const series =
		(childrens != null)
			? childrens?.map((children, index) => (children.amount / totalAmounts) * 100)
			: null;

	// Meta section
	const colors = meta?.slicerSelections?.map((dicer, index) => getDabbleColor(index, 0));

	const state = {
		series: series,
		options: {
			chart: {
				type: "donut",
				animations: {
					enabled: false,
				},
			},
			tooltip: {
				enabled: true,
				theme: false,
				fillSeriesColor: false,
				custom: ({ series, seriesIndex, dataPointIndex }) => {
					const currentData = childrens[seriesIndex];
					const isPositive = currentData.growth > 0;
					const color = isPositive ? "#21B830" : "#FF2626";
					const arrow = isPositive ? "M0 11L11 -4.72849e-07L22 11" : "M0 -9.61651e-07L11 11L22 0";
					let tooltip = (
						<div
							className="py-3 px-4"
							style={{ background: "#fff" }}>
							<div className="text-body-xs">
								<div className="row">
									<p className="m-0">
										{currentData !== null ? currentData.name : null} :{" "}
										{currentData !== null ? compactDecimal(currentData.percentage) : "0"}%
									</p>
								</div>
								<div className="row align-items-center">
									<p className="p-0 m-0">
										{currentData !== null ? serialAmount(currentData.amount, metric?.id) : "0"}
									</p>
									<p className="p-0 m-0 ml-1">(</p>
									{currentData.growth !== 0 && (
										<svg
											className="mr-1"
											width="12"
											height="8"
											viewBox="0 0 16 18">
											<path
												d={arrow}
												fill={color}
											/>
										</svg>
									)}
									<p className="p-0 m-0">{currentData.growth}%</p>
									<p className="p-0 m-0">)</p>
								</div>
							</div>
						</div>
					);
					return renderToString(tooltip);
				},
			},
			labels: childrens?.map((children, index) => children.name),
			legend: {
				show: true,
			},
			plotOptions: {
				pie: {
					donut: {
						size: "55%",
						labels: {
							show: true,
							value: {
								show: true,
								label: "Total",
								color: "#888",
								formatter: function (val) {
									return compactDecimal(parseInt(val ?? 0), 0) + "%";
								},
							},
							total: {
								show: true,
								label: "Total",
								color: "#888",
								formatter: function (w) {
									return (
										compactDecimal(
											w.globals.seriesTotals.reduce((a, b) => a + b, 0),
											0
										) + "%"
									);
								},
							},
						},
					},
				},
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				width: "0",
			},
			colors: colors,
		},
	};

	return (
		<div className="col-auto">
			<ReactApexChart
				options={state.options}
				series={state.series}
				type={state.options.chart.type}
				width={state.options.chart.width}
				height={state.options.chart.height}
				tooltip={state.options.tooltip}
			/>
		</div>
	);
}
export default PricingPieChart;
