import React, { useEffect, useRef, useState } from "react";
import CardNumber from "components/Composable/CardNumber";
import { useDispatch } from "react-redux";
import { fetchChartByInterval } from "store/actions/MarketshareActions";
import { getDabbleColor } from "utils/UIUtil";
import { debounce } from "utils/InputUtil";
import DabbleDropdown from "components/Forms/Dropdown/DabbleDropdown";
import MarketsharePieChart from "../chart/MarketsharePieChart";
import MarketshareStackedBarChart from "../chart/MarketshareStackedBarChart";
import EmptyCardPlaceholder from "components/Composable/EmptyCardPlaceholder";

// Compile request
// Request chart by request
function DicerGraphicCard(props) {
	const dispatch = useDispatch();
	const cardRef = useRef(null);
	const [data, setData] = useState(null);
	const [activeMainFilter, setActiveFilter] = useState(null);
	const groupBy = props.groupBy;
	const activeFilter = props?.activeFilter;
	const cardFilters = props.filters;
	const number = props.number;
	const colors = data?.dicerSelections?.map((dicer, index) => getDabbleColor(index, 0));
	const dicerLength = activeFilter?.dicerSelections?.length ?? 0;
	const slicerLength = activeFilter?.slicerSelections?.length ?? 0;

	useEffect(() => {
		const defaultCardFilterSelected = cardFilters != null ? cardFilters[0]?.value : null;
		if(activeMainFilter != null || (defaultCardFilterSelected != null)){
			function fetchData() {
				const request = {
					year: activeFilter?.period?.id,
					interval: activeFilter?.interval?.id,
					slicerId: activeFilter?.slicer?.value,
					metric: activeFilter?.metric?.id,
					slicerSelections: activeFilter?.slicerSelections?.map(
						(slicerSelection, _) => slicerSelection.value
					),
					dicerId: activeFilter?.dicer?.value,
					dicerSelections: activeFilter?.dicerSelections?.map(
						(dicerSelection, _) => dicerSelection.value
					),
					intervalFilter: activeMainFilter?.value ?? defaultCardFilterSelected,
					slicerFilter: activeMainFilter?.value ?? defaultCardFilterSelected,
					dicerFilter: activeMainFilter?.value ?? defaultCardFilterSelected,
				};
				dispatch(fetchChartByInterval(request, groupBy, setData));
			}
			if(activeFilter != null && cardFilters != null){
				const debouncedFetchData = debounce(fetchData, 250);
				debouncedFetchData();
			}
		}
	}, [activeMainFilter, activeFilter, cardFilters, groupBy, dispatch]);

	function changeFilter(filter, index) {
		setActiveFilter(filter);
	}

	function getDicers() {
		const selections = activeFilter?.dicerSelections ?? activeFilter?.slicerSelections;
		if (selections != null) {
			const selectionLabels = selections.map((selection) => selection?.label ?? selection?.name);
			return selectionLabels.join(", ").replace(/,(?!.*,)/gim, " and");
		}
		return "-";
	}

	function cardTitle() {
		return (
			<div className="card-header border-0 pb-0">
				{slicerLength > 0 ? (
					<div className="d-flex flex-column w-100">
						{
							<DabbleDropdown
								items={cardFilters}
								onTap={(filter, index) => changeFilter(filter, index)}
								selectedItemId={activeMainFilter?.value}
								toggleClassName="default-select style-3 btn btn-sm filter-option text-body-2xs regular"
								containerClassName="dropdown bootstrap-select form-control style-3 default-select d-inline-flex"
								key={Math.random()}
								buttonStyle="style1"
							/>
						}
						<p className="text-body-2lg p-0 m-0 mt-3">
							Marketshare between{" "}
							{activeFilter?.dicerSelections != null
								? `${dicerLength} ${activeFilter?.dicer?.label}`
								: `${slicerLength} ${activeFilter?.slicer?.label}`}
						</p>
						{activeFilter?.dicerSelections && (
							<p className="text-body-2lg p-0 m-0">{`of ${getDicers()}`}</p>
						)}
						<p className="text-body-2lg p-0 m-0">{`in ${activeFilter?.period?.id}`}</p>
					</div>
				) : (
					<p className="text-body-2lg p-0 m-0 text-muted">No slicer selection selected</p>
				)}
			</div>
		);
	}

	return (
		<div className="col-xl-12 col-md-12">
			<CardNumber number={number} />
			<div className="card">
				{cardTitle()}
				{data != null ? (
					<div
						className="card-body"
						ref={cardRef}>
						<MarketshareStackedBarChart
							data={data}
							colors={colors}
							filter={activeFilter}
						/>
					</div>
				) : (
					<EmptyCardPlaceholder />
				)}
			</div>
		</div>
	);
}

export default DicerGraphicCard;
