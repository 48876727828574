import React from "react";
import { Line } from "react-chartjs-2";
import { getRootColor } from "utils/UIUtil";

function YtdGrossTransactionChart(props) {
	const datasets = props.data.gtv;
	const isPositive = props.data.growth > 0;
	const color = isPositive ? "--success" : "--danger";
	const data = {
		labels: datasets.map((dataset) => dataset.toString()),
		datasets: [
			{
				borderColor: getRootColor(color),
				borderWidth: 3,
				tension: 0.25,
				data: datasets,
				fill: false,
				clip: false,
				borderCapStyle: "round",
			},
		],
	};

	const options = {
		maintainAspectRatio: false,
		responsive: true,
		plugins: {
			legend: {
				display: false,
			},
		},
		title: {
			display: false,
		},
		scales: {
			x: {
				display: !1,
				gridLines: !1,
			},
			y: {
				display: !1,
				gridLines: !1,
				ticks: {
					max: props.data.max,
					beginAtZero: true,
				},
			},
		},
		elements: {
			point: {
				radius: 0,
				borderWidth: 0,
			},
		},
		layout: {
			padding: {
				left: 5,
				right: 5,
				top: 5,
				bottom: 5,
			},
		},
	};

	return (
		<Line
			data={data}
			options={options}
			height={80}
		/>
	);
}

export default YtdGrossTransactionChart;
