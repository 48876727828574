import axiosInstance from '../core/AxiosInstance';

export async function retrieveYtdTransaction(year, comparison){
    const request = {
        year: year,
        comparison: comparison,
    };
    try {
        const url = `${process.env.REACT_APP_DABBLE_ANALYTIC}/marketshare/retrieve/transaction`;
        const response = await axiosInstance.post(url, request);
        return response;
    } catch (error) {
        throw(error)
    }
}

export async function retrieveYtdGrossTransaction(year, comparison){
    const request = {
        year: year,
        comparison: comparison,
    };
    try {
        const url = `${process.env.REACT_APP_DABBLE_ANALYTIC}/marketshare/retrieve/gtv`;
        const response = await axiosInstance.post(url, request);
        return response;
    } catch (error) {
        throw(error)
    }
}

export async function retrieveYtdQuantity(year, comparison){
    const request = {
        year: year,
        comparison: comparison,
    };
    try {
        const url = `${process.env.REACT_APP_DABBLE_ANALYTIC}/marketshare/retrieve/quantity`;
        const response = await axiosInstance.post(url, request);
        return response;
    } catch (error) {
        throw(error)
    }
}
