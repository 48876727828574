import React, {Fragment} from "react";
import SideBar from "./SideBar";

const JobieNav = () => {
   return (
      <Fragment>
         <SideBar />
      </Fragment>
   );
};

export default JobieNav;
