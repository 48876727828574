import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getCookie from "../utils/CookieUtil";

export const pricingApi = createApi({
  reducerPath: "pricingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_DABBLE_ANALYTIC,
    prepareHeaders: (headers) => {
      const token = getCookie("dabble_token");

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getYtdTransaction: builder.query({
      query: ({ body }) => ({
        url: `/pricing/ytd/transaction`,
        method: "post",
        body,
      }),
    }),

    getYtdGTV: builder.query({
      query: ({ body }) => ({
        url: `/pricing/ytd/gtv`,
        method: "post",
        body,
      }),
    }),
    getYtdQuantity: builder.query({
      query: ({ body }) => ({
        url: `/pricing/ytd/quantity`,
        method: "post",
        body,
      }),
    }),

    getTable: builder.query({
      query: ({ body, path }) => ({
        url: `/pricing/table${path}`,
        method: "post",
        body,
      }),
    }),
  }),
});

export const {
  useGetYtdTransactionQuery,
  useGetYtdGTVQuery,
  useGetYtdQuantityQuery,
  useGetTableQuery,
} = pricingApi;
