import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const activateAccountApi = createApi({
  reducerPath: 'activateAccountApi',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_DABBLE_API_HOST }),
  endpoints: (builder) => ({
    
    activateAccount: builder.mutation({
      query: (body) => ({
        url: `${process.env.REACT_APP_DABBLE_AUTH}/users/application-access/account/activation`,
        method: 'post',
        body: body,
      }),
    }),

    checkActivationLink: builder.mutation({
      query: (id) => ({
        url: `${process.env.REACT_APP_DABBLE_NOTIFICATION}/${id}`,
        method: 'get',
      }),
    }),

    setExpiredActivationLink: builder.mutation({
      query: (params) => ({
        url: `${process.env.REACT_APP_DABBLE_NOTIFICATION}/read`,
        method: 'post',
        params
      }),
    }),
  }),
})

export const {
  useActivateAccountMutation,
  useCheckActivationLinkMutation,
  useSetExpiredActivationLinkMutation
} = activateAccountApi