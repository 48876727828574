import React, { useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import {
  forgetPasswordAction,
  loadingToggleAction,
  loginAction,
} from "store/actions/AuthActions";
import { validateEmailFormat } from "services/DataService";
import login from "assets/images/background/bg-1.png";

function Login(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let errorsObj = { email: null, password: null, forgetPasswordEmail: null };
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isPasswordVisible, setShowPassword] = useState(false);
  const [errors, setErrors] = useState(errorsObj);
  const [forgetPasswordEmail, setForgetPasswordEmail] = useState('');
  const [forgetPasswordErrors, setForgetPasswordErrors] = useState({
    forgetPasswordEmail: null,
  });
  const [isForgetPasswordModalOpen, toggleForgetPasswordModal] =
    useState(false);
  const [isForgetPasswordSuccess, setForgetPasswordStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const forgetPasswordModalRef = useRef(null);
  const handleObsecurePassword = () =>
    setShowPassword((prevState) => !prevState);

  function onLogin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === '') {
      errorObj.email = 'Enter your email';
      error = true;
    }
    if (password === '') {
      errorObj.password = 'Enter your password';
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    dispatch(loadingToggleAction(true));
    dispatch(loginAction(email, password, navigate));
  }

  function onForgetPasswordSubmited(e) {
    e.preventDefault();
    // initialize error state
    let error = false;
    const errorObj = { ...errorsObj };

    // check if empty
    if (forgetPasswordEmail === '') {
      errorObj.forgetPasswordEmail = 'Enter your email';
      error = true;
    } else if (!validateEmailFormat(forgetPasswordEmail)) {
      errorObj.forgetPasswordEmail = 'Please check your E-mail format';
      error = true;
    }

    // validate format if not empty
    setForgetPasswordErrors(errorObj);
    if (error) {
      return;
    }
    dispatch(
      forgetPasswordAction(
        forgetPasswordEmail,
        setForgetPasswordStatus,
        setErrorMessage
      )
    );
  }

  function resetForgetPasswordForm(e) {
    const errorObj = { ...errorsObj };
    errorObj.forgetPasswordEmail = '';
    setForgetPasswordEmail('');
    setErrorMessage('')
    setForgetPasswordErrors(errorObj);
    setForgetPasswordStatus(false);
    toggleForgetPasswordModal(false);
  }

  return (
    <div className="login-wrapper">
      <Modal
        className="fade"
        centered="true"
        show={isForgetPasswordModalOpen}
        onHide={resetForgetPasswordForm}
        size="lg"
        ref={forgetPasswordModalRef}
      >
        <Modal.Body className="p-3">
          <Button
            variant=""
            className="close"
            onClick={() => resetForgetPasswordForm()}
          >
            <span className="text-body-xl modal-close-button">&times;</span>
          </Button>
          <div className="text-center mt-4">
            {isForgetPasswordSuccess ? (
              <div>
                <p className="text-body-lg bold">
                  Email sent. Please check your email
                </p>
                <p className="text-body-s">
                  We’ve sent password to reset instructions to:{" "}
                  {forgetPasswordEmail} If it doesn’t arrive soon, check your
                  spam folder.
                </p>
                <p className="mt-3 text-center text-body-s regular">
                  Still haven’t receiving the email?
                  <Link
                    className="text-primary ml-1"
                    onClick={() => {
                      dispatch(
                        forgetPasswordAction(
                          forgetPasswordEmail,
                          setForgetPasswordStatus,
                          setErrorMessage
                        )
                      );
                    }}
                  >
                    Resend e-mail
                  </Link>
                </p>
              </div>
            ) : (
              <form onSubmit={onForgetPasswordSubmited}>
                <p className="text-body-lg bold">Forgot your password?</p>
                <p className="text-body-base">
                  Enter your email address and we’ll send you a link to reset
                  your password.
                </p>
                <div className="form-group">
                  <div
                    className={`input-group d-xl-inline-flex d-none ${
                      forgetPasswordErrors.forgetPasswordEmail
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fa fa-envelope-o color-muted"></i>
                      </span>
                    </div>
                    <input
                      type="email"
                      className="form-control"
                      value={forgetPasswordEmail}
                      onChange={(e) => setForgetPasswordEmail(e.target.value)}
                      placeholder="Enter your e-mail"
                    />
                  </div>
                  {errorMessage && (
                    <div className="text-danger fs-12 mt-1 mb-1 text-center">
                      {errorMessage}
                    </div>
                  )}
                  {forgetPasswordErrors.forgetPasswordEmail && (
                    <div className="text-danger fs-12 mt-1 text-left">
                      {forgetPasswordErrors.forgetPasswordEmail}
                    </div>
                  )}
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block mt-4 text-black btn-rounded"
                  >
                    Reset Password
                  </button>
                </div>
              </form>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <div
        className="login-aside-left"
        style={{ backgroundImage: "url(" + login + ")" }}
      ></div>
      <div className="login-aside-right">
        <div className="row m-0 justify-content-center h-100 align-items-center">
          <div className="col-xl-6 col-xxl-8">
            <div className="authentication-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className=" mb-3">
                      <h3 className="text-center bold mb-5">
                        Welcome to Dabble
                      </h3>
                    </div>
                    <div className="card p-4 border">
                      {props.successMessage && (
                        <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                          {props.successMessage}
                        </div>
                      )}
                      <form onSubmit={onLogin}>
                        <div className="mb-4">
                          <p className="text-center text-body-xl bold">
                            Sign In
                          </p>
                        </div>

                        {/* E-mail textfield */}
                        <div className="form-group">
                          <label className="mb-2 text-body-sm regular">
                            Email
                          </label>
                          <div
                            className={`input-group inline-flex ${
                              errors.email ? "is-invalid" : ""
                            }`}
                          >
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="fa fa-envelope-o color-muted"></i>
                              </span>
                            </div>
                            <input
                              type="email"
                              className={`form-control ${
                                errors.email ? "is-invalid" : ""
                              }`}
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder="Enter your e-mail"
                            />
                          </div>
                          {errors.email && (
                            <div className="text-danger fs-12 mt-1">
                              {errors.email}
                            </div>
                          )}
                        </div>

                        {/* Password text field*/}
                        <div className="form-group">
                          <label className="mb-2 text-body-sm regular">
                            Password
                          </label>
                          <div
                            className={`input-group inline-flex ${
                              errors.password ? "is-invalid" : ""
                            }`}
                          >
                            <div className="input-group-prepend">
                              <span className="input-group-text">
                                <i className="fa fa-lock color-muted"></i>
                              </span>
                            </div>
                            <input
                              type={isPasswordVisible ? "text" : "password"}
                              className="form-control"
                              placeholder="Enter your password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">
                                <Link onClick={handleObsecurePassword}>
                                  <i
                                    className={`fa ${
                                      isPasswordVisible
                                        ? "fa-eye-slash"
                                        : "fa-eye"
                                    } color-muted`}
                                  ></i>
                                </Link>
                              </span>
                            </div>
                          </div>
                          {(errors.password || props.errorMessage) && (
                            <div className="text-danger fs-12 mt-1">
                              {errors.password ?? props.errorMessage}
                            </div>
                          )}
                        </div>

                        {/* Sign in button*/}
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block mt-4 text-black btn-rounded"
                          >
                            Sign In
                          </button>
                        </div>
                      </form>

                      {/* Forget password link */}
                      <div className="new-account mt-3">
                        <p className="mt-3 text-center text-body-xs regular">
                          <Link
                            className="text-primary"
                            onClick={() => toggleForgetPasswordModal(true)}
                          >
                            Forget password ?
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Login);
