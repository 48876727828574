import { AuthReducer } from './reducers/AuthReducer';
import { MenuReducer } from './reducers/MenuReducer';

import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import { activateAccountApi } from 'services/ActivateAccountService'
import { afinityPerformanceApi } from 'services/AfinityPerformanceService';
import { discountApi } from 'services/DiscountService'
import { discountApiV2 } from 'services/DiscountServiceV2'
import { pricingApi } from 'services/PricingService'
import { pricingApiV2 } from 'services/PricingServiceV2'

export const store = configureStore({
    reducer: {
        auth: AuthReducer,
        menu: MenuReducer,
        [activateAccountApi.reducerPath]: activateAccountApi.reducer,
        [discountApi.reducerPath]: discountApi.reducer,
        [discountApiV2.reducerPath]: discountApiV2.reducer,
        [pricingApi.reducerPath]: pricingApi.reducer,
        [pricingApiV2.reducerPath]: pricingApiV2.reducer,
        [afinityPerformanceApi.reducerPath]: afinityPerformanceApi.reducer,
    },

    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
        .concat(activateAccountApi.middleware)
        .concat(discountApi.middleware)
        .concat(discountApiV2.middleware)
        .concat(pricingApi.middleware)
        .concat(pricingApiV2.middleware)
        .concat(afinityPerformanceApi.middleware),
})

setupListeners(store.dispatch)
