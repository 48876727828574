import React, { useRef } from "react";
import Select from "react-select";
import { debounce } from "utils/InputUtil";

function Slicer(props) {
    const slicerRef = useRef(null);
    const slicers = props.slicers ?? [];
    const slicerSelections = props.slicerSelections ?? [];
    const selectedSlicer = props.selectedSlicer;
    const selectedSlicerSelections = props.selectedSlicerSelections;
    const onSlicerSelected = props.onSlicerSelected;
    const onSlicerSelectionSelected = props.onSlicerSelectionSelected;
    const onSearchSlicerSelection = debounce(props.onSearchSlicerSelection, 400);
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderRadius: "1.25rem",
            backgroundColor: "filter-selector",
            border: `1px solid ${state.isFocused ? '#00F0FF' : '#eee'}`,
            ':focus': {
                border: `1px solid #00F0FF`,
            },
            ':hover': {
                border: `1px solid #00F0FF`,
            },
            boxShadow: 'none'
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                borderRadius: "1.25rem",
                ':hover': {
                    backgroundColor: `#00F0FF`,
                },
            };
        },
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                borderRadius: "1.25rem",
            };
        },
        singleValue: (styles, { data }) => {
            return {
                ...styles,
                borderRadius: "1.25rem",
            };
        },
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            borderRadius: "1.25rem",
        }),
    };

    return <div className="col pb-3" id="SlicerSection">
        <hr />
        <p className="text-body-s medium text-primary">Slicer</p>
        <div className="form-group">
            <Select
                ref={slicerRef}
                key={'slicer'}
                className="text-body-base regular"
                value={selectedSlicer}
                onChange={onSlicerSelected}
                options={slicers}
                styles={customStyles}
            />
        </div>
        <div className="form-group">
            <Select
                key={'slicerSelection'}
                className="text-body-base regular"
                styles={customStyles}
                closeMenuOnSelect={false}
                value={selectedSlicerSelections}
                onChange={onSlicerSelectionSelected}
                isMulti
                isClearable={false}
                options={slicerSelections}
                onInputChange={onSearchSlicerSelection}
            />
        </div>
    </div>
}

export default Slicer;