import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import GrowthLabel from "../label/GrowthLabel";
import CardNumber from "components/Composable/CardNumber";
import DabbleDropdown from "components/Forms/Dropdown/DabbleDropdown";
import YtdQuantityChart from "../chart/YtdQuantityChart";
import { fetchYtdQuantity } from "store/actions/SummaryAction";
import { thousandFormat } from "utils/NumericUtil";
import { getShortMonth, getDateRange } from "utils/DataUtil";
import EmptyCardPlaceholder from "components/Composable/EmptyCardPlaceholder";

function YtdQuantityCard(props) {
	const dispatch = useDispatch();
	const filters = props?.activeFilter;
	const defaultCardFilter = "LAST_YEAR";
	const [cardFilters, setcardFilters] = useState([
		{
			label: "WTD",
			value: "LAST_WEEK",
		},
		{
			label: "MTD",
			value: "LAST_MONTH",
		},
		{
			label: "YTD",
			value: "LAST_YEAR",
		},
	]);
	const [selectedFilter, setSelectedFilter] = useState(defaultCardFilter);
	const [data, setData] = useState(null);
	const growth = data?.growth != null ? data?.growth.toFixed(2) : 0;
	function fetchData() {
		dispatch(fetchYtdQuantity(filters?.period?.id, selectedFilter, setData));
	}

	useEffect(() => {
		fetchData();
	}, [filters, selectedFilter]);

	function changeFilter(filter, index) {
		setSelectedFilter(filter.value);
	}

	function getDate() {
		if (filters?.period?.id != null) {
			const dateRange = getDateRange(filters?.period?.id, selectedFilter);
			const startDate =
				selectedFilter === "LAST_YEAR"
					? `${dateRange.startDate.getDate()} ${getShortMonth(
							dateRange.startDate.getMonth()
					  )} ${dateRange.startDate.getFullYear()}`
					: `${dateRange.startDate.getDate()} ${getShortMonth(dateRange.startDate.getMonth())}`;
			const endDate = `${dateRange.endDate.getDate()} ${getShortMonth(
				dateRange.endDate.getMonth()
			)} ${dateRange.endDate.getFullYear()}`;
			return `${startDate} - ${endDate}`;
		}
		return null;
	}

	return (
		<div className='col-xl-4 col-xxl-4 col-lg-4 col-sm-4'>
			<CardNumber number='3' />
			<div className='card'>
				<div className='card-header align-items-start pb-0 border-0'>
					<div className='col-12'>
						<div className='row justfiy-content-between'>
							<div className='d-flex flex-column flex-grow-1'>
								<span className='text-body-xs text-muted'>{getDate()}</span>
								<h5 className='bold'>Category</h5>
							</div>
							<div className='d-inline-flex'>
								<DabbleDropdown
									items={cardFilters}
									onTap={(filter, index) => changeFilter(filter, index)}
									selectedItemId={selectedFilter}
								/>
							</div>
						</div>
					</div>
				</div>
				{data != null ? (
					<div className='card-body pt-0 pb-2'>
						<div className='col-12'>
							<div className='row justify-content-between'>
								<h3 className='bold p-0 m-0'>
									{thousandFormat(data.totalQuantity)}
								</h3>
								<GrowthLabel data={growth} />
							</div>
						</div>
						<div className='col-12'>
							<div className='row'>
								<YtdQuantityChart data={data} />
							</div>
						</div>
					</div>
				) : (
					<EmptyCardPlaceholder />
				)}
			</div>
		</div>
	);
}

export default YtdQuantityCard;
