import { shortCurrency } from "utils/CurrencyUtil";
import { displayPercentage } from "utils/NumericUtil";
import { getDabbleColor } from "utils/UIUtil";

function ComparisonProgressBarMultiV2(props) {
	const data = props.data;
	const parentIndex = props?.parentIndex;
	const parentData = data?.data[parentIndex];
	const isExpanded = props?.isExpanded ?? false;
	const isThirdChildrensExist = parentData?.childrens[0].childrens != null;

	function multilevelProgressBar() {
		return isThirdChildrensExist ? (
			<div
				className="d-flex flex-row"
				style={{ gap: "12px" }}>
				{parentData?.childrens.map((dicer, dicerIndex) => {
					const dicerColor = getDabbleColor(parentIndex, dicerIndex + 1);
					return <div className="d-flex flex-column w-100">
						{ isExpanded && (
							<div>
							<p className="m-0" style={{ color: dicerColor }}>
								{dicer.title} : {shortCurrency({ num: dicer.amount, millionLabel: "mio" })}
							</p>
							<p className="mb-2" style={{ color: dicerColor }}>
								or {displayPercentage(dicer.percentage)}% of {parentData.interval}
							</p>
							</div>
						)}
						{/* The progress bar is designed using dicers */}
						<div
							className="progress w-100"
							style={{
								width: parentData.percentage,
							}}>
							{/* Loop slicer per dicers */}
							{dicer.childrens?.map((slicer, slicerIndex) => (
								<div
									className="progress-bar"
									role="progressbar"
									style={{
										width: `${slicer.percentage}%`,
										backgroundColor: getDabbleColor(
											parentIndex,
											slicerIndex + 1,
										),
									}}
									aria-valuenow={slicer.percentage}
									aria-valuemin="0"
									aria-valuemax="100"
									key={Math.random()}></div>
							))}
						</div>
					</div>;
				})}
			</div>
		) : (
			<div className="progress">
				{parentData?.childrens.map((slicer, index) => (
					<div
						className="progress-bar"
						role="progressbar"
						style={{
							width: `${slicer.percentage}%`,
							backgroundColor: getDabbleColor(parentIndex ?? 0, index + 1),
						}}
						aria-valuenow={slicer.percentage}
						aria-valuemin="0"
						aria-valuemax="100"
						key={Math.random()}></div>
				))}
			</div>
		);
	}

	return (
		<div>
			{multilevelProgressBar()}
			<div className="progress mt-2">
				<div
					className={{
						"progress-bar": true,
						"bg-grey": parentIndex == null,
					}}
					role="progressbar"
					aria-valuenow="100"
					aria-valuemin="0"
					aria-valuemax="100"
					style={{
						backgroundColor:
							parentIndex != null ? getDabbleColor(parentIndex, 0) : null,
						width: "100%",
					}}></div>
			</div>
		</div>
	);
}

export default ComparisonProgressBarMultiV2;
