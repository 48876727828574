import {retrieveFormats} from 'services/FilterService';

export const RETRIEVE_FORMATS_CONFIRMED = '[retrieve format action] confirmed retrieve format';
export const RETRIEVE_FORMATS_FAILED = '[retrieve format action] confirmed retrieve format';
export const UPDATE_SELECTED_FORMAT = '[update selected format]';

export function retrieveFormatsAction(defaultValue){
    return (dispatch) => {
        retrieveFormats()
        .then((response) => { 
            dispatch(retrieveFormatsConfirmedAction(response.data));
            dispatch(updateSelectedFormat(defaultValue ?? response.data[0]))
        })
        .catch((error) => {
            dispatch(retrieveFormatsFailedAction(error));
        });
    };
}

export function retrieveFormatsConfirmedAction(data) {
    return {
        type: RETRIEVE_FORMATS_CONFIRMED,
        result: data,
    };
}

export function retrieveFormatsFailedAction(data) {
    return {
        type: RETRIEVE_FORMATS_FAILED,
        result: data,
    };
}

export function updateSelectedFormat(format){
    return (dispatch) => {
        dispatch({
            type: UPDATE_SELECTED_FORMAT,
            payload: format,
        })
    }
}