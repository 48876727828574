import React, { useMemo, useRef } from "react";
import { getDabbleColor } from "utils/UIUtil";
import { useGetComparisonQuery } from "services/AfinityPerformanceService";
import { extractPercentage, intervalLabel, serialAmount } from "utils/DataUtil";
import { dabbleDecimal, displayPercentage } from "utils/NumericUtil";
import CardNumber from "components/Composable/CardNumber";
import EmptyCardPlaceholder from "components/Composable/EmptyCardPlaceholder";
import CardTitle from "components/Composable/CardTitle";
import ComparisonTooltipTile from "components/Composable/ComparisonTooltipTile";
import ComparisonProgressBarMultiV2 from "components/Composable/ComparisonProgressBarMultiV2";

function SlicerComparisonCard3(props) {
	const cardScrollRef = useRef(null);
	const innerCardRef = useRef(null);
	const activeFilter = props?.activeFilter;
	const slicersLength = activeFilter?.slicerSelections?.length ?? 0;
	const slicerLabel = activeFilter?.slicer?.label;
	const periodId = activeFilter?.period?.id;

	const body = useMemo(() => {
		const filter = {
			year: activeFilter?.period?.id,
			metric: activeFilter?.metric?.id,
			slicer_id: activeFilter?.slicer?.value,
			slicers: activeFilter?.slicerSelections?.map((slicerSelection, _) => slicerSelection.value),
			dicers: activeFilter?.dicerSelections?.map((dicerSelection, _) => dicerSelection.value),
			interval: activeFilter?.interval?.id,
		};

		if (activeFilter?.dicerSelections != null) {
			filter.dicer_id = activeFilter?.dicer?.value;
		}

		return filter;
	}, [activeFilter]);

	const query = useGetComparisonQuery({ body, path: "/3" }, { skip: !body?.slicer_id });
	const data = (query?.data != null) ? extractPercentage(query.data) : null;

	function getDicerLabels() {
		const dataSelections = data?.meta?.dicerSelections ?? activeFilter?.slicerSelections;
		return dataSelections?.map((dicerSelection) => dicerSelection?.name ?? dicerSelection?.label);
	}

	function getyearRange() {
		if (data != null) {
			const months = data?.data?.map((data, index) => data?.title);
			const lastMonths = months[months.length - 1];
			return `${months[0]} to ${lastMonths}`;
		}
	}

	function progressBarBottomCaption(parentData, parentIndex) {
		const colorStyle = {
			color: getDabbleColor(parentIndex, 0),
		};

		return (
			<p
				className="mt-1 mb-0 text-body-s text-center"
				style={colorStyle}>
				{parentIndex + 1}
			</p>
		);
	}


	return (
		<div className="col-xl-12 col-md-12">
			<div className="card">
				<CardNumber number="8" />
				<CardTitle
					slicerTitle={slicerLabel}
					slicerLength={slicersLength}
					dicerLabels={getDicerLabels()}
					period={periodId}
					periodRange={getyearRange()}
					key={Math.random()}
				/>
				{data?.data != null ? (
					<div
						className="card-body text-body-s regular"
						ref={cardScrollRef}>
						<div
							className="d-flex mb-3"
							style={{ gap: "10px" }}
							ref={innerCardRef}
							key={Math.random()}>
							{data?.data.map((parentData, parentIndex) => (
								<div
									className="dabble-tooltip-container"
									style={{ width: `${parentData.percentage}%` }}
									key={Math.random()}>
									{/* Tooltip */}
									<div
										className="dabble-tooltip rounded-sm shadow mt-5 py-2 px-4 text-body regular"
										style={{ zIndex: "1080" }}>
										<p className="p-0 pb-0 mb-1">
											{intervalLabel(activeFilter?.interval?.id)} {parentIndex + 1} ({parentData.title}): {serialAmount(parentData?.amount, activeFilter?.metric?.id)} or {displayPercentage(parentData.percentage)}% of {activeFilter?.period?.id}
										</p>
										{parentData.childrens?.map((slicer, slicerIndex) => {
											return (
												<ComparisonTooltipTile
													title={slicer.title}
													value={serialAmount(slicer.amount, activeFilter?.metric?.id)}
													percentage={slicer.percentage}
													growth={dabbleDecimal(slicer.growth)}
													color={getDabbleColor(slicerIndex, parentIndex, 10)}
													key={Math.random()}
												/>
											);
										})}
									</div>
									<ComparisonProgressBarMultiV2
										data={data}
										parentIndex={parentIndex}
										parentData={parentData}
										key={Math.random()}
									/>
									{progressBarBottomCaption(parentData, parentIndex)}
								</div>
							))}
						</div>
					</div>
				) : (
					<EmptyCardPlaceholder />
				)}
			</div>
		</div>
	);
}

export default SlicerComparisonCard3;
