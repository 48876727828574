import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchSlicerComparison } from "store/actions/PerformanceAction";
import { getDabbleColor } from "utils/UIUtil";
import { displayPercentage, dabbleDecimal } from "utils/NumericUtil";
import { serialAmount } from "utils/DataUtil";
import CardNumber from "components/Composable/CardNumber";
import ComparisonTooltipTile from "components/Composable/ComparisonTooltipTile";
import ComparisonProgressBar from "components/Composable/ComparisonProgressBar";
import EmptyCardPlaceholder from "components/Composable/EmptyCardPlaceholder";
import CardTitle from "components/Composable/CardTitle";
import debounce from "lodash.debounce";

function SlicerComparisonCard2(props) {
	const cardScrollRef = useRef(null);
	const screenWidth = window.innerWidth;
	const activeFilter = props?.activeFilter;
	const slicersLength = activeFilter?.slicerSelections?.length ?? 0;
	const slicerLabel = activeFilter?.slicer?.label;
	const periodId = activeFilter?.period?.id;
	const dispatch = useDispatch();
	const [isExpanded, setZoom] = useState(false);
	const [data, setData] = useState(null);

	useEffect(() => {
		if(activeFilter?.slicerSelections != null && activeFilter?.dicerSelections != null){
			function fetchData() {
				const filter = {
					year: activeFilter?.period?.id,
					metric: activeFilter?.metric?.id,
					slicer_id: activeFilter?.slicer?.value,
					slicers: activeFilter?.slicerSelections?.map((slicerSelection, _) => slicerSelection.value),
					dicers: activeFilter?.dicerSelections?.map((dicerSelection, _) => dicerSelection.value),
				};
				if (activeFilter?.dicerSelections != null) {
					filter.dicer_id = activeFilter?.dicer?.value;
				}
				dispatch(fetchSlicerComparison(filter, "dicer", setData));
			}
			const debouncedFetchData = debounce(fetchData, 250);
			debouncedFetchData();
		}
	}, [activeFilter, dispatch]);

	function getDicerLabels() {
		const selections = activeFilter?.dicerSelections ?? activeFilter?.slicerSelections;
		return selections.map((selection) => selection?.label ?? selection?.name);
	}

	function progressBarBottomCaption(parentData, parentIndex) {
		const commonStyle = {
			color: getDabbleColor(parentIndex, 0),
		};

		return (
			<div className="row">
				<div className="col text-left">
					<p
						className="mt-3 mb-0"
						style={commonStyle}>
						{parentData.name}
					</p>
				</div>
			</div>
		);
	}

	return (
		<div className="col-xl-12 col-md-12">
			<CardNumber number="6" />
			<div className="card">
				<CardTitle
					slicerTitle={slicerLabel}
					slicerLength={slicersLength}
					dicerLabels={getDicerLabels()}
					period={periodId}
				/>
				{data != null ? (
					<div
						className="card-body text-body-s regular"
						ref={cardScrollRef}>
						<div
							className={`d-flex`}
							style={{ gap: "10px" }}>
							{data.datas.map((parentData, parentIndex) => (
								<div
									style={{ width: `${parentData.percentage}%` }}
									key={Math.random()}>
									<div className="dabble-tooltip-container w-100">
										<div
											className="dabble-tooltip rounded-sm shadow mt-5 py-2 px-4 text-body regular"
											style={{ zIndex: "1080" }}>
											<p className="p-0 pb-0 mb-1">
												{`${parentData.name}: ${serialAmount(
													parentData.amount,
													activeFilter?.metric?.id
												)} or ${dabbleDecimal(parentData.percentage)}% of ${activeFilter?.period?.id}`}
											</p>
											{parentData.childrens?.map((slicer, slicerIndex) => {
												return (
													<ComparisonTooltipTile
														title={slicer.name}
														value={serialAmount(slicer.amount, activeFilter?.metric?.id)}
														percentage={slicer.percentage}
														growth={dabbleDecimal(slicer.growth)}
														color={getDabbleColor(parentIndex, slicerIndex + 1, parentData.childrens.length + 2)}
														key={Math.random()}
													/>
												);
											})}
										</div>
										<ComparisonProgressBar
											data={data}
											parentIndex={parentIndex}
											isMultiLevel={true}
										/>
									</div>
								</div>
							))}
						</div>
						<div className="row">
							{data.datas.map((parentData, parentIndex) =>
								<div className="col" key={Math.random()}>
									{progressBarBottomCaption(parentData, parentIndex)}
								</div>
							)}
						</div>
					</div>
				) : (
					<EmptyCardPlaceholder />
				)}
			</div>
		</div>
	);
}

export default SlicerComparisonCard2;
