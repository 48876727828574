import axios from "axios";
import {
	forgetPassword,
	formatError,
	login,
	logout,
	runLogoutTimer,
	saveTokenInLocalStorage,
	saveTokenV2InLocalStorage,
	signUp,
} from "services/AuthService";
import { deleteCookie } from "utils/CookieUtil";

export const FORGET_PASSWORD_CONFIRMED_ACTION =
	"[forget password action] confirmed forget password";
export const FORGET_PASSWORD_FAILED_ACTION = "[forget password action] failed forget password";
export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";

export function forgetPasswordAction(
  email,
  setForgetPasswordStatus,
  setErrorMessage
) {
  return (dispatch) => {
    forgetPassword(email)
      .then((response) => {
        setForgetPasswordStatus(response?.data?.success);
        if (!response?.data?.succes) {
          setErrorMessage(response?.data?.message);
        }
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        setErrorMessage(error?.message || error?.response?.data?.message);
        dispatch(forgetPasswordFailedAction(errorMessage));
      });
  };
}

export function signupAction(email, password, navigate) {
	return (dispatch) => {
		signUp(email, password)
			.then((response) => {
				saveTokenInLocalStorage(response.data);
				runLogoutTimer(
					dispatch,
					response.data.expiresIn * 1000
					//history,
				);
				dispatch(confirmedSignupAction(response.data));
				navigate("/dashboard");
				//history.push('/dashboard');
			})
			.catch((error) => {
				const errorMessage = formatError(error.response.data);
				dispatch(signupFailedAction(errorMessage));
			});
	};
}

export function logoutAction(navigate) {
	return (dispatch) => {
		logout()
			.then((response) => {
				dispatch(logoutActionOutput(true, response.data));
			})
			.catch((error) => {
				dispatch(logoutActionOutput(false, error));
			})
			.finally(() => {
				localStorage.removeItem("userDetails");
				deleteCookie("dabble_token");
				deleteCookie("dabble_refresh_token");
				deleteCookie("dabble_token_v2");
				deleteCookie("dabble_refresh_token_v2");
				navigate("/login", { replace: true });
			});
	};
}

export function loginAction(email, password, navigate) {
  return (dispatch) => {
    login(email, password)
      .then(
        axios.spread((data1, data2) => {
          saveTokenInLocalStorage(data1.data);
          saveTokenV2InLocalStorage(data2.data.data);
          dispatch(loginConfirmedAction(data1.data));
          navigate("/dashboard", { replace: true });
        })
      )
      .catch((error) => {
        const errorMessage = "Username / password salah";
        dispatch(loginFailedAction(errorMessage));
      });
  };
}

export function forgetPasswordFailedAction(data) {
	return {
		type: FORGET_PASSWORD_FAILED_ACTION,
		payload: data,
	};
}

export function forgetPasswordConfirmedAction(data) {
	return {
		type: FORGET_PASSWORD_CONFIRMED_ACTION,
		payload: data,
	};
}

export function loginFailedAction(data) {
	return {
		type: LOGIN_FAILED_ACTION,
		payload: data,
	};
}

export function loginConfirmedAction(data) {
	return {
		type: LOGIN_CONFIRMED_ACTION,
		payload: data,
	};
}

export function logoutActionOutput(isSuccess, payload) {
	return {
		type: LOGOUT_ACTION,
		payload: payload,
		isSuccess: isSuccess,
	};
}

export function confirmedSignupAction(payload) {
	return {
		type: SIGNUP_CONFIRMED_ACTION,
		payload,
	};
}

export function signupFailedAction(message) {
	return {
		type: SIGNUP_FAILED_ACTION,
		payload: message,
	};
}

export function loadingToggleAction(status) {
	return {
		type: LOADING_TOGGLE_ACTION,
		payload: status,
	};
}
