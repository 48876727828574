/**
 * / Menu
 * import MetisMenu from "metismenujs";
 */
import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { isEven, isOdd } from "utils/NumericUtil";
import { debounce } from "utils/InputUtil";
import {
	syncActiveFiltersAction,
	updateActiveFiltersAction,
	updateFilterAction,
} from "store/actions/FilterActions";
import {
	retrieveAnalyticsAction,
	updateSelectedAnalytic,
} from "store/actions/filter/AnalyticActions";
import { retrieveMetricsAction, updateSelectedMetric } from "store/actions/filter/MetricActions";
import {
	retrieveIntervalsAction,
	updateSelectedInterval,
} from "store/actions/filter/IntervalActions";
import { retrievePeriodsAction, updateSelectedPeriod } from "store/actions/filter/PeriodActions";
import {
	retrieveSlicersAction,
	retrieveSlicerSelectionAction,
	updateSelectedSlicer,
	updateSelectedSlicerSelections,
} from "store/actions/filter/SlicerActions";
import {
	retrieveDicersAction,
	retrieveDicerSelectionAction,
	updateSelectedDicer,
	updateSelectedDicerSelections,
} from "store/actions/filter/DicerActions";
import { menu } from "store/selectors/MenuSelectors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logoutAction } from "store/actions/AuthActions";
import Swal from "sweetalert2";
import Slicer from "layouts/nav/filters/Slicer";
import Dicer from "layouts/nav/filters/Dicer";

function SideBar(props) {
	const properties = props;
	const location = useLocation();
	const navigate = useNavigate();

	// Slicer and dicers
	const _menu = useSelector(menu);
	const _filter = _menu.filter;
	const _selectedAnalytic = _filter?.analytic;
	const _selectedMetric = _filter?.metric;
	const _selectedPeriod = _filter?.period;
	const _selectedInterval = _filter?.interval;
	const _selectedSlicer = _filter?.slicer;
	const _selectedDicer = _filter?.dicer;
	const _selectedSlicerSelections = _filter?.slicerSelections;
	const _selectedDicerSelection = _filter?.dicerSelections;
	const _analytics = _menu?.analytics;
	const _metrics = _menu?.metrics;
	const _periods = _menu?.periods;
	const _intervals = _menu?.intervals;
	const _slicers = _menu?.slicers;
	const _dicers = _menu?.dicers;
	const _slicerSelections = _menu?.slicerSelections;
	const _dicerSelections = _menu?.dicerSelections;
	const companyName = localStorage.getItem('company_name') || null
	const companyLogo = localStorage.getItem('company_logo') || null

	useEffect(() => {
		function onInit() {
			properties.onSyncActiveFilters(location);
		}
		const debouncedInitialMethod = debounce(onInit, 250);
		debouncedInitialMethod();
	}, [properties, location]);

	function handleAnalyticClick(e, analytic) {
		e.preventDefault();
		const route = analytic.route;
		const queryParams = location?.search;
		const designatedRoute = (queryParams!=null) ? (route + queryParams) : route;
		navigate(designatedRoute);
		properties.onAnalyticSelected(analytic);
	}


	function onSlicerSelected(slicer) {
		properties.onSlicerSelected(slicer);
		properties.onSlicerSelectionsUpdated(null);
	}

	function onDicerSelected(dicer) {
		const payload = {
			keywordName: null,
			excludeSelectionId: [],
			pageable: {
				pageNumber: 0,
				pageSize: 100,
				sortDirection: "asc",
				sortProperty: "name",
			},
		};
		properties.onDicerSelected(dicer);
		properties.onRetrieveDicerSelections(dicer?.value, payload);
	}

	function updateActiveFilters() {
		properties.onUpdateActiveFilters({
			analytic: _selectedAnalytic,
			metric: _selectedMetric,
			period: _selectedPeriod,
			interval: _selectedInterval,
			slicer: _selectedSlicer,
			slicerSelections: _selectedSlicerSelections,
			dicer: _selectedDicer,
			dicerSelections: _selectedDicerSelection,
		}, navigate, location);
	}

	function logout() {
		Swal.fire({
			title: "Are you sure?",
			text: "Do you want to logout?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#d33",
			confirmButtonText: "Logout",
		}).then((result) => {
			if (result.isConfirmed) {
				properties.onLogout(navigate);
			}
		});
	}

	return (
		<div className="deznav d-flex flex-column">
			<div className="deznav-scroll h-100">
				<div className="col pt-3">
					{ (companyLogo !== undefined && companyLogo !== null && companyLogo !== 'undefined') ? (
						<img
						src={companyLogo}
						alt="Logo"
						className="w-100 mb-3"
					/>
					):null}
					<p className="text-body-base">Hello</p>
					{ (companyName !== undefined && companyName !== null && companyName !== 'undefined') && (
						<p className="text-body-lg">{companyName}</p>
					)}
				</div>
				{_metrics != null ? (
					<div
						className="col pb-3"
						id="MetricSection">
						<hr></hr>
						<p className="text-body-s medium text-black">Metric</p>
						<div className="row">
							{_metrics.map((data, index) => {
								const topPadding = index > 1 ? " mt-2" : "";
								const rightPadding = isEven(index) ? " pr-2" : "";
								const leftPadding = isOdd(index) ? " pl-2" : "";
								const isSelected = _selectedMetric?.id === data.id;

								return (
									<div
										key={data.title}
										className={`col-md-6${topPadding}${rightPadding}${leftPadding}`}>
										<button
											className={`btn ${
												isSelected ? "btn-primary" : "btn-outline-primary"
											} btn-xxs btn-block text-black`}
											onClick={(e) => properties.onMetricSelected(data)}>
											{data.title}
										</button>
									</div>
								);
							})}
						</div>
					</div>
				) : null}
				{_periods != null ? (
					<div
						className="col pb-3"
						id="PeriodSection">
						<hr></hr>
						<p className="text-body-s medium text-black">Period</p>
						<div className="d-flex flex-wrap" style={{gap:'5px'}}>
							{_periods.map((data, index) => {
								const isSelected = _selectedPeriod?.id === data.id;

								return (
									<div
										key={data.title}>
										<button
											className={`btn ${
												isSelected ? "btn-primary" : "btn-outline-primary"
											} btn-xxs btn-block text-black`}
											onClick={(e) => properties.onPeriodSelected(data)}>
											{data.title}
										</button>
									</div>
								);
							})}
						</div>
					</div>
				) : null}
				{_intervals != null ? (
					<div
						className="col pb-3"
						id="IntervalSection">
						<hr></hr>
						<p className="text-body-s medium text-black">Interval</p>
						<div className="row">
							{_intervals.map((data, index) => {
								const topPadding = index > 1 ? " mt-2" : "";
								const rightPadding = isEven(index) ? " pr-2" : "";
								const leftPadding = isOdd(index) ? " pl-2" : "";
								const isSelected = _selectedInterval?.id === data.id;

								return (
									<div
										key={data.title}
										className={`col-md-6${topPadding}${rightPadding}${leftPadding}`}>
										<button
											className={`btn ${
												isSelected ? "btn-primary" : "btn-outline-primary"
											} btn-xxs btn-block text-black`}
											onClick={(e) => properties.onIntervalSelected(data)}>
											{data.title}
										</button>
									</div>
								);
							})}
						</div>
					</div>
				) : null}
				{/* Slicer section */}
				<Slicer
					slicers={_slicers}
					slicerSelections={_slicerSelections}
					selectedSlicer={_selectedSlicer}
					selectedSlicerSelections={_selectedSlicerSelections}
					onSlicerSelected={onSlicerSelected}
					onSlicerSelectionSelected={(slicerSelection) => {
						const condition = (slicerSelection != null)?  slicerSelection?.length <= 5 : true
						if(condition){
							properties.onSlicerSelectionsUpdated(slicerSelection);
						}
					}}
					onSearchSlicerSelection={(value) => {
						properties.onRetrieveSlicerSelections(_selectedSlicer?.value, {
							keywordName: value,
						});
					}}
				/>
				{/* End of slicer section */}
				{/* Dicer section */}
				{_selectedSlicer != null ? (
					<Dicer
						dicers={_dicers}
						dicerSelections={_dicerSelections}
						selectedDicer={_selectedDicer}
						selectedDicerSelection={_selectedDicerSelection}
						onDicerSelected={onDicerSelected}
						onDicerSelectionSelected={(dicerSelection) => {
							const condition = (dicerSelection != null) ?  dicerSelection?.length <= 5 : true
							if(condition){
								properties.onDicerSelectionsUpdated(dicerSelection)
							}
						}}
						onSearchDicerSelection={(value) => {
							properties.onRetrieveDicerSelections(_selectedDicer?.value, {
								keywordName: value,
							});
						}}
					/>
				) : null}
				<div className="col pb-3 pt-0">
					<hr></hr>
					<p className="text-body-s medium text-black">Analysis</p>
					<div className="row">
						{_analytics != null
							? _analytics?.map((data, index) => {
									const topPadding = index > 1 ? " mt-2" : "";
									const rightPadding = isEven(index) ? " pr-2" : "";
									const leftPadding = isOdd(index) ? " pl-2" : "";
									const isSelected = _selectedAnalytic?.id === data.id;
									return (
										<div
											key={data.title}
											className={`col-md-6${topPadding}${rightPadding}${leftPadding}`}>
											<div
												className={`btn ${
													isSelected ? "btn-primary" : "btn-outline-primary"
												} btn-xxs btn-block text-black`}
												onClick={(e) => handleAnalyticClick(e, data)}
												to={data.route}>
												{data.title}
											</div>
										</div>
									);
							  })
							: null}
					</div>
				</div>
				{/* End of dicer section */}
				<div className="col col-auto mb-3">
					<button
						className="btn btn-primary btn-rounded btn-block"
						onClick={updateActiveFilters}>
						<i className="fa fa-filter mr-1"></i>
						Apply filter
					</button>
				</div>
			</div>
			<div className="d-flex flex-column p-3 shadow-lg">
				<Link
					onClick={logout}
					className="ai-icon d-flex flex-row align-items-center">
					<FontAwesomeIcon icon="fa-solid fa-arrow-right-from-bracket" />
					<span className="ml-2">Logout </span>
				</Link>
				{/* <a className="d-flex flex-row" href="profile">
					<img
						src={avatar}
						alt=""
						width="42"
						className="rounded-circle me-2"
					/>
					<div className="ml-2">
						<h5 className="bold m-0">
							Admin
						</h5>
						<span className="text-body-xs regular p-0 m-0">demo@dabble.com</span>
					</div>
				</a> */}
			</div>
		</div>
	);
}

const mapDispatchToProps = (dispatch) => {
	return {
		onSyncActiveFilters: (location) => dispatch(syncActiveFiltersAction(location)),
		onUpdateFilters: (filters) => dispatch(updateFilterAction(filters)),
		onUpdateActiveFilters: (activeFilters, navigate, location) => dispatch(updateActiveFiltersAction(activeFilters, navigate, location)),
		// onRetrieveAnalytics: () => dispatch(retrieveAnalyticsAction()),
		// onRetrieveMetrics: () => dispatch(retrieveMetricsAction()),
		// onRetrieveFormats: () => dispatch(retrieveFormatsAction()),
		// onRetrieveIntervals: () => dispatch(retrieveIntervalsAction()),
		// onRetrievePeriods: () => dispatch(retrievePeriodsAction()),
		onRetrieveSlicers: () => dispatch(retrieveSlicersAction()),
		onRetrieveDicers: (slicerId) => dispatch(retrieveDicersAction(slicerId, null)),
		onRetrieveSlicerSelections: (slicerId, payload) =>
			dispatch(retrieveSlicerSelectionAction(slicerId, payload)),
		onRetrieveDicerSelections: (dicerId, payload) =>
			dispatch(retrieveDicerSelectionAction(dicerId, payload)),
		onAnalyticSelected: (analytic) => dispatch(updateSelectedAnalytic(analytic)),
		onMetricSelected: (metric) => dispatch(updateSelectedMetric(metric)),
		// onFormatSelected: (format) => dispatch(updateSelectedFormat(format)),
		onIntervalSelected: (interval) => dispatch(updateSelectedInterval(interval)),
		onPeriodSelected: (period) => dispatch(updateSelectedPeriod(period)),
		onSlicerSelected: (slicer) => {
			dispatch(updateSelectedSlicer(slicer));
			dispatch(retrieveDicersAction(slicer.value));
			dispatch(retrieveSlicerSelectionAction(slicer.value));
			dispatch(updateSelectedDicerSelections(null));
		},
		onDicerSelected: (dicer) => {
			dispatch(updateSelectedDicer(dicer));
			dispatch(updateSelectedDicerSelections(null));
			if (dicer != null) {
				dispatch(retrieveDicerSelectionAction(dicer.value));
			}
		},
		onSlicerSelectionsUpdated: (slicerSelection) =>
			dispatch(updateSelectedSlicerSelections(slicerSelection)),
		onDicerSelectionsUpdated: (dicerSelection) =>
			dispatch(updateSelectedDicerSelections(dicerSelection)),
		onLogout: (navigate) => dispatch(logoutAction(navigate)),
	};
};

const mapStateToProps = (state) => {
	return {
		filter: state.filter,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
