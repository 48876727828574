import {
	retrieveYtdGrossTransaction,
	retrieveYtdQuantity,
	retrieveYtdTransaction,
} from "services/SummaryService";

export const RETRIEVE_YTD_TRANSACTION_SUCCESS = "[Retrieve date to date transaction success]";
export const RETRIEVE_YTD_TRANSACTION_FAILED = "[Retrieve date to date transaction failed]";
export const RETRIEVE_YTD_GROSS_TRANSACTION_VALUE_SUCCESS = "[Retrieve date to date growth transaction value success]";
export const RETRIEVE_YTD_GROSS_TRANSACTION_VALUE_FAILED = "[Retrieve date to date growth transaction value failed]";
export const RETRIEVE_YTD_QUANTITY_SUCCESS = "[Retrieve date to date quantity success]";
export const RETRIEVE_YTD_QUANTITY_FAILED = "[Retrieve date to date quantity failed]";

export function fetchYtdTransaction(year, comparison, callback) {
	return (dispatch) => {
		retrieveYtdTransaction(year, comparison)
			.then((response) => {
				response.data.data.title = "2023 YTD Sell Out data";
				dispatch({
					type: RETRIEVE_YTD_TRANSACTION_SUCCESS,
					payload: response.data.data,
				});
				callback(response.data.data);
			})
			.catch((error) => {
				dispatch({
					type: RETRIEVE_YTD_TRANSACTION_FAILED,
					payload: null,
					error: error.toString(),
				});
				callback(null);
			});
	};
}

export function fetchYtdGrossTransaction(year, comparison, callback) {
	return (dispatch) => {
		retrieveYtdGrossTransaction(year, comparison)
			.then((response) => {
				response.data.data.title = "2023 YTD Sell Out Data";
				dispatch({
					type: RETRIEVE_YTD_GROSS_TRANSACTION_VALUE_SUCCESS,
					payload: response.data.data,
				});
				callback(response.data.data);
			})
			.catch((error) => {
				dispatch({
					type: RETRIEVE_YTD_GROSS_TRANSACTION_VALUE_FAILED,
					payload: null,
					error: error.toString(),
				});
				callback(null);
			});
	};
}

export function fetchYtdQuantity(year, comparison, callback) {
	return (dispatch) => {
		retrieveYtdQuantity(year, comparison)
			.then((response) => {
				response.data.data.title = "2023 YTD Sell Out Data";
				dispatch({
					type: RETRIEVE_YTD_QUANTITY_SUCCESS,
					payload: response.data.data,
				});
				callback(response.data.data);
			})
			.catch((error) => {
				dispatch({
					type: RETRIEVE_YTD_QUANTITY_FAILED,
					payload: null,
					error: error.toString(),
				});
				callback(null);
			});
	};
}
