import { dabbleDecimal } from "utils/NumericUtil";

function ComparisonTooltipTile(props){
    const color = props.color;
    const title = props.title;
    const value = props.value;
    const growth = props.growth;
    const percentage = props.percentage;
    const isPositive = growth > 0;
    const growthColor = isPositive ? "#21B830" : "#FF2626";
    const arrow = isPositive ? "4,0 0,8 8,8" : "4,8 0,0 8,0";

    function delta(){
        return <>
            <span className="p-0 m-0">
                {` ( `}
            </span>
            <svg
                width="8"
                height="8"
                className="p-0 m-0">
                <polygon
                    points={arrow}
                    fill={growthColor}
                />
            </svg>
            <span className="p-0 ml-1">
                {growth}
            </span>
            <span className="p-0 m-0">
                {`%) `}
            </span>
        </>
    }

    return (
        <div className="d-flex flex-row align-content-between text-body-xs align-items-center ">
            <div className="px-0">
                <div className="px-2 py-1 my-1 border rounded">
                    <svg
                        className="mr-1"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <rect
                            width="12"
                            height="12"
                            rx="12.5"
                            fill={color}></rect>
                    </svg>
                    <span className="p-0 m-0">
                        { title }
                    </span>
                    <span className="p-0 m-0">
                        { ` (${dabbleDecimal(percentage)}%)` }
                    </span>
                </div>
            </div>
            <div className="d-flex flex-row flex-grow-1 justify-content-end">
                <div className="text-right pl-1 p-0 m-0">
                    <span className="p-0 m-0">
                        { value }
                    </span>
                    {/*  */}
                    {growth != 0 ? delta():null}
                </div>
            </div>
        </div>
    );

}

export default ComparisonTooltipTile;