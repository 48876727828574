import CardNumber from "components/Composable/CardNumber";
import PricingBarLineChart2 from "../chart/PricingBarLineChart2";
import PricingTable2 from "../table/PricingTable2";
import { useSelector } from "react-redux";
import { getTimespanFilterByPeriod } from "utils/FilterUtil";
import { useState } from "react";

export default function PricingGraphicTableCard2() {
	const { activeFilter } = useSelector((state) => state.menu);
	const timespanPeriods = getTimespanFilterByPeriod(activeFilter?.interval?.id || "WEEKLY");
	const defaultActiveTimespan = timespanPeriods[timespanPeriods?.length - 1];

	const [dicerFilter, setDicerFilter] = useState(activeFilter?.dicerSelections?.[0]);
	const [activeTimespanFilter, setActiveTimespanFilter] = useState(defaultActiveTimespan);

	return (
		<div className="col-xl-12 col-md-12">
			<div className="card">
				<CardNumber number="7" />
				<div className="card-body w-100">
					<PricingBarLineChart2
						dicerFilter={dicerFilter}
						setDicerFilter={setDicerFilter}
						activeTimespanFilter={activeTimespanFilter}
						setActiveTimespanFilter={setActiveTimespanFilter}
						timespanPeriods={timespanPeriods}
					/>
					<PricingTable2
						dicerFilter={dicerFilter}
						activeTimespanFilter={activeTimespanFilter}
					/>
				</div>
			</div>
		</div>
	);
}
