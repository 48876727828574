import React, { useMemo, useRef } from "react";
import { getDabbleColor } from "utils/UIUtil";
import { useGetComparisonQuery } from "services/DiscountServiceV2";
import CardNumber from "components/Composable/CardNumber";
import EmptyCardPlaceholder from "components/Composable/EmptyCardPlaceholder";
import CardTitle from "components/Composable/CardTitle";
import ComparisonStackedBarChart from "../chart/ComparisonStackedBarChart";

function SlicerComparisonCard3(props) {
	const cardScrollRef = useRef(null);
	const innerCardRef = useRef(null);
	const activeFilter = props?.activeFilter;
	const slicersLength = activeFilter?.slicerSelections?.length ?? 0;
	const slicerLabel = activeFilter?.slicer?.label;
	const periodId = activeFilter?.period?.id;

	const body = useMemo(() => {
		const filter = {
			year: activeFilter?.period?.id,
			metric: activeFilter?.metric?.id,
			slicer_id: activeFilter?.slicer?.value,
			slicers: activeFilter?.slicerSelections?.map((slicerSelection, _) => slicerSelection.value),
			dicers: activeFilter?.dicerSelections?.map((dicerSelection, _) => dicerSelection.value),
			interval: activeFilter?.interval?.id,
		};

		if (activeFilter?.dicerSelections != null) {
			filter.dicer_id = activeFilter?.dicer?.value;
		}

		return filter;
	}, [activeFilter]);

	const { data } = useGetComparisonQuery({ body, path: "/3" }, { skip: !body?.slicer_id });
	const colors = data?.meta?.dicerSelections?.map((dicer, index) => getDabbleColor(index, 0));

	function getDicerLabels() {
		const dataSelections = data?.meta?.dicerSelections ?? activeFilter?.slicerSelections;
		return dataSelections?.map((dicerSelection) => dicerSelection?.name ?? dicerSelection?.label);
	}

	function getyearRange() {
		if (data != null) {
			const months = data?.data?.map((data, index) => data?.title);
			const lastMonths = months[months.length - 1];
			return `${months[0]} to ${lastMonths}`;
		}
	}

	return (
		<div className="col-xl-12 col-md-12">
			<div className="card">
				<CardNumber number="8" />
				<CardTitle
					slicerTitle={slicerLabel}
					slicerLength={slicersLength}
					dicerLabels={getDicerLabels()}
					period={periodId}
					periodRange={getyearRange()}
					key={Math.random()}
				/>
				{data != null ? (
					<div
						className="card-body text-body-s regular"
						ref={cardScrollRef}>
						<ComparisonStackedBarChart
							data={data}
							colors={colors}
							filter={activeFilter}
						/>
					</div>
				) : (
					<EmptyCardPlaceholder />
				)}
			</div>
		</div>
	);
}

export default SlicerComparisonCard3;
