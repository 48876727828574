import React, { useCallback } from "react";
import CardNumber from "components/Composable/CardNumber";
import ComparisonProgressBar from "components/Dashboard/discount/composable/ComparisonProgressBar";
import { useSelector } from "react-redux";
import { formatCurrency } from "utils/CurrencyUtil";
import { getDabbleColor } from "utils/UIUtil";
import { dabbleDecimal, thousandFormat } from "utils/NumericUtil";
import { useGetComparisonQuery } from "services/DiscountServiceV2";
import { useMemo } from "react";
import EmptyCardPlaceholder from "components/Composable/EmptyCardPlaceholder";
import ComparisonTooltipTile from "components/Composable/ComparisonTooltipTile";
import { serialAmount } from "utils/DataUtil";

function SlicerComparisonCard() {
	const { activeFilter } = useSelector((state) => state.menu);
	const slicersLength = activeFilter?.slicerSelections?.length ?? 0;
	const body = {
		year: activeFilter?.period?.id,
		metric: activeFilter?.metric?.id,
		slicer_id: activeFilter?.slicer?.value,
		slicers: activeFilter?.slicerSelections?.map((slicerSelection) => slicerSelection.value),
		interval: activeFilter?.interval?.id,
	};
	if (activeFilter?.dicerSelections != null) {
		body.dicer_id = activeFilter?.dicer?.value;
		body.dicers = activeFilter?.dicerSelections?.map((dicerSelection) => dicerSelection.value);
	}

	const { data } = useGetComparisonQuery(
		{
			body,
			path: "/1",
		},
		{ skip: !body.slicer_id }
	);

	const combinedGTV = useMemo(
		() =>
			data?.data?.reduce(
				(accumulator, data) => accumulator + data?.amount + data?.discountedAmount,
				0
			),
		[data?.data]
	);

	const getDataGroup = useCallback(() => {
		if (data != null) {
			const selections = activeFilter?.dicerSelections ?? activeFilter?.slicerSelections;
			if (selections != null) {
				const selectionLabels = selections.map((selection) => selection?.label);
				return selectionLabels.join(", ").replace(/,(?!.*,)/gim, " and");
			}
		}
		return "-";
	}, [activeFilter?.dicerSelections, activeFilter?.slicerSelections, data]);

	function cardTitle() {
		const slicerSelectionsLength = activeFilter?.slicerSelections?.length ?? 0;
		const slicerLabel = activeFilter?.slicer?.label;
		const periodId = activeFilter?.period?.id;

		return (
			<div className="card-header border-0 pb-0">
				<div className="d-flex flex-column">
					<p className="text-body-2lg p-0 m-0">{`Discount Comparison between ${slicerSelectionsLength} ${slicerLabel}`}</p>
					<p className="text-body-2lg p-0 m-0">{`of ${getDataGroup()}`}</p>
					<p className="text-body-2lg p-0 m-0">{`in ${periodId}`}</p>
				</div>
			</div>
		);
	}

	function cardCaption() {
		const slicerTitles = data?.data?.map((slicer) => slicer.title).join(" and ");
		const formattedTotalAmount = formatCurrency(combinedGTV);
		return (
			<p className="mt-3 mb-0 text-center">
				{`Combined ${slicerTitles}: ${formattedTotalAmount} in ${data.meta.year}`}
			</p>
		);
	}

	function progressBarCaption() {
		const combinedGTV = data?.data?.reduce(
			(accumulator, data) => accumulator + data?.amount + data?.discountedAmount,
			0
		);
		return (
			<div className="row">
				{data.data.map((slicer, index) => {
					const total = slicer?.amount + slicer?.discountedAmount;
					const amountPercentage = ((slicer?.amount / total) * 100)?.toFixed(0);
					const discountedAmountPercentage = ((slicer?.discountedAmount / total) * 100)?.toFixed(0);
					const totalPercentage = ((total / combinedGTV) * 100)?.toFixed(0);

					const color = getDabbleColor(index, 0);
					return (
						<div
							className="col"
							key={index}>
							<p
								className="m-0"
								style={{ color }}>
								{slicer.title} :
							</p>

							<p
								className="m-0"
								style={{ color }}>
								Discount Rp. {thousandFormat(slicer?.amount)} or {amountPercentage}% of GTV
							</p>
							<p
								className="m-0"
								style={{ color }}>
								Revenue Rp. {thousandFormat(slicer?.discountedAmount)} or {discountedAmountPercentage}% of
								GTV
							</p>
							<p
								className="m-0"
								style={{ color }}>
								GTV Rp. {thousandFormat(total)} or {totalPercentage}% of Combined GTV in {data?.meta?.year}
							</p>
						</div>
					);
				})}
			</div>
		);
	}

	return (
		<div className="col-xl-12 col-md-12">
			<CardNumber number="4" />
			<div className="card">
				{cardTitle()}
				{data ? (
					<div className="card-body">
						{progressBarCaption()}
						<div className="dabble-tooltip-container w-100">
							<div
								className="dabble-tooltip rounded-sm shadow mt-5 py-2 px-4 text-body regular"
								style={{ zIndex: "1080" }}>
								<p className="p-0 pb-0 mb-1">
									{`Combined ${slicersLength} ${activeFilter?.slicer?.label} : ${serialAmount(
										combinedGTV,
										activeFilter?.metric?.id
									)} in ${activeFilter?.period?.id}`}
								</p>
								{data.data?.map((slicer, slicerIndex) => {
									return [
										<ComparisonTooltipTile
											title={slicer?.title}
											value={serialAmount(slicer.amount, activeFilter?.metric?.id)}
											percentage={slicer?.percentage}
											growth={dabbleDecimal(slicer?.growth)}
											color={getDabbleColor(slicerIndex, 0)}
											key={Math.random()}
										/>,
										<ComparisonTooltipTile
											title={`Discounted ${slicer.title}`}
											value={serialAmount(slicer.discountedAmount, activeFilter?.metric?.id)}
											percentage={slicer.discountedPercentage}
											growth={dabbleDecimal(slicer.discountedGrowth)}
											color={"#000"}
											key={Math.random()}
										/>,
									];
								})}
							</div>
							<ComparisonProgressBar data={data} />
						</div>
						{cardCaption()}
					</div>
				) : (
					<EmptyCardPlaceholder />
				)}
			</div>
		</div>
	);
}

export default SlicerComparisonCard;
 