import React, { useCallback, useEffect, useMemo } from "react";
import EmptyCardPlaceholder from "components/Composable/EmptyCardPlaceholder";
import TimespanChips from "components/Composable/TimespanChips";
import DabbleDropdown from "components/Forms/Dropdown/DabbleDropdown";
import Chart from "react-apexcharts";
import { renderToString } from "react-dom/server";
import { useSelector } from "react-redux";
import { useGetChartQuery } from "services/PricingServiceV2";
import { getDabbleColor } from "utils/UIUtil";

const PricingBarLineChart2 = ({
	dicerFilter,
	setDicerFilter = () => {},
	activeTimespanFilter,
	setActiveTimespanFilter = () => {},
	timespanPeriods,
}) => {
	const menu = useSelector((state) => state.menu);
	const activeFilter = menu?.activeFilter;
	const dicerSelections = activeFilter?.dicerSelections;

	const selectedDicersLength = activeFilter?.dicerSelections?.length;
	const colors = activeFilter?.slicerSelections?.map((dicer, index) => getDabbleColor(index, 0));
	const slicerLength = activeFilter?.slicerSelections?.length ?? 0;
	const slicerSelections = activeFilter?.slicerSelections;
	const selectionLabels = slicerSelections
		?.map((selection) => selection?.label ?? selection?.name)
		.join(", ")
		.replace(/,(?!.*,)/gim, " and");

	const body = useMemo(() => {
		const filter = {
			year: activeFilter?.period?.id,
			interval: activeFilter?.interval?.id,
			timespan: activeTimespanFilter?.value ?? 1,
			metric: activeFilter?.metric?.id,
			slicerId: activeFilter?.slicer?.value,
			slicerSelections: activeFilter?.slicerSelections?.map(
				(slicerSelection, _) => slicerSelection.value
			),
		};
		if (activeFilter?.dicerSelections != null) {
			filter.dicerId = activeFilter?.dicer?.value;
			filter.dicerFilter = dicerFilter?.value;
		}
		return filter;
	}, [activeFilter, activeTimespanFilter, dicerFilter]);

	const { data: dataChart } = useGetChartQuery({ body, path: "/2" }, { skip: !body.dicerId });

	const data = dataChart?.data;
	const meta = dataChart?.meta;
	const categories = data?.map((i) => i.title);

	const dataRestructured = useMemo(() => {
		const result = [];
		data?.forEach((element) => {
			element?.childrens?.forEach((data) => {
				const dataPrice = {
					name: data.title + " Price",
					amount: data.average,
					group: data.title,
				};
				const dataMetric = {
					name: data.title + " GTV",
					amount: data.metric,
					group: data.title,
				};

				result.push(dataPrice);
				result.push(dataMetric);
			});
		});

		return result;
	}, [data]);

	const dataSeries = useMemo(() => {
		const series = [];
		dataRestructured.forEach((element) => {
			const dataIsAlreadyAvailable = series.filter((i) => i.name === element.name).length > 0;
			if (!dataIsAlreadyAvailable) {
				series.push({
					data: dataRestructured.filter((i) => i.name === element.name).map((i) => i.amount),
					...element,
				});
			} else {
				const index = series.findIndex((i) => i.name === element.name);
				const newDataNumber = dataRestructured?.[element]?.data;
				if (newDataNumber) {
					series[index].data.push(newDataNumber);
				}
			}
		});
		series.forEach((element, index) => {
			if (element.name.includes("GTV")) {
				series[index].type = "column";
			} else {
				series[index].type = "line";
			}
		});
		return series;
	}, [dataRestructured]);

	function cardTitle() {
		return (
			<div>
				{slicerLength > 0 ? (
					<>
						<DabbleDropdown
							items={dicerSelections}
							onTap={(filter) => setDicerFilter(filter)}
							selectedItemId={dicerFilter?.value}
							toggleClassName="default-select style-3 btn btn-sm filter-option text-body-2xs regular"
							containerClassName="dropdown bootstrap-select form-control style-3 default-select d-inline-flex"
							key={Math.random()}
							buttonStyle="style1"
						/>
						<div className="d-flex justify-content-between w-100 mt-4">
							<div className="text-body-2lg">
								<div>Nett Price and GTV Performance</div>
								<div>
									in {selectedDicersLength} {activeFilter?.dicer?.label} of {selectionLabels}
								</div>
								<div>in {meta?.year}</div>
							</div>
							{timespanPeriods && (
								<TimespanChips
									setActiveTimespanFilter={setActiveTimespanFilter}
									activeTimespanFilter={activeTimespanFilter}
									timespanPeriods={timespanPeriods}
								/>
							)}
						</div>
					</>
				) : (
					<p className="text-body-2lg p-0 m-0 text-muted">No slicer selection selected</p>
				)}
			</div>
		);
	}

	const renderToolTip = useCallback(
		({ seriesIndex, dataPointIndex }) => {
			const dataSeries1 = data?.[dataPointIndex]?.childrens?.[0];
			const dataSeries2 = data?.[dataPointIndex]?.childrens?.[1];
			const dataObjColors = ["#FB7C73", "#00F0FF", "#F82416", "#4CAF50", "#0A58CA", "#320704"];
			const titleColors = ["#D42200", "#FED3D0", "#165DFF", "#CCFCFF"];
			const dataObj1 = {
				["Avg Price / Unit"]: dataSeries1?.average,
				Max: dataSeries1?.max,
				Mean: dataSeries1?.mean,
				Median: dataSeries1?.median,
				Min: dataSeries1?.min,
				Mode: dataSeries1?.mode,
			};
			const dataObj2 = {
				["Avg Price / Unit"]: dataSeries2?.average,
				Max: dataSeries2?.max,
				Mean: dataSeries2?.mean,
				Median: dataSeries2?.median,
				Min: dataSeries2?.min,
				Mode: dataSeries2?.mode,
			};

			// if (seriesIndex === 1 || seriesIndex === 3) return null;

			return renderToString(
				<div className="d-flex">
					<div
						className="rounded px-4 py-2"
						style={{ zIndex: "9999 !important" }}>
						<div style={{ color: titleColors[seriesIndex], fontWeight: 700, zIndex: 9999 }}>
							{dataSeries1?.title}
						</div>
						{Object.keys(dataObj1)?.map((key, index) => (
							<div
								key={index}
								className="my-1 d-flex justify-content-between align-items-center">
								<div className="border rounded border-light-text-primary py-1 px-2 d-flex align-items-center">
									<div
										style={{ width: "14px", height: "14px", background: dataObjColors[index] }}
										className="rounded-circle mr-2"
									/>
									<div>{key}</div>
									<div
										style={{
											width: "14px",
											height: "14px",
											fontSize: "9px",
											fontWeight: "600",
											border: "2px solid #4B5565",
										}}
										className="rounded-circle ml-2 d-flex align-items-center justify-content-center">
										i
									</div>
								</div>
								<div className="ml-4">Rp. {dataObj1[key]}</div>
							</div>
						))}
					</div>
					<div
						className="rounded px-4 py-2"
						style={{ zIndex: "9999 !important" }}>
						<div style={{ color: titleColors[seriesIndex + 2], fontWeight: 700, zIndex: 9999 }}>
							{dataSeries2?.title}
						</div>
						{Object.keys(dataObj2)?.map((key, index) => (
							<div
								key={index}
								className="my-1 d-flex justify-content-between align-items-center">
								<div className="border rounded border-light-text-primary py-1 px-2 d-flex align-items-center">
									<div
										style={{ width: "14px", height: "14px", background: dataObjColors[index] }}
										className="rounded-circle mr-2"
									/>
									<div>{key}</div>
									<div
										style={{
											width: "14px",
											height: "14px",
											fontSize: "9px",
											fontWeight: "600",
											border: "2px solid #4B5565",
										}}
										className="rounded-circle ml-2 d-flex align-items-center justify-content-center">
										i
									</div>
								</div>
								<div className="ml-4">Rp. {dataObj2[key]}</div>
							</div>
						))}
					</div>
				</div>
			);
		},
		[data]
	);

	const options = useMemo(() => {
		return {
			series: dataSeries,
			chart: {
				height: 350,
				type: "bar",
				width: 2,
				stacked: false,
				toolbar: {
					show: false,
				},
				zoom: {
					enabled: false,
				},
			},
			stroke: {
				width: 3,
				curve: "smooth",
				colors: ["#D42200", "transparent", "#165DFF", "transparent"],
			},
			dataLabels: {
				formatter: (val) => {
					return val / 1000 + "K";
				},
				enabled: false,
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: "20px",
					barHeight: "70%",
					borderRadius: 4,
					borderRadiusApplication: "around",
					borderRadiusWhenStacked: "all",
				},
				line: {
					columnWidth: "10px",
				},
			},
			xaxis: {
				categories,
				tooltip: {
					enabled: false,
				},
				labels: {
					formatter: function (val) {
						const text = val?.toString()?.split(" ");
						const modifiedText = [text?.[0].slice(0, 3), text?.[1]];
						return modifiedText;
					},
				},
			},
			colors: ["#D42200", "#FED3D0", "#165DFF", "#CCFCFF"],
			yaxis: [
				{
					title: {
						text: "Price",
					},
					labels: {
						show: true,
						formatter: (val) => {
							return `Rp. \n${val?.toFixed()}`;
						},
					},
				},
				{
					opposite: true,
					title: {
						rotate: 90,
						text: "GTV",
					},
					labels: {
						show: true,
						formatter: (val) => {
							return `Rp. \n${val?.toFixed()}`;
						},
					},
				},
			],
			legend: {
				position: "top",
				horizontalAlign: "left",
			},
			tooltip: {
				enabled: true,
				shared: true,
				intersect: false,
				custom: renderToolTip,
			},
		};
	}, [categories, dataSeries, renderToolTip]);

	useEffect(() => {
		const style = document.createElement("style");
		style.textContent = `
      #pricing-bar-line-chart-2 .apexcharts-bar-area:hover {
        filter: brightness(0.9);
      }
    `;
		document.head.appendChild(style);

		return () => {
			document.head.removeChild(style);
		};
	}, []);

	return (
		<div
			id="pricing-bar-line-chart-2"
			className="col-xl-12 col-md-12 custom-legend">
			{cardTitle()}
			{data?.length > 0 ? (
				<Chart
					options={options}
					series={options.series}
					width="100%"
					height={400}
				/>
			) : (
				<EmptyCardPlaceholder />
			)}
		</div>
	);
};

export default PricingBarLineChart2;
