import { Suspense } from "react";
/// Components
import Index from "routes/index";
import { connect } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faFontAwesome, faTwitter } from "@fortawesome/free-brands-svg-icons";
// action
import { isAuthenticated } from "./store/selectors/AuthSelectors";
/// Style
import "vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "styles/css/style.css";
import "styles/css/global.css";

library.add(fas, faTwitter, faFontAwesome);

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let navigate = useNavigate();
		let location = useLocation();
		let params = useParams();

		return (
			<Component
				{...props}
				router={{ location, navigate, params }}
			/>
		);
	}

	return ComponentWithRouterProp;
}

function App(props) {
	return (
		<>
			<Suspense
				fallback={
					<div id="preloader">
						<div className="sk-three-bounce">
							<div className="sk-child sk-bounce1"></div>
							<div className="sk-child sk-bounce2"></div>
							<div className="sk-child sk-bounce3"></div>
						</div>
					</div>
				}>
				<Index />
			</Suspense>
		</>
	);
}

const mapStateToProps = (state) => {
	return {
		isAuthenticated: isAuthenticated(state),
	};
};

export default withRouter(connect(mapStateToProps)(App));
