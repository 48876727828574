import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function EmptyCardPlaceholder() {
	return (
		<div className="card-body">
			<hr className="mt-0 text-muted"/>
			<div className="d-flex flex-row text-muted justify-content-center align-items-center">
				<FontAwesomeIcon icon="fa-chart-line" />
				<p className="text-body-base regular ml-2 mb-0 p-0">No data available</p>
			</div>
		</div>
	);
}

export default EmptyCardPlaceholder;
