import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { debounce } from "utils/InputUtil";
import YtdTransactionCard from "components/Dashboard/card/YtdTransactionCard";
import YtdGrossTransactionCard from "components/Dashboard/card/YtdGrossTransactionCard";
import YtdQuantityCard from "components/Dashboard/card/YtdQuantityCard";
import SlicerComparisonCard2 from "components/Dashboard/pricing/card/SlicerComparisonCard2";
import SlicerComparisonCard3 from "components/Dashboard/pricing/card/SlicerComparisonCard3";
import SlicerComparisonCard from "components/Dashboard/pricing/card/SlicerComparisonCard";
import { Chart1, Chart2, Chart3 } from "services/PricingDummyData";
import PricingGraphicTableCard3 from "components/Dashboard/pricing/card/PricingGraphicTableCard3";
import PricingGraphicTableCard2 from "components/Dashboard/pricing/card/PricingGraphicTableCard2";
import PricingGraphicTableCard from "components/Dashboard/pricing/card/PricingGraphicTableCard";
// import PricingBarLineChart from "components/Dashboard/pricing/chart/PricingBarLineChart";
// import PricingBarLineChart2 from "components/Dashboard/pricing/chart/PricingBarLineChart2";
// import PricingTable1 from "components/Dashboard/pricing/table/PricingTable1";
// import PricingTable2 from "components/Dashboard/pricing/table/PricingTable2";
import CardNumber from "components/Composable/CardNumber";

function Pricing(props) {
  const [_props, saveProps] = useState(null);
  const _menu = _props?.menu;

  useEffect(() => {
    const onSaveProps = debounce(saveProps, 250);
    onSaveProps(props);
  }, [props]);

  return _menu != null ? (
    <div>
      <div className="col">
        <div className="row">
          <h4 className="bold m-0">Pricing Analytic</h4>
        </div>
        <div className="row">
          <p className="text-body regular">{`in the period of ${_menu?.filter?.period.title}`}</p>
        </div>
      </div>
      <div className="row">
        {/* Summary card sections */}
        <YtdTransactionCard activeFilter={_menu?.activeFilter} />
        <YtdGrossTransactionCard activeFilter={_menu?.activeFilter} />
        <YtdQuantityCard activeFilter={_menu?.activeFilter} />
        {/*  */}
        <SlicerComparisonCard activeFilter={_menu?.activeFilter} />
        {/*  */}
        <PricingGraphicTableCard activeFilter={_menu?.activeFilter} />
        {/*  */}
        <SlicerComparisonCard2 activeFilter={_menu?.activeFilter} />
        {/*  */}
        <PricingGraphicTableCard2 activeFilter={_menu?.activeFilter} />
        {/*  */}
        <SlicerComparisonCard3 activeFilter={_menu?.activeFilter} />
        {/*  */}
        <PricingGraphicTableCard3 activeFilter={_menu?.activeFilter} />
      </div>
    </div>
  ) : null;
}

const mapStateToProps = (state) => {
  return {
    menu: state.menu,
  };
};

export default connect(mapStateToProps, null)(Pricing);
