import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { dabbleDecimal } from "utils/NumericUtil";

function GrowthLabel(props){
    const isPositive = props.data > 0;
    const color = isPositive ? "text-success" : "text-danger";
    const indicator = (props.data !== 0.0 && isPositive) ? "+" : "";
    const icon = isPositive ? "fa-arrow-trend-up" : "fa-arrow-trend-down";
    return (
        <div className="d-flex align-items-center">
            <p className={`${color} p-0 m-0`}>{`(${indicator}${dabbleDecimal(props.data)}%)`}</p>
            <FontAwesomeIcon icon={`fa-solid ${icon}`} className={`ml-2 ${color}`}/>
        </div>
    );
}

export default GrowthLabel;