import {retrieveIntervals} from 'services/FilterService';

export const RETRIEVE_INTERVALS_CONFIRMED = '[retrieve format action] confirmed retrieve interval';
export const RETRIEVE_INTERVALS_FAILED = '[retrieve format action] confirmed retrieve interval';
export const UPDATE_SELECTED_INTERVAL = '[update selected interval]';

export function retrieveIntervalsAction(defaultValue){
    return (dispatch) => {
        retrieveIntervals()
        .then((response) => { 
            dispatch(retrieveIntervalsConfirmedAction(response.data));
            dispatch(updateSelectedInterval(defaultValue ?? response.data[0]))
        })
        .catch((error) => {
            dispatch(retrieveIntervalsFailedAction(error));
        });
    };
}

export function retrieveIntervalsConfirmedAction(data) {
    return {
        type: RETRIEVE_INTERVALS_CONFIRMED,
        result: data,
    };
}

export function retrieveIntervalsFailedAction(data) {
    return {
        type: RETRIEVE_INTERVALS_FAILED,
        result: data,
    };
}

export function updateSelectedInterval(interval){
    return (dispatch) => {
        dispatch({
            type: UPDATE_SELECTED_INTERVAL,
            payload: interval,
        })
    }
}