import { formatCurrency } from "./CurrencyUtil";
import { thousandFormat } from "./NumericUtil";

export const GENERAL_ERROR = "General error";

export const UNHANDLED_ERROR = "Unhandled error";

export function baseResponse(code, status, message) {
	return {
		code: code,
		status: status,
		message: message,
	};
}

export function serialAmount(amount, metricId) {
	switch (metricId) {
		case "transaction":
			return `${thousandFormat(amount)} Transactions`;
		case "gtv":
			return formatCurrency(amount);
		case "quantity":
			return `${thousandFormat(amount)} Items`;
		default:
			break;
	}
}

export function getShortMonth(month) {
	const monthNames = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	];
	return monthNames[month];
}

export function subtractDays(date, days) {
	const newDate = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
	return newDate;
}

export function getDateRange(selectedPeriod, selectedFilter) {
	let startDate, endDate;
	const today = new Date();
	const toDate = new Date(selectedPeriod, today.getMonth(), today.getDate());
	const currentYear = toDate.getFullYear();
	if (selectedFilter === "LAST_WEEK") {
		const firstDay = currentYear >= selectedPeriod ? toDate.getDate() - 7 : 1;
		startDate = new Date(selectedPeriod, toDate.getMonth(), firstDay);
		endDate = toDate;
	} else if (selectedFilter === "LAST_MONTH") {
		startDate = new Date(selectedPeriod, toDate.getMonth(), 1);
		endDate = toDate;
	} else if (selectedFilter === "LAST_YEAR") {
		startDate = new Date(selectedPeriod, 0, 1);
		endDate = toDate;
	} else {
		throw new Error("Invalid filter provided");
	}
	return { startDate, endDate };
}

export function intervalLabel(intervalId) {
	switch (intervalId) {
		case "WEEKLY":
			return "Week";
		case "MONTHLY":
			return "Month";
		case "QUARTERLY":
			return "Quarter";
		case "SEMESTERLY":
			return "Semester";
		case "YEARLY":
			return "Year";
		default:
			return "";
	}
}

export function extractPercentage(json) {
	const amount = json.data.map((data, index) => data?.amount);
	const totalAmount = amount.reduce((a, b) => a + b);
	const data = json.data.map((data, index) => {
		/// Calculate percentage
		const newPercentage = (data?.amount / totalAmount) * 100;
		if (data?.childrens != null) {
			/// Check for dicer children then repeat
			const dicerAmount = data.childrens.map((children, index) => children.amount);
			const dicerTotalAmount = dicerAmount.reduce((a, b) => a + b);
			const childrens = data.childrens.map((children, index) => {
				const newPercentage = (children.amount / dicerTotalAmount) * 100;
				return {
					...children,
					percentage : newPercentage,
				};
			});
			return {
				...data,
				childrens: childrens,
				percentage : newPercentage,
			}
		}
		/// Asign percentage to data
		return {
			...data,
			percentage : newPercentage,
		}
	});
	return {
		...json,
		data: data,
	};
}

export function extractDiscountPercentage(json) {
	const amount = json.data.map((data, index) => data?.amount);
	const discountedAmount = json.data.map((data, index) => data?.discountedAmount);
	const allAmount = amount.concat(discountedAmount);
	const totalAmount = allAmount.reduce((a, b) => a + b);
	const data = json.data.map((data, index) => {
		/// Calculate percentage
		const newPercentage = (data?.amount / totalAmount) * 100;
		const newDiscountPercentage = (data?.discountedAmount / totalAmount) * 100;
		if (data?.childrens != null) {
			/// Check for dicer children then repeat
			const dicerAmount = data.childrens.map((children, index) => children.amount);
			const dicerDiscountedAmount = data.childrens.map((children, index) => children.discountedAmount);
			const allDicerAmount = dicerAmount.concat(dicerDiscountedAmount);
			const dicerTotalAmount = allDicerAmount.reduce((a, b) => a + b);
			const childrens = data.childrens.map((children, index) => {
				const newPercentage = (children.amount / dicerTotalAmount) * 100;
				const newDiscountPercentage = (children.discountedAmount / dicerTotalAmount) * 100;
				return {
					...children,
					percentage : newPercentage,
					discountedPercentage : newDiscountPercentage,
				};
			});
			return {
				...data,
				childrens: childrens,
				percentage : newPercentage,
				discountedPercentage : newDiscountPercentage,
			}
		}
		/// Asign percentage to data
		return {
			...data,
			percentage : newPercentage,
			discountedPercentage : newDiscountPercentage,
		}
	});
	return {
		...json,
		data: data,
	};
}

export function calculatePercentage(amount, totalAmount) {
	return (amount / totalAmount) * 100;
}

export const getMetricLabel = (metric) => {
	switch (metric?.toLowerCase()) {
		case "transaction":
			return "Transaction";
		case "quantity":
			return "Quantity";
		case "gtv":
			return "GTV";
		default:
			return "";
	}
};
