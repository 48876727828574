import { calculatePercentage } from "utils/DataUtil";
import { getDabbleColor } from "utils/UIUtil";

function ComparisonProgressBar(props) {
	const data = props.data.data;

	function singleLevelProgressBar() {
		return (
			<div className="progress">
				{data.map((slicer, index) => [
					<div
						className="progress-bar"
						role="progressbar"
						style={{
							width: `${slicer.percentage}%`,
							backgroundColor: getDabbleColor(index, 0),
						}}
						aria-valuenow={slicer.percentage}
						aria-valuemin="0"
						aria-valuemax="100"
						key={Math.random()}></div>,
					<div
						className="progress-bar"
						role="progressbar"
						style={{
							width: `${calculatePercentage(slicer.discountedAmount, slicer.amount)}%`,
							backgroundColor: "#000",
						}}
						aria-valuenow={calculatePercentage(slicer.discountedAmount, slicer.amount)}
						aria-valuemin="0"
						aria-valuemax="100"
						key={Math.random()}></div>,
				])}
			</div>
		);
	}

	return (
		<div>
			{singleLevelProgressBar()}
			<div className="progress mt-2">
				<div
					className={{ "progress-bar": true, "bg-grey": true, }}
					role="progressbar"
					aria-valuenow="100"
					aria-valuemin="0"
					aria-valuemax="100"
					style={{
						width: "100%",
					}}></div>
				<div
					className={{
						"progress-bar": true,
					}}
					role="progressbar"
					aria-valuenow="100"
					aria-valuemin="0"
					aria-valuemax="100"
					style={{
						backgroundColor: "#000",
						width: `${data.discountedPercentage}%`,
					}}></div>
			</div>
		</div>
	);
}

export default ComparisonProgressBar;
