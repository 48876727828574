import chroma from "chroma-js";

export function getRootColor(variableName) {
	const style = window.getComputedStyle(document.documentElement);
	const colorValue = style.getPropertyValue(variableName);
	return colorValue;
}

export function getRandomColorRgb() {
	const red = Math.floor(Math.random() * 256);
	const green = Math.floor(Math.random() * 256);
	const blue = Math.floor(Math.random() * 256);
	const rgb = `rgb(${red}, ${green}, ${blue})`;
	return rgb;
}

export function getRandomColorFromSelection(colorIndex, index) {
	const colors = [
		// Base Colors
		["#ffb3bd", "#8ab7ff", "#FFE93F", "#8CE900", "#15E8FF"],
		// Pastel Colors
		["#962DFF", "#E0C6FD", "#93AAFD", "#4A3AFF", "#FF718B"],
	];
	return colors[colorIndex][index];
}

export function getDabbleColor(primaryIndex, index, length = 10) {
	// 1. bright pink, base #FA2AFF
	// 2. green, base #B1E755
	// 3. cyan, base #15E8FF
	// 4. red, base #DF2935
	// 5. yellow base #FDCA40
	// 6. orange brown, base #A33100
	// 7. orange, base #FF9900
	// 8. deep green, base #1B9900
	// 9. pink, base #F16995
	// 10. light blue, base #2099DF
	// 11. greenish cyan, base #00FFDD
	// 12. gray, base #4B5565
	// 13. blue, base #0084FE
	const colors = [
		chroma.scale(["#FDCA40", 'white']).colors(length),
		chroma.scale(["#DF2935", 'white']).colors(length),
		chroma.scale(["#15E8FF", 'white']).colors(length),
		chroma.scale(["#B1E755", 'white']).colors(length),
		chroma.scale(["#FA2AFF", 'white']).colors(length),
		chroma.scale(["#A33100", 'white']).colors(length),
		chroma.scale(["#FF9900", 'white']).colors(length),
		chroma.scale(["#1B9900", 'white']).colors(length),
		chroma.scale(["#F16995", 'white']).colors(length),
		chroma.scale(["#2099DF", 'white']).colors(length),
		chroma.scale(["#00FFDD", 'white']).colors(length),
		chroma.scale(["#4B5565", 'white']).colors(length),
		chroma.scale(["#0084FE", 'white']).colors(length),
		chroma.scale(["#8C7AE4", "white"]).colors(length),
		chroma.scale(["#FCF876", "white"]).colors(length),
		chroma.scale(["#6E2217", "white"]).colors(length),
		chroma.scale(["#3E6251", "white"]).colors(length),
		chroma.scale(["#D0605E", "white"]).colors(length),
		chroma.scale(["#98777B", "white"]).colors(length),
		chroma.scale(["#F6547A", "white"]).colors(length),
		chroma.scale(["#9F8170", "white"]).colors(length),
		chroma.scale(["#3F888F", "white"]).colors(length),
		chroma.scale(["#F78E69", "white"]).colors(length),
		chroma.scale(["#EFB1DF", "white"]).colors(length),
		chroma.scale(["#7B9473", "white"]).colors(length),
		chroma.scale(["#7895B2", "white"]).colors(length),
		chroma.scale(["#AF4035", "white"]).colors(length),
		chroma.scale(["#BAAB68", "white"]).colors(length),
		chroma.scale(["#5A7382", "white"]).colors(length),
		chroma.scale(["#509EA2", "white"]).colors(length),
		chroma.scale(["#9E5E6F", "white"]).colors(length),
		chroma.scale(["#726D93", "white"]).colors(length),
		chroma.scale(["#DE73FF", "white"]).colors(length),
		chroma.scale(["#D3A95C", "white"]).colors(length),
		chroma.scale(["#CCAAD5", "white"]).colors(length),
		chroma.scale(["#BC2F59", "white"]).colors(length),
		chroma.scale(["#808000", "white"]).colors(length),
		chroma.scale(["#E1DB72", "white"]).colors(length),
		chroma.scale(["#BC8F8F", "white"]).colors(length),
		chroma.scale(["#B87FD4", "white"]).colors(length),
		chroma.scale(["#B28474", "white"]).colors(length),
		chroma.scale(["#67E387", "white"]).colors(length),
		chroma.scale(["#A8895B", "white"]).colors(length),
		chroma.scale(["#917C6F", "white"]).colors(length),
		chroma.scale(["#DE6FA1", "white"]).colors(length),
		chroma.scale(["#AF593E", "white"]).colors(length),
		chroma.scale(["#67807B", "white"]).colors(length),
		chroma.scale(["#4287A7", "white"]).colors(length),
		chroma.scale(["#ACDF87", "white"]).colors(length),
		chroma.scale(["#F4A460", "white"]).colors(length),
		chroma.scale(["#A259A4", "white"]).colors(length),
		chroma.scale(["#7895B2", "white"]).colors(length),
		chroma.scale(["#485214", "white"]).colors(length),
		chroma.scale(["#659190", "white"]).colors(length),
		chroma.scale(["#D5AAFF", "white"]).colors(length),
		chroma.scale(["#BE81F7", "white"]).colors(length),
		chroma.scale(["#885159", "white"]).colors(length),
		chroma.scale(["#904E55", "white"]).colors(length),
		chroma.scale(["#527F76", "white"]).colors(length),
		chroma.scale(["#748B97", "white"]).colors(length),
		chroma.scale(["#B28474", "white"]).colors(length),
		chroma.scale(["#F2C1D1", "white"]).colors(length),
		chroma.scale(["#966A3E", "white"]).colors(length),
		chroma.scale(["#67807B", "white"]).colors(length),
		chroma.scale(["#A8E4A0", "white"]).colors(length),
		chroma.scale(["#6F63A0", "white"]).colors(length),
		chroma.scale(["#5B92E5", "white"]).colors(length),
		chroma.scale(["#E897AD", "white"]).colors(length),
		chroma.scale(["#987456", "white"]).colors(length),
		chroma.scale(["#A7752C", "white"]).colors(length),
		chroma.scale(["#E6739F", "white"]).colors(length),
		chroma.scale(["#C49EB5", "white"]).colors(length),
		chroma.scale(["#536872", "white"]).colors(length),
		chroma.scale(["#2A3800", "white"]).colors(length),
		chroma.scale(["#CBAC45", "white"]).colors(length),
		chroma.scale(["#7F2E00", "white"]).colors(length),
		chroma.scale(["#FDD9B5", "white"]).colors(length),
		chroma.scale(["#617C58", "white"]).colors(length),
		chroma.scale(["#6B4423", "white"]).colors(length),
		chroma.scale(["#9F8169", "white"]).colors(length),
		chroma.scale(["#7EC8E3", "white"]).colors(length),
		chroma.scale(["#BF652E", "white"]).colors(length),
		chroma.scale(["#E3DAC9", "white"]).colors(length),
		chroma.scale(["#84563C", "white"]).colors(length),
		chroma.scale(["#5D8CAE", "white"]).colors(length),
		chroma.scale(["#DECDBE", "white"]).colors(length),
		chroma.scale(["#A899E6", "white"]).colors(length),
		chroma.scale(["#987D73", "white"]).colors(length),
		chroma.scale(["#36454F", "white"]).colors(length),
		chroma.scale(["#D9B99B", "white"]).colors(length),
		chroma.scale(["#A8925B", "white"]).colors(length),
		chroma.scale(["#923827", "white"]).colors(length),
		chroma.scale(["#C54B8C", "white"]).colors(length),
		chroma.scale(["#736330", "white"]).colors(length),
		chroma.scale(["#9B7653", "white"]).colors(length),
		chroma.scale(["#8A3324", "white"]).colors(length),
		chroma.scale(["#F49AC2", "white"]).colors(length),
		chroma.scale(["#AE642D", "white"]).colors(length),
		chroma.scale(["#9F9F5F", "white"]).colors(length),
		chroma.scale(["#A67F59", "white"]).colors(length),
		chroma.scale(["#CF8E80", "white"]).colors(length),
		chroma.scale(["#A57257", "white"]).colors(length),
		chroma.scale(["#CDA989", "white"]).colors(length),
		chroma.scale(["#4F6228", "white"]).colors(length),
		chroma.scale(["#E0A890", "white"]).colors(length),
		chroma.scale(["#698C91", "white"]).colors(length),
		chroma.scale(["#AF4036", "white"]).colors(length),
		chroma.scale(["#AA7F59", "white"]).colors(length),
		chroma.scale(["#857A61", "white"]).colors(length),
		chroma.scale(["#64ACC8", "white"]).colors(length),
		chroma.scale(["#BA8759", "white"]).colors(length),
		chroma.scale(["#9D2933", "white"]).colors(length),
		chroma.scale(["#FFBA92", "white"]).colors(length),
		chroma.scale(["#3D2B1F", "white"]).colors(length),
		chroma.scale(["#78866B", "white"]).colors(length),
		chroma.scale(["#674C47", "white"]).colors(length),
		chroma.scale(["#779ECB", "white"]).colors(length),
		chroma.scale(["#A2ADD0", "white"]).colors(length),
		chroma.scale(["#645452", "white"]).colors(length),
		chroma.scale(["#981B1E", "white"]).colors(length),
		chroma.scale(["#F4A460", "white"]).colors(length),
		chroma.scale(["#784421", "white"]).colors(length),
		chroma.scale(["#965A3E", "white"]).colors(length),
		chroma.scale(["#6C2E1F", "white"]).colors(length),
		chroma.scale(["#6B3D33", "white"]).colors(length),
		chroma.scale(["#CE4676", "white"]).colors(length),
		chroma.scale(["#3D0C02", "white"]).colors(length),
		chroma.scale(["#53736F", "white"]).colors(length),
		chroma.scale(["#CD9575", "white"]).colors(length),
		chroma.scale(["#665D1E", "white"]).colors(length),
		chroma.scale(["#0E6EB8", "white"]).colors(length),
		chroma.scale(["#4682B4", "white"]).colors(length),
		chroma.scale(["#8B8680", "white"]).colors(length),
		chroma.scale(["#5218FA", "white"]).colors(length),
	];
	if(primaryIndex > colors.length){
		return null;
	}
	return colors[primaryIndex][index];
}

export function generateShades(baseColor, length) {
	const shades = [];

	// Convert the base color to RGB format
	const baseRGB = [
		parseInt(baseColor.substr(1, 2), 16),
		parseInt(baseColor.substr(3, 2), 16),
		parseInt(baseColor.substr(5, 2), 16),
	];

	// Calculate the step for each RGB value
	const step = baseRGB.map((value) => Math.round(value / length));

	// Generate the shades by incrementing/decrementing the RGB values
	for (let i = 0; i < length; i++) {
		const shadeRGB = baseRGB.map((value, index) => {
			return value + i * step[index];
		});

		// Convert the RGB values back to hex format
		const shadeHex = shadeRGB
			.map((value) => value.toString(16).padStart(2, "0"))
			.join("");

		shades.push(`#${shadeHex}`);
	}

	return shades;
}

export function percentageToPixels(fullAmount, percentage) {
	return (fullAmount / 100) * percentage;
}

export function scrollToElement(id) {
	const element = document.getElementById(id);
	if (element) {
		element.scrollIntoView({
			behavior: "smooth",
			passive: true,
		});
	}
}


export function scrollByRef({ direction, pixels = 300, ref }) {
	ref.current.scrollBy({
		top: 0,
		left: direction === "left" ? pixels * -1 : pixels,
		behavior: "smooth",
	});
}

export function getHeightByRowCount(count){
	if (count <= 3) {
		return 150;
	} else if (count > 3 && count < 6) {
		return 250;
	} else if (count > 6 && count <= 12) {
		return 350;
	} else if (count > 12 && count < 24) {
		return 2000;
	} else {
		return 2500;
	}
}