import React, { useRef } from "react";
import { getDabbleColor } from "utils/UIUtil";
import { dabbleDecimal } from "utils/NumericUtil";
import { extractPercentage, serialAmount } from "utils/DataUtil";
import { useMemo } from "react";
import { useGetComparisonQuery } from "services/AfinityPerformanceService";
import CardNumber from "components/Composable/CardNumber";
import ComparisonTooltipTile from "components/Composable/ComparisonTooltipTile";
import EmptyCardPlaceholder from "components/Composable/EmptyCardPlaceholder";
import CardTitle from "components/Composable/CardTitle";
import ComparisonProgressBar from "../composable/ComparisonProgressBar";

function SlicerComparisonCard2(props) {
	const cardScrollRef = useRef(null);
	const activeFilter = props?.activeFilter;
	const slicersLength = activeFilter?.slicerSelections?.length ?? 0;
	const slicerLabel = activeFilter?.slicer?.label;
	const periodId = activeFilter?.period?.id;

	const body = useMemo(() => {
		const filter = {
			year: activeFilter?.period?.id,
			metric: activeFilter?.metric?.id,
			slicer_id: activeFilter?.slicer?.value,
			slicers: activeFilter?.slicerSelections?.map((slicerSelection, _) => slicerSelection.value),
			dicer_id: activeFilter?.dicer?.value,
			dicers: activeFilter?.dicerSelections?.map((dicerSelection, _) => dicerSelection.value),
		};
		if (activeFilter?.slicerSelections != null && activeFilter?.dicerSelections != null) {
			if (activeFilter?.dicerSelections != null) {
				filter.dicer_id = activeFilter?.dicer?.value;
			}
		}
		return filter;
	}, [
		activeFilter?.dicer?.value,
		activeFilter?.dicerSelections,
		activeFilter?.metric?.id,
		activeFilter?.period?.id,
		activeFilter?.slicer?.value,
		activeFilter?.slicerSelections,
	]);

	const query = useGetComparisonQuery({ body, path: "/2" }, { skip: !body?.slicer_id });
	const data = (query.data != null) ? extractPercentage(query.data) : null;

	function getDicerLabels() {
		const selections = activeFilter?.dicerSelections ?? activeFilter?.slicerSelections;
		return selections?.map((selection) => selection?.label ?? selection?.name);
	}

	function progressBarBottomCaption(parentData, parentIndex) {
		const commonStyle = {
			color: getDabbleColor(parentIndex, 0),
		};

		return (
			<div className="row">
				<div className="col text-left">
					<p
						className="mt-3 mb-0"
						style={commonStyle}>
						{parentData.title}
					</p>
				</div>
			</div>
		);
	}

	return (
		<div className="col-xl-12 col-md-12">
			<div className="card">
				<CardNumber number="6" />
				<CardTitle
					slicerTitle={slicerLabel}
					slicerLength={slicersLength}
					dicerLabels={getDicerLabels()}
					period={periodId}
				/>
				{data != null ? (
					<div
						className="card-body text-body-s regular"
						ref={cardScrollRef}>
						<div
							className={`d-flex`}
							style={{ gap: "10px" }}>
							{data?.data?.map((parentData, parentIndex) => (
								<div
									style={{ width: `${parentData.percentage * 100}%` }}
									key={Math.random()}>
									<div className="dabble-tooltip-container w-100">
										<div
											className="dabble-tooltip rounded-sm shadow mt-5 py-2 px-4 text-body regular"
											style={{ zIndex: "1080" }}>
											<p className="p-0 pb-0 mb-1">
												{`${parentData.title}: ${serialAmount(
													parentData.amount,
													activeFilter?.metric?.id
												)} or ${dabbleDecimal(parentData.percentage)}% of ${activeFilter?.period?.id}`}
											</p>
											{parentData.childrens?.map((slicer, slicerIndex) => {
												return (
													<ComparisonTooltipTile
														title={slicer?.title}
														value={serialAmount(slicer?.amount, activeFilter?.metric?.id)}
														percentage={slicer?.percentage}
														growth={dabbleDecimal(slicer?.growth)}
														color={getDabbleColor(parentIndex, slicerIndex + 1, parentData.childrens.length + 2)}
														key={Math.random().toString()}
													/>
												);
											})}
										</div>
										<ComparisonProgressBar
											data={data}
											parentIndex={parentIndex}
											isMultiLevel={true}
											key={Math.random()}
										/>
									</div>
								</div>
							))}
						</div>
						<div className="row">
							{data?.data?.map((parentData, parentIndex) => (
								<div
									className="col"
									key={Math.random()}>
									{progressBarBottomCaption(parentData, parentIndex)}
								</div>
							))}
						</div>
					</div>
				) : (
					<EmptyCardPlaceholder />
				)}
			</div>
		</div>
	);
}

export default SlicerComparisonCard2;
