import React from "react";

const Footer = () => {
  const d = new Date();
  return (
    <div className="footer">
      <div className="copyright">
        <p>
          All Rights Reserved © {d.getFullYear()} {" "}
          <a href="https://dash.dabble.bz/" target="_blank"  rel="noreferrer">
            Dabble
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
