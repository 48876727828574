import ReactApexChart from "react-apexcharts";
import { renderToString } from "react-dom/server";
import { intervalLabel, serialAmount } from "utils/DataUtil";
import { dabbleDecimal, displayPercentage, thousandFormat } from "utils/NumericUtil";
import { getDabbleColor, getHeightByRowCount } from "utils/UIUtil";

export default function ComparisonStackedBarChart(props) {
	const rows = props.data.data;
	const filter = props.filter;
	const metric = filter?.metric;
    const categories = rows.map((data, index) => data?.title);
	const colors = getRawData()?.series?.map((dicer, index) => getDabbleColor(index, 0));

    function getRawData(){
        const categories = []
        const series = []
        rows.forEach((data, index) => {
			/// Data limiter, nomatter what
			if(index<55){
				/// loop sub series
				data.childrens.forEach((children, index)=>{
					/// Get group exsist
					const existingObject = series.find(seri => seri.name === children.title);
					if (existingObject) {
						if (Array.isArray(existingObject.data)) {
							existingObject.data.push(children?.amount);
						} else {
							existingObject.data = [existingObject.data, children?.amount];
						}
					} 
					else {
						series.push({
							name: children.title,
							group: children.group,
							data: [children.amount]
						});
					}
				})
			}
        });
        return {
            categories: categories,
            series: series,
        }
    }

	const state = {
		series: getRawData().series,
		options: {
			chart: {
				type: "bar",
				height: getHeightByRowCount(getRawData().categories.length),
				stacked: true,
				toolbar: {
					show: false,
				},
			},
			plotOptions: {
				bar: {
					horizontal: true,
				},
			},
            dataLabels: {
                enabled: false,
            },
			stroke: {
				width: 1,
				colors: ["#fff"],
			},
			xaxis: {
				categories: categories,
				labels: {
					formatter: function (val) {
						return thousandFormat(val);
					},
				},
			},
			yaxis: {
				title: {
					text: undefined,
				},
			},
			tooltip: {
				shared: true,
				intersect: false,
				width: "400px",
				custom: renderToolTip,
			},
			fill: {
				colors: colors,
				opacity: 1,
			},
			legend: {
				show: true,
                showForSingleSeries: true,
				position: "top",
				markers: {
					radius: 20,
					fillColors: colors,
				},
			},
		},
	};


	function renderToolTip({ series, seriesIndex, dataPointIndex }) {
		const parentData = rows[dataPointIndex]
		const selectedSeries = parentData?.childrens;
		const title = parentData?.title;
		return renderToString(
			<div className="card px-1 py-3 m-0" key={Math.random()}>
				<div className="col">
					<p className="pb-0 mb-1">
						{intervalLabel(filter?.interval?.id)} {dataPointIndex+1} {title}: {serialAmount(parentData?.amount, filter?.metric?.id)} or {displayPercentage(parentData.percentage)}% of {filter?.period?.id}</p>
				</div>
				{selectedSeries.map((selectedSerie, index) => {
					const isPositive = selectedSerie.growth > 0;
					const color = isPositive ? "#21B830" : "#FF2626";
					const arrow = isPositive ? "M0 11L11 -4.72849e-07L22 11" : "M0 -9.61651e-07L11 11L22 0";
					const symbol = isPositive ? "+" : "";
					const growth = `${symbol}${dabbleDecimal(selectedSerie?.growth)}`;
					return (
						<div className="d-flex flex-row align-content-between text-body-xs align-items-center" key={Math.random()}>
							<div className="col col-auto">
								<div className="px-2 py-1 my-1 border rounded">
									<svg
										className="mr-1"
										width="12"
										height="12"
										viewBox="0 0 12 12"
										fill="none"
										xmlns="http://www.w3.org/2000/svg">
										<rect
											width="12"
											height="12"
											rx="12.5"
											fill={colors[index]}></rect>
									</svg>
									{selectedSerie?.title}
								</div>
							</div>
							<div className="col"></div>
							<div className="col d-flex align-items-center">
								<svg
									className="p-0 m-0"
									width="24"
									height="12"
									viewBox="0 0 19 12">
									<path
										d={arrow}
										fill={color}
									/>
								</svg>
								<div className="text-right pl-1 p-0 m-0">
									<span
										className="p-0 m-0"
										style={{ color: color }}>
										{" "}
										{growth}{" "}
									</span>
									<span className="p-0 m-0">({serialAmount(selectedSerie.amount, metric?.id)})</span>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		);
	}


	return (
		<ReactApexChart
			options={state.options}
			series={state.series}
			type="bar"
			height={state.options.chart.height}
            key={Math.random()}
		/>
	);
}
