import CardNumber from "components/Composable/CardNumber";
import PricingGraphicCard3 from "./PricingGraphicCard3";
import PricingTable3 from "../table/PricingTable3";
import { useState } from "react";
import { useSelector } from "react-redux";

export default function PricingGraphicTableCard3() {
    const { activeFilter } = useSelector((state) => state.menu);
    const [slicerFilter, setSlicerFilter] = useState(activeFilter?.slicerSelections?.[0]);

    return (
        <div className="col-xl-12 col-md-12">
            <div className="card">
                <CardNumber number="9" />
                <div className="card-body w-100">
                    <PricingGraphicCard3 
                        slicerFilter={slicerFilter}
                        setSlicerFilter={setSlicerFilter}
                    />
                    <PricingTable3 slicerFilter={slicerFilter} />
                </div>
            </div>
        </div>
    );
}